<template>
  <div>
    <b-card no-body class="mb-1" v-if="d_show">
      <b-card-header header-bg-variant="white" header-text-variant="dark" class="p-1">{{ $t('wdm16.3317') }} ( {{ selectedParamData.name.label.toLocaleUpperCase('tr') }} ) </b-card-header>
      <div :style="f_mainParameterSelectionStyle()">
        <b-form-group :label="$t('wdm16.12912')" label-for="parameter_name" :label-cols="6">
          <template v-if="d_isThisModelEditable">
            <b-button v-if="!show_edit_parameter_label" size="sm" @click="EditParameterLabel()"><i class="fa fa-edit"></i></b-button>
            <b-button v-if="show_edit_parameter_label" size="sm" @click="SaveParameterLabel()"><i class="fa fa-save"></i></b-button>
          </template>
          <template v-if="show_edit_parameter_label">
            <b-form-input type="text" id="show_edit_parameter_label" v-model="new_param_label" @input="f_forceUpdate"></b-form-input>
          </template>
          <template v-else>
            {{ selectedParamData.name.label.toLocaleUpperCase('tr') }}
          </template>
        </b-form-group>
        <b-form-group :label="$t('wdm16.12893')" label-for="parameter_value" :label-cols="6">
          {{ selectedParamData.name.value }}
        </b-form-group>
        <b-form-group :label="$t('wdm16.12914')" label-for="parameter_lang_id" :label-cols="6">
          {{ selectedParamData.name.wdm16_id ? selectedParamData.name.wdm16_id : $t('wdm16.12633') }}
        </b-form-group>
        <b-form-group v-if="selectedParamData.type === 'textarea'" :label="$t('wdm16.12915')" label-for="row_count" :label-cols="6">
          <b-form-select v-model="selectedParamData.rows" :disabled="!d_isThisModelEditable" @change="f_forceUpdate">
            <option v-for="(row) in 30">{{ row }}</option>
          </b-form-select>
        </b-form-group>
        <b-form-group v-if="selectedParamData.type === 'select' || selectedParamData.type === 'radiogroup'" label="Select Yapısı" label-for="selectedParamData.type" :label-cols="6">
          <b-form-select v-model="selectedParamData.type" :disabled="!d_isThisModelEditable" @change="f_forceUpdate">
            <option value="select">{{ $t('wdm16.12916') }}</option>
            <option value="radiogroup">{{ $t('wdm16.12917') }}</option>
          </b-form-select>
        </b-form-group>
        <template v-if="d_isThisModelEditable">
          <b-form-group :label="$t('wdm16.12956')" label-for="recording_requirement" :label-cols="6">
            <b-badge :variant="selectedParamData.required === 'yes' ? 'success' : 'danger'" style="cursor: pointer;" @click="param_require_func(selectedParamData)"> {{ $t('wdm16.12776') }} </b-badge>
          </b-form-group>
          <b-form-group :label="$t('wdm16.12957')" label-for="show_param_while_recording" :label-cols="6">
            <b-badge :variant="selectedParamData.show === 'yes' ? 'success' : 'danger'" style="cursor: pointer;" @click="param_show_func(selectedParamData)"> {{ $t('wdm16.12921') }} </b-badge>
          </b-form-group>
          <b-form-group label="Yazdırırken başlığı kullanma" label-for="show_param_while_recording" :label-cols="6">
            <b-badge :variant="selectedParamData.dont_print_caption === 'yes' ? 'success' : 'danger'" style="cursor: pointer;" @click="print_show_func(selectedParamData)"> Yazdırırken başlığı kullanma </b-badge>
          </b-form-group>
          <b-form-group label="Kaydederken Etkin" label-for="show_param_while_recording" :label-cols="6">
            <b-badge :variant="!selectedParamData.disabled ? 'success' : 'danger'" style="cursor: pointer;" @click="param_ability(selectedParamData)"> {{ $t('wdm16.12922') }} </b-badge>
          </b-form-group>
          <b-form-group v-if="option_data[data_type].favorite_show_mode === 'yes'" :label="$t('wdm16.11344')" label-for="favorite_show_mode" :label-cols="6">
            <b-badge :variant="selectedParamData.fav_show === 'yes' ? 'success' : 'danger'" style="cursor: pointer;" @click="param_fav_show_func(selectedParamData)"> {{ $t('wdm16.11344') }} (FS) </b-badge>
          </b-form-group>
          <b-form-group :label="$t('wdm16.12923')" label-for="lab_unit" :label-cols="6">
            <b-badge :variant="selectedParamData.lab_unit ? 'success' : 'danger'" style="cursor: pointer;" @click="param_lab_unit_func(selectedParamData)"> {{ $t('wdm16.12924') }} (LU) </b-badge>
          </b-form-group>
          <b-form-group :label="$t('wdm16.12925')" label-for="show_size_units" :label-cols="6">
            <b-badge :variant="selectedParamData.show_size_units ? 'success' : 'danger'" style="cursor: pointer;" @click="param_show_size_units_func(selectedParamData)"> {{ $t('wdm16.12926') }} (SU) </b-badge>
          </b-form-group>
          <b-form-group :label="$t('wdm16.12927')" label-for="show_weight_units" :label-cols="6">
            <b-badge :variant="selectedParamData.show_weight_units ? 'success' : 'danger'" style="cursor: pointer;" @click="param_show_weight_units_func(selectedParamData)"> {{ $t('wdm16.12928') }} (WU) </b-badge>
          </b-form-group>
          <b-form-group :label="$t('wdm16.12929')" label-for="show_dosage_units" :label-cols="6">
            <b-badge :variant="selectedParamData.show_dosage_units ? 'success' : 'danger'" style="cursor: pointer;" @click="param_show_dosage_units_func(selectedParamData)"> {{ $t('wdm16.12930') }} (DU) </b-badge>
          </b-form-group>
          <b-form-group :label="$t('wdm16.12931')" label-for="not_schema" :label-cols="6">
            <b-badge :variant="selectedParamData.not_schema ? 'success' : 'danger'" style="cursor: pointer;" @click="param_not_schema_func(selectedParamData)"> {{ $t('wdm16.12931') }} (Ş) </b-badge>
          </b-form-group>
          <b-form-group :label="$t('wdm16.12933')" label-for="anonym" :label-cols="6">
            <b-badge :variant="selectedParamData.anonym ? 'success' : 'danger'" style="cursor: pointer;" @click="param_anonym_func(selectedParamData)"> {{ $t('wdm16.12933') }} (A) </b-badge>
          </b-form-group>
          <b-form-group :label="$t('wdm16.12935')" label-for="auto_naming" :label-cols="6">
            <b-badge :variant="selectedParamData.naming ? 'success' : 'danger'" style="cursor: pointer;" @click="param_name_func(selectedParamData)"> {{ $t('wdm16.12935') }} (N) </b-badge>
          </b-form-group>
          <b-form-group :label="$t('wdm16.13272')" label-for="dont_use_label" :label-cols="6">
            <b-badge :variant="selectedParamData.dont_use_label ? 'success' : 'danger'" style="cursor: pointer;" @click="param_dont_use_label_func(selectedParamData)"> {{ $t('wdm16.13272') }} (DL) </b-badge>
          </b-form-group>
          <b-form-group :label="$t('wdm16.12939')" label-for="show_after_recorded" :label-cols="6">
            <b-badge :variant="selectedParamData.page_not_show ? 'danger' : 'success'" style="cursor: pointer;" @click="param_page_not_show_func(selectedParamData)"> {{ $t('wdm16.12939') }} (SAR) </b-badge>
          </b-form-group>
          <b-form-group v-if="page_type === 'hospital_settings'" :label="$t('wdm16.12941')" label-for="hims_match_operations" :label-cols="6">
            <b-badge :variant="selectedParamData.hims_match_operations ? 'success' : 'danger'" style="cursor: pointer;" @click="param_hims_match_operations(selectedParamData)"> {{ $t('wdm16.12941') }} (HMO) </b-badge>
          </b-form-group>
          <b-form-group :label="$t('wdm16.1675')" label-for="selectedParamData_info" :label-cols="6">
            <b-textarea id="selectedParamData_info" v-model="selectedParamData.info" @input="f_forceUpdate"></b-textarea>
          </b-form-group>
          <b-form-group v-if="['integer', 'float'].indexOf(selectedParamData.type) !== -1" :label="$t('wdm16.12943')" label-for="selectedParamData_max" :label-cols="6">
            <b-form-input type="number" min="0" step="1" class="form-control" v-model="selectedParamData.min" :name="$t('wdm16.12943')" @input="f_forceUpdate"></b-form-input>
          </b-form-group>
          <b-form-group v-if="['text', 'textarea', 'integer', 'float'].indexOf(selectedParamData.type) !== -1" :label="$t('wdm16.12945')" label-for="selectedParamData_max" :label-cols="6">
            <b-form-input type="number" min="0" step="1" class="form-control" v-model="selectedParamData.max" :name="$t('wdm16.12945')" @input="f_forceUpdate"></b-form-input>
          </b-form-group>
          <b-form-group v-if="['select', 'object_list'].indexOf(selectedParamData.type) !== -1" label="Selectbox Türü" label-for="selectedParamData_selectbox_type" :label-cols="6">
            <b-form-select id="show_parameter_features" :plain="true" v-model="selectedParamData.selectbox_type" @change="f_forceUpdate">
              <option value="vselect">{{ $t('wdm16.12947') }} </option>
              <option value="listbox">{{ $t('wdm16.12948') }}</option>
            </b-form-select>
          </b-form-group>
          <b-form-group v-if="['checkbox'].indexOf(selectedParamData.type) !== -1" label="Checkbox Türü" label-for="selectedParamData_checkbox_type" :label-cols="6">
            <b-form-select id="show_parameter_features" :plain="true" v-model="selectedParamData.checkbox_type" @change="f_forceUpdate">
              <option value="checkbox">Checkbox</option>
              <option value="listbox">{{ $t('wdm16.12948') }}</option>
            </b-form-select>
          </b-form-group>
          <!-- <b-form-group v-if="['object_list', 'object_list_options', 'list_parameter'].indexOf(selectedParamData.type) === -1" :label="$t('wdm16.12953')" label-for="selectedParamData_wdm_specific" :label-cols="6">
            <b-form-select id="wdm_specific" :plain="true" v-model="selectedParamData.wdm_specific" @change="f_forceUpdate">
              <option value="table">{{ $t('wdm16.12949') }}</option>
              <option value="sentence">{{ $t('wdm16.12950') }}</option>
              <option value="polestar">{{ $t('wdm16.12951') }}</option>
              <option value="not_show">{{ $t('wdm16.12952') }}</option>
            </b-form-select>
          </b-form-group> -->
          <b-form-group v-if="['select', 'object_list', 'object_list_options', 'checkbox', 'radiogroup'].indexOf(selectedParamData.type) !== -1" :label="$t('wdm16.12975')" label-for="parameter_option_method" :label-cols="6">
            <b-form-select id="parameter_option_method" :plain="true" v-model="selectedParamData.parameter_option_method" :disabled="!d_isThisModelEditable" @change="f_forceUpdate">
              <option value="manuel">{{ $t('wdm16.12976') }}</option>
              <option value="option_search">{{ $t('wdm16.12977') }}</option>
            </b-form-select>
          </b-form-group>
          <b-form-group v-if="selectedParamData.parameter_option_method && selectedParamData.parameter_option_method === 'option_search'" :label="$t('wdm16.12975')" label-for="parameter_option_method_2" :label-cols="6">
            <b-form-select id="parameter_option_search_with_function" :plain="true" v-model="selectedParamData.option_search" :disabled="!d_isThisModelEditable" @change="f_forceUpdate">
              <option v-for="parameter_function_option in d_parameterFunctionOptions" :value="parameter_function_option.value">{{ parameter_function_option.label }}</option>
            </b-form-select>
          </b-form-group>
          <b-form-group v-if="selectedParamData.parameter_option_method && selectedParamData.parameter_option_method === 'option_search' && selectedParamData.option_search === 'get_hospital_wtl_list'" :label="$t('wdm16.12980')" label-for="option_search_hospital_wtl" :label-cols="6">
            <b-form-select id="parameter_option_search_hospital_wtl_with_function" :plain="true" v-model="selectedParamData.option_search_hospital_wtl" :disabled="!d_isThisModelEditable" @change="f_forceUpdate">
              <option value="department">{{ $t('wdm16.11428') }}</option>
              <option value="hospital_location">{{ $t('wdm16.12981') }}</option>
            </b-form-select>
          </b-form-group>
          <b-form-group v-if="selectedParamData.option_search === 'get_patient_wdmr_list'" label="WDMR Türünü Seçin" label-for="option_search_patient_wdmr" :label-cols="6">
            <b-form-select id="parameter_option_search_patient_wdmr_with_function" :plain="true" v-model="selectedParamData.option_search_patient_wdmr" :disabled="!d_isThisModelEditable" @change="f_forceUpdate">
              <option value="treatment">{{ $t('wdm16.12982') }}</option>
            </b-form-select>
          </b-form-group>
          <template v-if="selectedParamData.type === 'object_list'">
            <template v-if="selectedParamData.object_type && selectedParamData.object_param && upper_parameters[selectedParamData.object_param]">
              {{ upper_parameters[selectedParamData.object_param].object_type = selectedParamData.object_type }}
            </template>
            <b-form-group label="Obje parametresi" label-for="object_param" :label-cols="6">
              <select class="form-control" v-model="selectedParamData.object_param" :disabled="!d_isThisModelEditable" @change="f_forceUpdate">
                <option v-for="(other_param, other_param_ind) in upper_param_order" :value="upper_parameters[other_param].name" v-if="upper_parameters[other_param].type === 'object_list_options'">
                  {{ upper_parameters[other_param].name.label }}
                </option>
              </select>
            </b-form-group>
            <b-form-group :label="$t('wdm16.12983')" label-for="object_type" :label-cols="6">
              <select class="form-control" v-model="selectedParamData.object_type" :disabled="!d_isThisModelEditable" @change="f_forceUpdate">
                <option v-for="(object_type, object_type_ind) in ['object']" :value="object_type">
                  {{ object_type }}
                </option>
              </select>
            </b-form-group>
          </template>
          <b-form-group v-if="selectedParamData.type === 'object_list_options'" :label="$t('wdm16.12984')" label-for="object_record_type" :label-cols="6">
            {{ selectedParamData.object_type }}
          </b-form-group>
          <b-form-group v-if="select_types.indexOf(selectedParamData.type) !== -1" :label-cols="6" :label="$t('wdm16.12985')">
            <b-input-group>
              <b-form-select class="form-control" v-model="selectedParamData.default" :disabled="!d_isThisModelEditable" @change="f_forceUpdate">
                <option v-for="(object_type, object_type_ind) in selectedParamData.options" :value="object_type">
                  {{ object_type.label }}
                </option>
              </b-form-select>
              <b-input-group-prepend v-if="d_isThisModelEditable">
                <b-button variant="danger" @click="f_cleanDefaultOption"><i class="fa fa-trash"></i></b-button>
              </b-input-group-prepend>
            </b-input-group>
          </b-form-group>
          <b-row>
            <b-col sm="12" md="3">
              Parametre resmi
              <b-button v-if="selectedParamData.image_info && selectedParamData.image_info.data !== ''" @click="f_deleteParamImage()"> <i class="fa fa-trash"></i> {{ $t('wdm16.12987') }} </b-button>
            </b-col>
            <b-col sm="12" md="3">
              <b-form-file @input="f_addImageToSelectedParameter()" v-model="d_selectedImage" accept="jpeg,jpg,png" :placeholder="$t('wdm16.12988')" :drop-placeholder="$t('wdm16.12989')"></b-form-file>
            </b-col>
            <b-col sm="12" md="3">
              <template v-if="selectedParamData.image_info && selectedParamData.image_info.data !== ''">
                <b-form-select v-model="selectedParamData.image_info.side" @change="f_forceUpdate">
                  <option value="icon">{{ $t('wdm16.12990') }}</option>
                  <option value="bottom">{{ $t('wdm16.12991') }}</option>
                </b-form-select>
                <b-row style="margin: 0px;">
                  <b-col cols="12">
                    <img :src="selectedParamData.image_info.data" style="width: 25%;">
                  </b-col>
                </b-row>
              </template>
            </b-col>
          </b-row>
          <!-- EDITABLE -->
        </template>
        <template v-else>
          <b-form-group :label="$t('wdm16.12956')" label-for="recording_requirement" :label-cols="6">
            <b-badge :variant="selectedParamData.required === 'yes' ? 'success' : 'danger'" style="cursor: pointer;"> {{ $t('wdm16.12955') }} (RE) </b-badge>
          </b-form-group>
          <b-form-group :label="$t('wdm16.12957')" label-for="show_param_while_recording" :label-cols="6">
            <b-badge :variant="selectedParamData.show === 'yes' ? 'success' : 'danger'" style="cursor: pointer;"> {{ $t('wdm16.12958') }} (SOR) </b-badge>
          </b-form-group>
          <b-form-group v-if="option_data[data_type].favorite_show_mode === 'yes'" :label="$t('wdm16.11344')" label-for="favorite_show_mode" :label-cols="6">
            <b-badge :variant="selectedParamData.fav_show === 'yes' ? 'success' : 'danger'" style="cursor: pointer;"> {{ $t('wdm16.11344') }} (FS) </b-badge>
          </b-form-group>
          <b-form-group :label="$t('wdm16.12923')" label-for="lab_unit" :label-cols="6">
            <b-badge :variant="selectedParamData.lab_unit ? 'success' : 'danger'" style="cursor: pointer;"> {{ $t('wdm16.12924') }} (LU) </b-badge>
          </b-form-group>
          <b-form-group :label="$t('wdm16.12925')" label-for="show_size_units" :label-cols="6">
            <b-badge :variant="selectedParamData.show_size_units ? 'success' : 'danger'" style="cursor: pointer;"> {{ $t('wdm16.12926') }} (SU) </b-badge>
          </b-form-group>
          <b-form-group :label="$t('wdm16.12927')" label-for="show_weight_units" :label-cols="6">
            <b-badge :variant="selectedParamData.show_weight_units ? 'success' : 'danger'" style="cursor: pointer;"> {{ $t('wdm16.12928') }} (WU) </b-badge>
          </b-form-group>
          <b-form-group :label="$t('wdm16.12929')" label-for="show_dosage_units" :label-cols="6">
            <b-badge :variant="selectedParamData.show_dosage_units ? 'success' : 'danger'" style="cursor: pointer;"> {{ $t('wdm16.12930') }} (DU) </b-badge>
          </b-form-group>
          <b-form-group :label="$t('wdm16.12931')" label-for="not_schema" :label-cols="6">
            <b-badge :variant="selectedParamData.not_schema ? 'success' : 'danger'" style="cursor: pointer;"> {{ $t('wdm16.12931') }} (Ş) </b-badge>
          </b-form-group>
          <b-form-group :label="$t('wdm16.12933')" label-for="anonym" :label-cols="6">
            <b-badge :variant="selectedParamData.anonym ? 'success' : 'danger'" style="cursor: pointer;"> {{ $t('wdm16.12933') }} (A) </b-badge>
          </b-form-group>
          <b-form-group :label="$t('wdm16.12935')" label-for="auto_naming" :label-cols="6">
            <b-badge :variant="selectedParamData.naming ? 'success' : 'danger'" style="cursor: pointer;"> {{ $t('wdm16.12935') }} (N) </b-badge>
          </b-form-group>
          <b-form-group :label="$t('wdm16.12935')" label-for="dont_use_label" :label-cols="6">
            <b-badge :variant="selectedParamData.dont_use_label ? 'success' : 'danger'" style="cursor: pointer;"> {{ $t('wdm16.12938') }} (DL) </b-badge>
          </b-form-group>
          <b-form-group :label="$t('wdm16.12939')" label-for="show_after_recorded" :label-cols="6">
            <b-badge :variant="selectedParamData.page_not_show ? 'danger' : 'success'" style="cursor: pointer;"> {{ $t('wdm16.12939') }} (SAR) </b-badge>
          </b-form-group>
          <b-form-group v-if="page_type === 'hospital_settings'" :label="$t('wdm16.12941')" label-for="hims_match_operations" :label-cols="6">
            <b-badge :variant="selectedParamData.hims_match_operations ? 'success' : 'danger'" style="cursor: pointer;"> {{ $t('wdm16.12784') }} </b-badge>
          </b-form-group>
          <b-form-group :label="$t('wdm16.1675')" label-for="selectedParamData_info" :label-cols="6">
            {{ selectedParamData.info }}
          </b-form-group>
          <b-form-group v-if="['integer', 'float'].indexOf(selectedParamData.type) !== -1" :label="$t('wdm16.12943')" label-for="selectedParamData_max" :label-cols="6">
            {{ selectedParamData.min }}
          </b-form-group>
          <b-form-group v-if="['text', 'textarea', 'integer', 'float'].indexOf(selectedParamData.type) !== -1" :label="$t('wdm16.12945')" label-for="selectedParamData_max" :label-cols="6">
            {{ selectedParamData.max }}
          </b-form-group>
          <b-form-group v-if="['select', 'object_list'].indexOf(selectedParamData.type) !== -1" label="Selectbox Türü" label-for="selectedParamData_selectbox_type" :label-cols="6">
            {{ selectedParamData.selectbox_type }}
          </b-form-group>
          <b-form-group v-if="['checkbox'].indexOf(selectedParamData.type) !== -1" label="Checkbox Türü" label-for="selectedParamData_checkbox_type" :label-cols="6">
            {{ selectedParamData.checkbox_type }}
          </b-form-group>
          <!-- <b-form-group v-if="['object_list', 'object_list_options', 'list_parameter'].indexOf(selectedParamData.type) === -1" :label="$t('wdm16.12953')" label-for="selectedParamData_wdm_specific" :label-cols="6">
            {{ selectedParamData.wdm_specific }}
          </b-form-group> -->
          <!-- NOT EDITABLE -->
          <b-form-group v-if="['select', 'object_list', 'object_list_options', 'checkbox', 'radiogroup'].indexOf(selectedParamData.type) !== -1" :label="$t('wdm16.12975')" label-for="parameter_option_method" :label-cols="6">
            {{ selectedParamData.parameter_option_method }}
          </b-form-group>
          <b-form-group v-if="selectedParamData.parameter_option_method && selectedParamData.parameter_option_method === 'option_search'" :label="$t('wdm16.12975')" label-for="parameter_option_method_2" :label-cols="6">
            {{ selectedParamData.option_search }}
          </b-form-group>
          <b-form-group v-if="selectedParamData.parameter_option_method && selectedParamData.parameter_option_method === 'option_search' && selectedParamData.option_search === 'get_hospital_wtl_list'" :label="$t('wdm16.12980')" label-for="option_search_hospital_wtl" :label-cols="6">
            {{ selectedParamData.option_search_hospital_wtl }}
          </b-form-group>
          <b-form-group v-if="selectedParamData.option_search === 'get_patient_wdmr_list'" label="WDMR Türünü Seçin" label-for="option_search_patient_wdmr" :label-cols="6">
            {{ selectedParamData.option_search_patient_wdmr }}
          </b-form-group>
          <template v-if="selectedParamData.type === 'object_list'">
            <template v-if="selectedParamData.object_type && selectedParamData.object_param && upper_parameters[selectedParamData.object_param]">
              {{ upper_parameters[selectedParamData.object_param].object_type = selectedParamData.object_type }}
            </template>
            <b-form-group label="Obje parametresi" label-for="object_param" :label-cols="6">
              {{ selectedParamData.object_param ? selectedParamData.object_param.label : '' }}
            </b-form-group>
            <b-form-group :label="$t('wdm16.12983')" label-for="object_type" :label-cols="6">
              {{ selectedParamData.object_type }}
            </b-form-group>
          </template>
          <b-form-group v-if="selectedParamData.type === 'object_list_options'" :label="$t('wdm16.12984')" label-for="object_record_type" :label-cols="6">
            {{ selectedParamData.object_type }}
          </b-form-group>
          <b-form-group v-if="select_types.indexOf(selectedParamData.type) !== -1" :label-cols="6" :label="$t('wdm16.12985')">
            <b-input-group>
              {{ selectedParamData.default ? selectedParamData.default.label : '' }}
            </b-input-group>
          </b-form-group>
          <b-row>
            <b-col sm="12" md="3">
              Parametre resmi
            </b-col>
            <b-col sm="12" md="3">
            </b-col>
            <b-col sm="12" md="3">
              <template v-if="selectedParamData.image_info && selectedParamData.image_info.data !== ''">
                <b-row style="margin: 0px;">
                  <b-col cols="12">
                    <img :src="selectedParamData.image_info.data" style="width: 25%;">
                  </b-col>
                </b-row>
              </template>
            </b-col>
          </b-row>
          <!-- NOT EDITABLE -->
        </template>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
import {
  default as Modal
} from "@/components/widgets/Modal";
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
  name: 'ParameterDetails',
  computed: mapGetters({
    lang: 'lang',
    device: 'device'
  }),
  props: {
    d_isThisModelEditable: {
      type: Boolean,
      required: true
    },
    f_mainParameterSelectionStyle: {
      type: Function,
      required: true
    },
    selectedParamData: {
      type: Object,
      required: true
    },
    upper_parameters: {
      type: Object,
      required: true
    },
    upper_param_order: {
      type: Array,
      required: true
    },
    option_data: {
      type: Object,
      required: true
    },
    param_change: {
      type: Object,
      required: true
    },
    data_type: {
      type: String,
      required: true
    },
    page_type: {
      type: String,
      required: true
    }
  },
  components: {
    Modal,
    WisdomDataModal
  },
  data () {
    return {
      select_types: ['select', 'object_list', 'checkbox', 'radiogroup'],
      d_selectedImage: '',
      d_parameterFunctionOptions: [],
      show_edit_parameter_label: false,
      d_wisdomeraSystem: {},
      w_presentation: false,
      d_show: false,
      user: {},
      d_selectedMenu: 'general',
      new_param_label: ''
    };
  },
  created: function () {
    this.d_wisdomeraSystem = JSON.parse(localStorage.getItem('wisdomera_system'));
    if (this.d_wisdomeraSystem) {
      this.d_parameterFunctionOptions = JSON.parse(JSON.stringify(this.d_wisdomeraSystem['parameter_functions']));
    }
    this.w_presentation = JSON.parse(localStorage.getItem('presentation'));
    this.d_wdmr22 = JSON.parse(localStorage.getItem('wdm22'));
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  mounted: function () {
    this.f_hideShow();
  },
  watch: {
    'selectedParamData.parameter_option_method': function () {
      if (this.selectedParamData.parameter_option_method === 'manuel') {
        this.$delete(this.selectedParamData, 'option_search');
      }
      this.f_forceUpdate();
    },
    'selectedParamData': function () {
      this.f_forceUpdate();
    }
  },
  methods: {
    f_cleanDefaultOption: function () {
      this.$delete(this.selectedParamData, 'default');
      this.f_forceUpdate();
    },
    f_hideShow: function () {
      this.d_show = false;
      setTimeout(function () {
        this.d_show = true;
      }.bind(this), 50);
    },
    f_forceUpdate: function () {
      this.$forceUpdate();
    },
    f_addImageToSelectedParameter: function () {
      if (this.d_selectedImage) {
        let file_name = this.d_selectedImage.name;
        let arr = this.d_selectedImage.name.split('.');
        let fileExtension = arr[arr.length - 1];
        // console.log(fileExtension);
        if (["jpg", "jpeg", "png"].indexOf(fileExtension.toLowerCase()) === -1) {
          this.d_selectedImage = '';
          this.f_forceUpdate();
          alert(this.$t('wdm16.12634'));
          return;
        }
        if (this.d_selectedImage.size > 2097152) {
          this.d_selectedImage = '';
          this.f_forceUpdate();
          alert(this.$t('wdm16.12635'));
          return;
        }

        var reader = new FileReader();
        var vm = this;
        let doc_data = {
          'user': this.user.username,
          'information': '',
          'fileExtension': fileExtension,
          'file_name': file_name,
          'side': 'icon',
          'data': ''
        };
        this.selectedParamData.image_info = doc_data;
        var reader = new FileReader();
        reader.addEventListener("load", function () {
          vm.selectedParamData.image_info.data = reader.result;
          vm.f_forceUpdate();
        }, false);
        reader.readAsDataURL(this.d_selectedImage);
        this.d_selectedImage = '';
      }
    },
    f_deleteParamImage: function () {
      this.$delete(this.selectedParamData, 'image_info');
      this.f_forceUpdate();
    },
    param_name_func: function () {
      this.selectedParamData.naming ? this.$delete(this.selectedParamData, 'naming') : this.selectedParamData.naming = '1';
      this.f_forceUpdate();
    },
    param_dont_use_label_func: function () {
      this.selectedParamData.dont_use_label ? this.$delete(this.selectedParamData, 'dont_use_label') : this.selectedParamData.dont_use_label = '1';
      this.f_forceUpdate();
    },
    param_lab_unit_func: function () {
      this.selectedParamData.lab_unit ? this.$delete(this.selectedParamData, 'lab_unit') : this.selectedParamData.lab_unit = '1';
      this.f_forceUpdate();
    },
    param_show_size_units_func: function () {
      this.selectedParamData.show_size_units ? this.$delete(this.selectedParamData, 'show_size_units') : this.selectedParamData.show_size_units = '1';
      this.f_forceUpdate();
    },
    param_show_weight_units_func: function () {
      this.selectedParamData.show_weight_units ? this.$delete(this.selectedParamData, 'show_weight_units') : this.selectedParamData.show_weight_units = '1';
      this.f_forceUpdate();
    },
    param_show_dosage_units_func: function () {
      this.selectedParamData.show_dosage_units ? this.$delete(this.selectedParamData, 'show_dosage_units') : this.selectedParamData.show_dosage_units = '1';
      this.f_forceUpdate();
    },
    param_not_schema_func: function () {
      this.selectedParamData.not_schema ? this.$delete(this.selectedParamData, 'not_schema') : this.selectedParamData.not_schema = '1';
      this.f_forceUpdate();
    },
    param_anonym_func: function () {
      this.selectedParamData.anonym ? this.$delete(this.selectedParamData, 'anonym') : this.selectedParamData.anonym = '1';
      this.f_forceUpdate();
    },
    param_option_search_func: function () {
      this.selectedParamData.option_search ? this.$delete(this.selectedParamData, 'option_search') : this.selectedParamData.option_search = '';
      this.f_forceUpdate();
    },
    param_page_not_show_func: function () {
      this.selectedParamData.page_not_show ? this.$delete(this.selectedParamData, 'page_not_show') : this.selectedParamData.page_not_show = '0';
      this.f_forceUpdate();
    },
    param_hims_match_operations: function () {
      this.selectedParamData.hims_match_operations ? this.$delete(this.selectedParamData, 'hims_match_operations') : this.selectedParamData.hims_match_operations = '0';
      this.f_forceUpdate();
    },
    param_require_func: function () {
      if (this.selectedParamData.required === 'yes') {
        this.selectedParamData.required = 'no';
      } else {
        this.selectedParamData.required = 'yes';
      }
      this.f_forceUpdate();
    },
    param_show_func: function () {
      if (this.selectedParamData.show === 'yes') {
        this.selectedParamData.show = 'no';
      } else {
        this.selectedParamData.show = 'yes';
      }
      this.f_forceUpdate();
    },
    print_show_func: function () {
      if (this.selectedParamData.dont_print_caption === 'yes') {
        this.selectedParamData.dont_print_caption = 'no';
      } else {
        this.selectedParamData.dont_print_caption = 'yes';
      }
      this.f_forceUpdate();
    },
    param_ability: function () {
      if (this.selectedParamData.disabled) {
        this.$delete(this.selectedParamData, 'disabled');
      } else {
        this.selectedParamData.disabled = 1;
      }
      this.f_forceUpdate();
    },
    param_fav_show_func: function () {
      if (this.selectedParamData.fav_show && this.selectedParamData.fav_show === 'yes') {
        this.selectedParamData.fav_show = 'no';
      } else {
        this.selectedParamData.fav_show = 'yes';
      }
      this.f_forceUpdate();
    },
    EditParameterLabel: function () {
      this.new_param_label = this.selectedParamData.name.label;
      this.show_edit_parameter_label = true;
      this.f_forceUpdate();
    },
    SaveParameterLabel: function () {
      this.selectedParamData.name.label = this.new_param_label;
      this.new_param_label = '';
      this.show_edit_parameter_label = false;
      this.f_forceUpdate();
    },
    f_motherChildren: function (op) {
      if (this.param_change[op] === 0) {
        this.param_change[op] = 1;
      } else {
        this.param_change[op] = 0;
      }
    }
  }
};

</script>

<style type="text/css">
.menusubitem {
  float: left;
  min-width: 50px;
}

.menuitem {
  float: left;
  min-width: 75px;
  height: 100%;
  padding: 3px;
}

</style>

