<template>
  <div v-if="option_data[data_type]" :class="[d_fullScreen ? 'full-screen-mode' : 'normal-mode']" :style="option_data[data_type] && option_data[data_type].color ? 'border-radius: 5px 0px 0px 5px; border-left: solid 4px ' + option_data[data_type].color : 'border-left: solid 4px #c8ced3; border-radius: 5px 0px 0px 5px;'">
    <!-- <b-row v-if="data.document_list && data.document_list.length > 0 && !rule_engine && !polestar"> -->
    <b-row v-if="!rule_engine && !polestar">
      <b-col sm="12" :md="d_wdmrSettings.right_page_mode.page ? '6' : '12'">
        <template v-if="p_schemaEdit">
          <b-card no-body header-tag="header" footer-tag="footer" border-variant="primary" class="mb-1">
            <b-row>
              <b-col cols="12">
                <b-form-group :label="$t('wdm16.11472')" label-for="new_schema_group" :label-cols="3">
                  <v-select v-model="p_schemaEdit.new_schema_group" name="new_schema_group" :options="d_editSchemaGroupList" style="width:100%;">
                  </v-select>
                </b-form-group>
                <b-form-group :label="$t('wdm16.3280')" label-for="schema_label" :label-cols="3">
                  <b-form-input type="text" class="form-control-warning" id="schema_label" v-model="p_schemaEdit.label"></b-form-input>
                </b-form-group>
                <b-button @click="f_editSaveSchema()" block variant="warning">{{ $t('wdm16.11473') }}</b-button>
              </b-col>
            </b-row>
          </b-card>
        </template>
      </b-col>
      <b-col sm="12" md="6" v-if="d_wdmrSettings.right_page_mode.page">
        <b-row>
          <b-col sm="12" md="4">
            <b-button variant="secondary" @click="d_wdmrSettings.right_page_mode.page === 'wisdomdatashow' ? d_wdmrSettings.right_page_mode.page = 'document_upload' : d_wdmrSettings.right_page_mode.page = 'wisdomdatashow'">{{ $t('wdm16.11476') }} </b-button>
          </b-col>
          <b-col sm="12" md="4">
            <b-form-select :plain="true" v-model="wisdom_data_show_view_mode" required>
              <option value="table">{{ $t('wdm16.11474') }}</option>
              <option value="sentence">{{ $t('wdm16.11475') }}</option>
            </b-form-select>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row style="margin-left: 0px;">
      <b-col sm="12" :md="d_wdmrSettings.right_page_mode.page ? '6' : '12'" style="padding-left: 0px;">
        <template v-if="data.project && data.project.length > 0">
          <b-card no-body header-tag="header" footer-tag="footer" border-variant="primary" class="mb-1">
            <b-card-header header-bg-variant="primary" class="p-1">
              <strong>{{ $t('wdm16.11477') }}</strong>
            </b-card-header>
            <b-row v-for="(pr, pr_ind) in data.project" :key="'data.project_' + pr_ind" style="margin: 2px;">
              <b-col sm="12" md="4" lg="4">
                Proje Adı: {{ pr.label }}
              </b-col>
              <b-col sm="12" md="8" lg="8">
                <b-form-select v-model="pr.status">
                  <option value="upload">{{ $t('wdm16.13184') }}</option>
                  <option value="record">{{ $t('wdm16.13185') }}</option>
                  <option value="control">{{ $t('wdm16.13186') }}</option>
                  <option value="approve">{{ $t('wdm16.4268') }}</option>
                </b-form-select>
              </b-col>
            </b-row>
          </b-card>
        </template>
        <template>
          <b-card key="up_side" v-if="!rule_engine && !polestar" class="mb-1" no-body header-tag="header" footer-tag="footer">
            <template v-if="d_wdmrSettings.wdmr_header_mode === 2">
              <b-row style="margin: 0px;">
                <b-col sm="12" md="8" style="padding: 0px;">
                  <span :style="option_data[data_type] && option_data[data_type].color ? 'margin-left: 0px; padding: 3px; border-radius: 0px 3px 3px 0px; font-size: 10px; background-color: ' + option_data[data_type].color : 'margin-left: 0px; padding: 3px; border-radius: 0px 3px 3px 0px; font-size: 10px; background-color: #c8ced3;'">
                    {{ option_data[data_type] && option_data[data_type].name ? option_data[data_type].name.label : '' }} ( v{{ option_data[data_type] && option_data[data_type].version ? option_data[data_type].version : '' }} )
                    </span>
                  <b-dropdown v-if="!p_waiTalkAlgorithm && f_canEditThisWdm()" variant="secondary" size="sm" right style="margin-right: 5px; margin-left: 5px;">
                    <template v-slot:button-content>
                      <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="width: 1.5em;" />
                    </template>
                    <b-dropdown-item v-if="f_canEditThisWdm()" @click="f_editWdm()">
                      <img src="@/icon/2717434.png" style="width: 2.5em;" /> {{ $t('wdm16.13187') }}
                    </b-dropdown-item>
                  </b-dropdown>
                  <span v-if="data.id !== undefined && data.id !== '' && data.id !== 'new'" style="margin-left: 3px; border-radius: 3px 3px; font-size: 10px; background-color: #f19b9b; padding: 3px;"> güncelle </span>
                  <span v-else style="margin-left: 3px; border-radius: 3px 3px; font-size: 10px; background-color: #8bc34a; padding: 3px;"> {{ $t('wdm16.9939') }} </span>
                </b-col>
                <b-col sm="12" md="4" style="padding: 0px; cursor: pointer;" @click="d_showDateModal = true">
                  {{ DateFormat2(data.date) }}
                </b-col>
              </b-row>
            </template>
            <template v-if="d_wdmrSettings.wdmr_header_mode === 3">
              <b-card-header header-bg-variant="secondary" class="p-1" :style="option_data[data_type] && option_data[data_type].color ? 'border-bottom: solid 1px ' + option_data[data_type].color : ''">
                <b-row style="margin: 2px;">
                  <b-col sm="12" :md="d_wdmrSettings.page_divide === 2 || d_wdmrSettings.page_divide === 3 || d_wdmrSettings.page_divide === 4 ? 12 : 5">
                    <span :style="option_data[data_type] && option_data[data_type].color ? 'font-size: 14px; background-color: ' + option_data[data_type].color : 'font-size: 14px;'"> {{ option_data[data_type] && option_data[data_type].name ? option_data[data_type].name.label : '' }} ( v{{ option_data[data_type] && option_data[data_type].version ? option_data[data_type].version : '' }} )
                    </span>
                    <!--         <b-badge variant="secondary" :style="option_data[data_type] && option_data[data_type].color ? 'font-size: 12px; background-color: ' + option_data[data_type].color : 'font-size: 12px;'"> {{ option_data[data_type] && option_data[data_type].name ? option_data[data_type].name.label : '' }} ( v{{ option_data[data_type] && option_data[data_type].version ? option_data[data_type].version : '' }} )
                    </b-badge> -->
                  </b-col>
                  <b-col sm="12" :md="d_wdmrSettings.page_divide === 2 || d_wdmrSettings.page_divide === 3 || d_wdmrSettings.page_divide === 4 ? 12 : 5">
                    <template v-if="data.date_type === 'datetime'">
                      <datepicker :config="{ enableTime: true, minDate: option_data[data_type].date_ability_limit === 'after' ? d_dateAbilityLimit : '', maxDate: option_data[data_type].date_ability_limit === 'before' ? d_dateAbilityLimit : '', time_24hr: true}" class="form-control" v-model="data.date" @input="f_calculateEndingDateLimit"></datepicker>
                    </template>
                    <template v-else-if="data.date_type === 'date'">
                      <b-form-input type="date" :min="option_data[data_type].date_ability_limit === 'after' ? d_dateAbilityLimit : ''" :max="option_data[data_type].date_ability_limit === 'before' ? d_dateAbilityLimit : ''" v-model="data.date" @input="f_calculateEndingDateLimit"></b-form-input>
                    </template>
                    <template v-else-if="data.date_type === 'yearmonth'">
                      <input type="month" :min="option_data[data_type].date_ability_limit === 'after' ? d_dateAbilityLimit : '1000-01'" :max="option_data[data_type].date_ability_limit === 'before' ? d_dateAbilityLimit : '9999-12'" v-model="data.date" @input="f_calculateEndingDateLimit">
                    </template>
                    <template v-else-if="data.date_type === 'year'">
                      <input type="number" :min="option_data[data_type].date_ability_limit === 'after' ? d_dateAbilityLimit : '1000'" :max="option_data[data_type].date_ability_limit === 'before' ? d_dateAbilityLimit : '9999'" step="1" v-model="data.date" @input="f_calculateEndingDateLimit">
                    </template>
                  </b-col>
                  <b-col sm="12" :md="d_wdmrSettings.page_divide === 2 || d_wdmrSettings.page_divide === 3 || d_wdmrSettings.page_divide === 4 ? 12 : 2">
                    <b-button class="pull-right" size="md" style="margin-right: 5px; padding: 0px;" variant="secondary" @click="f_changeWdmrSettingsHeaderMode()" :title="$t('wdm16.13188')">
                      <img src="@/icon/688807.png" style="width: 2.5em;" />
                    </b-button>
                    <b-dropdown v-if="!p_waiTalkAlgorithm" class="pull-right" variant="secondary" size="md" right style="margin-right: 5px;">
                      <template v-slot:button-content>
                        <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="width: 1.5em;" />
                      </template>
                      <b-dropdown-item @click="f_updateWdmSettings('add')">
                        <img src="@/icon/2087726.png" style="width: 2.5em;" /> {{ $t('wdm16.13193') }}
                      </b-dropdown-item>
                      <b-dropdown-item @click="f_printWdmr()">
                        <img src="@/icon/950780.png" style="width: 2.5em;" /> Yazdır
                      </b-dropdown-item>
                      <b-dropdown-item @click="f_showLanguageChange()">
                        <img src="@/icon/2569592.png" style="width: 2.5em;" /> {{ $t('wdm16.13190') }}
                      </b-dropdown-item>
                      <b-dropdown-item v-if="f_canEditThisWdm()" @click="f_editWdm()">
                        <img src="@/icon/2717434.png" style="width: 2.5em;" /> {{ $t('wdm16.13187') }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                </b-row>
              </b-card-header>
            </template>
            <template v-if="d_wdmrSettings.wdmr_header_mode === 4">
              <b-card-header header-bg-variant="secondary" class="p-1" :style="option_data[data_type] && option_data[data_type].color ? 'border-bottom: solid 1px ' + option_data[data_type].color : ''">
                <b-row style="margin: 2px;">
                  <b-col sm="12" :lg="d_wdmrSettings.page_divide === 2 || d_wdmrSettings.page_divide === 3 || d_wdmrSettings.page_divide === 4 ? 12 : 2" style="padding: 3px;">
                    <span :style="option_data[data_type] && option_data[data_type].color ? 'font-size: 12px; border-radius: 5px; background-color: ' + option_data[data_type].color : 'font-size: 14px;'"> {{ option_data[data_type] && option_data[data_type].name ? option_data[data_type].name.label : '' }} ( v{{ option_data[data_type] && option_data[data_type].version ? option_data[data_type].version : '' }} )
                    </span>
                  </b-col>
                  <b-col sm="12" :lg="d_wdmrSettings.page_divide === 2 || d_wdmrSettings.page_divide === 3 || d_wdmrSettings.page_divide === 4 ? 12 : 10">
                    <template v-if="!rule_engine && !polestar">
                      <b-button class="pull-right" size="md" style="margin-right: 5px; padding: 0px;" variant="warning" @click="f_changeWdmrSettingsHeaderMode()" :title="$t('wdm16.13192')">
                        <img src="@/icon/688807.png" style="width: 2.5em;" />
                      </b-button>
                      <b-dropdown v-if="!p_waiTalkAlgorithm" class="pull-right" variant="white" size="md" right style="margin-right: 5px;">
                        <template v-slot:button-content>
                          <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="width: 1.5em;" />
                        </template>
                        <b-dropdown-item @click="f_updateWdmSettings('add')">
                          <img src="@/icon/2087726.png" style="width: 2.5em;" /> {{ $t('wdm16.13193') }}
                        </b-dropdown-item>
                        <b-dropdown-item @click="f_printWdmr()">
                          <img src="@/icon/950780.png" style="width: 2.5em;" /> Yazdır
                        </b-dropdown-item>
                        <b-dropdown-item @click="f_showLanguageChange()">
                          <img src="@/icon/2569592.png" style="width: 2.5em;" /> {{ $t('wdm16.13190') }}
                        </b-dropdown-item>
                        <b-dropdown-item v-if="f_canEditThisWdm()" @click="f_editWdm()">
                          <img src="@/icon/2717434.png" style="width: 2.5em;" /> {{ $t('wdm16.13187') }}
                        </b-dropdown-item>
                      </b-dropdown>
                      <b-button class="pull-right" :title="$t('wdm16.6589')" size="md" :variant="d_fullScreen ? 'warning' : 'secondary'" style="margin-right: 5px; padding: 0px;" @click="d_fullScreen ? d_fullScreen = false : d_fullScreen = true">
                        <img src="@/icon/629153.png" style="width: 2.5em;" />
                      </b-button>
                      <b-button class="pull-right" title="Ekranı Böl (Sağ | Sol)" size="md" style="margin-right: 5px; padding: 0px;" :variant="d_wdmrSettings.right_page_mode.page ? 'warning' : 'secondary'" @click="f_rightPageMode()">
                        <img src="@/icon/2940638.png" style="width: 2.5em;" />
                      </b-button>
                      <b-button class="pull-right" size="md" v-if="parameter_list && parameter_list.length > 0" :variant="use_parameter_list ? 'warning' : 'secondary'" style="margin-right: 5px; padding: 0px;" @click="use_parameter_list ? use_parameter_list = false : use_parameter_list = true" :title="$t('wdm16.13196')">
                        <img src="@/icon/1517831.png" style="width: 2.5em;" /> [{{ parameter_list.join(', ') }}]</b-button>
                      <b-button v-if="!p_waiTalkAlgorithm" class="pull-right" size="md" style="margin-right: 5px; padding: 0px;" :variant="d_wdmrSettings.favorite_show_mode ? 'warning' : 'secondary'" @click="d_wdmrSettings.favorite_show_mode ? d_wdmrSettings.favorite_show_mode = 0 : d_wdmrSettings.favorite_show_mode = 1" :title="$t('wdm16.11277')">
                        <img src="@/icon/1869589.png" style="width: 2.5em;" />
                      </b-button>
                      <b-button v-if="!p_waiTalkAlgorithm" class="pull-right" size="md" style="margin-right: 5px; padding: 0px;" :variant="d_wdmrSettings.settings.show ? 'warning' : 'secondary'" @click="d_wdmrSettings.settings.show ? d_wdmrSettings.settings.show = 0 : d_wdmrSettings.settings.show = 1" :title="$t('wdm16.4037')">
                        <img src="@/icon/1584256.png" style="width: 2.5em;" />
                      </b-button>
                      <b-button class="pull-right" size="md" style="margin-right: 5px; padding: 0px;" variant="secondary" @click="f_changeAnatomyComponentMode()" :title="$t('wdm16.11278')">
                        <img src="@/icon/W3D_humanbody.png" style="width: 2.5em;" /> {{ d_wdmrSettings.anatomy_component.mode }}
                      </b-button>
                      <b-button class="pull-right" size="md" variant="secondary" :title="$t('wdm16.11279')" style="margin-right: 5px; padding: 0px" @click="f_anatomyGroupColumnDivide()">
                        <img src="@/icon/W3D_humanbody.png" style="width: 2.5em;" />
                        <img src="@/icon/2349601.png" style="width: 2.5em;" /> {{ d_wdmrSettings.anatomy_group.cols }}
                      </b-button>
                      <b-button v-if="option_data[data_type].anatomy" class="pull-right" size="md" style="margin-right: 5px; padding: 0px;" :variant="d_wdmrSettings.wdmr_schema.wdm_anatomy.show ? 'warning' : 'secondary'" @click="d_wdmrSettings.wdmr_schema.wdm_anatomy.show ? d_wdmrSettings.wdmr_schema.wdm_anatomy.show = 0 : d_wdmrSettings.wdmr_schema.wdm_anatomy.show = 1" :title="$t('wdm16.11280')">
                        <img src="@/icon/641.png" style="width: 2.5em;" />
                      </b-button>
                      <b-button class="pull-right" size="md" style="margin-right: 5px; padding: 0px;" :variant="d_wdmrSettings.group.show ? 'warning' : 'secondary'" @click="d_wdmrSettings.group.show ? d_wdmrSettings.group.show = 0 : d_wdmrSettings.group.show = 1" :title="$t('wdm16.11281')">
                        <img src="@/icon/45774.png" style="width: 2.5em;" />
                      </b-button>
                      <b-button v-if="option_data[data_type].schema && option_data[data_type].schema === 'yes'" class="pull-right" size="md" style="margin-right: 5px; padding: 0px;" :variant="d_wdmrSettings.wdmr_schema.wdm.show ? 'warning' : 'secondary'" @click="d_wdmrSettings.wdmr_schema.wdm.show ? d_wdmrSettings.wdmr_schema.wdm.show = 0 : d_wdmrSettings.wdmr_schema.wdm.show = 1" :title="$t('wdm16.11282')">
                        <img src="@/icon/3082142.png" style="width: 2.5em;" />
                      </b-button>
                      <b-button v-if="option_data[data_type].use_old_data && option_data[data_type].use_old_data === 'yes'" class="pull-right" size="md" style="margin-right: 5px; padding: 0px;" :variant="d_showWdmrOldDataRegion ? 'warning' : 'secondary'" @click="d_showWdmrOldDataRegion ? d_showWdmrOldDataRegion = false : d_showWdmrOldDataRegion = true" :title="$t('wdm16.13197')">
                        <img src="@/icon/287792.png" style="width: 2.5em;" />
                      </b-button>
                      <b-button v-if="user && user.permissions_result && user.permissions_result.indexOf('wisdom') !== -1" class="pull-right" size="md" style="margin-right: 5px; padding: 0px;" :variant="d_showAiRobot ? 'warning' : 'secondary'" @click="d_showAiRobot ? d_showAiRobot = false : d_showAiRobot = true" :title="$t('wdm16.13198')">
                        <img src="@/icon/2324511.png" style="width: 2.5em;" />
                      </b-button>
                      <b-button v-if="user && user.permissions_result && user.permissions_result.indexOf('wisdom') !== -1" class="pull-right" size="md" style="margin-right: 5px; padding: 0px;" :variant="d_autoNaming ? 'warning' : 'secondary'" @click="d_autoNaming ? d_autoNaming = false : d_autoNaming = true" :title="$t('wdm16.13199')">
                        <img src="@/icon/1421585.png" style="width: 2.5em;" />
                      </b-button>
                      <b-button v-if="!p_waiTalkAlgorithm" class="pull-right" size="md" style="margin-right: 5px; padding: 0px;" :variant="d_changeWdmrCreator ? 'warning' : 'secondary'" @click="f_changeWdmrCreator()" :title="$t('wdm16.13200')">
                        <img src="@/icon/2903969.png" style="width: 2.5em;" />
                      </b-button>
                      <b-button v-if="!p_waiTalkAlgorithm && option_data[data_type].document_upload && option_data[data_type].document_upload === 'yes'" class="pull-right" size="md" style="margin-right: 5px; padding: 0px;" :variant="d_wdmrSettings.document_upload.show ? 'warning' : 'secondary'" @click="d_wdmrSettings.document_upload.show ? d_wdmrSettings.document_upload.show = false : d_wdmrSettings.document_upload.show = true; $forceUpdate();" :title="$t('wdm16.6255')">
                        <img src="@/icon/736649.png" style="width: 2.5em;" />
                      </b-button>
                      <b-button class="pull-right" size="md" style="margin-right: 5px; padding: 0px;" @click="f_changeGroupMode()" :title="$t('wdm16.11283')">
                        <img src="@/icon/969174.png" style="width: 2.5em;" /> {{ d_wdmrSettings.group.mode }}
                      </b-button>
                      <b-button class="pull-right" size="md" variant="secondary" :title="$t('wdm16.11284')" style="margin-right: 5px; padding: 0px" @click="f_groupColumnDivide()">
                        <img src="@/icon/2349601.png" style="width: 2.5em;" /> {{ d_wdmrSettings.column_settings.cols }}
                      </b-button>
                      <b-button class="pull-right" size="md" variant="secondary" :title="$t('wdm16.11286')" style="margin-right: 5px; padding: 0px" @click="f_changeWisdomParamInputMode()">
                        <img src="@/icon/2063326.png" style="width: 2.5em;" /> {{ d_wdmrSettings.wisdom_param.mode }}
                      </b-button>
                      <b-button v-if="!p_waiTalkAlgorithm && (d_wdmrSettings.wisdom_param.mode === 2 || d_wdmrSettings.wisdom_param.mode === 3)" class="pull-right" size="md" :variant="d_wdmrSettings.wisdom_param.settings ? 'warning' : 'secondary'" @click="d_wdmrSettings.wisdom_param.settings ? d_wdmrSettings.wisdom_param.settings = 0 : d_wdmrSettings.wisdom_param.settings = 1" :title="$t('wdm16.11287')" style="margin-right: 5px; padding: 0px">
                        <img src="@/icon/2063326.png" style="width: 2.5em;" />
                        <img src="@/icon/1584256.png" style="width: 2.5em;" />
                      </b-button>
                      <b-button v-if="!p_waiTalkAlgorithm" class="pull-right" size="md" style="margin-right: 5px; padding: 0px;" :title="$t('wdm16.13201')" :variant="d_wdmrSettings.wdm_designer.show ? 'warning' : 'secondary'" @click="d_wdmrSettings.wdm_designer.show ? d_wdmrSettings.wdm_designer.show = 0 : d_wdmrSettings.wdm_designer.show = 1">
                        <img src="@/icon/2825693.png" style="width: 2.5em;" />
                      </b-button>
                      <b-button v-if="d_wdmrSettings.wdm_designer.show" class="pull-right" size="md" style="margin-right: 5px; padding: 0px;" :title="$t('wdm16.13202')" :variant="d_wdmrSettings.wdm_designer.settings ? 'warning' : 'secondary'" @click="d_wdmrSettings.wdm_designer.settings ? d_wdmrSettings.wdm_designer.settings = 0 : d_wdmrSettings.wdm_designer.settings = 1">
                        <img src="@/icon/719135.png" style="width: 2.5em;" />
                      </b-button>
                    </template>
                  </b-col>
                </b-row>
              </b-card-header>
            </template>
            <template v-if="d_changeWdmrCreator">
              <b-card-header header-bg-variant="primary" class="p-1">
                WDMR Data Sahibi Seçim Ekranı
              </b-card-header>
              <b-row>
                <b-col cols="12">
                  <v-select v-model="data.d_changeWdmrCreator" name="d_changeWdmrCreator" :options="d_userListWithDepartments" style="width:100%;">
                  </v-select>
                </b-col>
              </b-row>
            </template>
            <b-card-header header-bg-variant="primary" class="p-1" v-if="d_autoNaming">
              <b-row>
                <b-col cols="12">
                  <strong>{{ data.label }}</strong>
                </b-col>
              </b-row>
            </b-card-header>
            <template v-if="d_showAiRobot">
              <wdm-ai-robot :param_change="param_change" :p_wdmAiList="d_wdmAiWdmList" :p_aiRobotMatchParameterResult="d_aiRobotMatchParameterResult"></wdm-ai-robot>
            </template>
            <template v-if="d_wdmrSettings.date.show_as_row">
              <b-row style="margin: 2px;" v-if="option_data[data_type].date_ability === 'yes' && d_showDate">
                <b-col sm="11" md="4" lg="4"> <strong>{{ $t('wdm16.13204') }} </strong> </b-col>
                <b-col sm="11" md="4" lg="4">
                  <template v-if="data.date_type === 'datetime'">
                    <datepicker :config="{ enableTime: true, minDate: option_data[data_type].date_ability_limit === 'after' ? d_dateAbilityLimit : '', maxDate: option_data[data_type].date_ability_limit === 'before' ? d_dateAbilityLimit : '', time_24hr: true}" class="form-control" v-model="data.date" @input="f_calculateEndingDateLimit"></datepicker>
                  </template>
                  <template v-else-if="data.date_type === 'date'">
                    <b-form-input type="date" :min="option_data[data_type].date_ability_limit === 'after' ? d_dateAbilityLimit : ''" :max="option_data[data_type].date_ability_limit === 'before' ? d_dateAbilityLimit : ''" v-model="data.date" @input="f_calculateEndingDateLimit"></b-form-input>
                  </template>
                  <template v-else-if="data.date_type === 'yearmonth'">
                    <input type="month" :min="option_data[data_type].date_ability_limit === 'after' ? d_dateAbilityLimit : '1000-01'" :max="option_data[data_type].date_ability_limit === 'before' ? d_dateAbilityLimit : '9999-12'" v-model="data.date" @input="f_calculateEndingDateLimit">
                  </template>
                  <template v-else-if="data.date_type === 'year'">
                    <input type="number" :min="option_data[data_type].date_ability_limit === 'after' ? d_dateAbilityLimit : '1000'" :max="option_data[data_type].date_ability_limit === 'before' ? d_dateAbilityLimit : '9999'" step="1" v-model="data.date" @input="f_calculateEndingDateLimit">
                  </template>
                </b-col>
                <b-col sm="11" md="3" lg="3" v-if="option_data[data_type].date_ability_user_selection === 'yes'">
                  <select v-model="data.date_type" class="form-control" @input="f_changeDateType">
                    <option value="year">{{ $t('wdm16.12749') }}</option>
                    <option value="yearmonth">{{ $t('wdm16.12750') }}</option>
                    <option value="date">{{ $t('wdm16.12751') }}</option>
                    <option value="datetime">{{ $t('wdm16.12752') }}</option>
                  </select>
                </b-col>
              </b-row>
              <b-row style="margin: 2px;" v-if="option_data[data_type].ending_date_ability === 'yes' && d_showEndingDate">
                <b-col sm="11" md="4" lg="4"> <strong>{{ $t('wdm16.13209') }} </strong> </b-col>
                <b-col sm="11" md="4" lg="4">
                  <template v-if="data.ending_date_type === 'datetime'">
                    <datepicker :config="{ enableTime: true, minDate: d_dateAndEndingDatelimit, maxDate: option_data[data_type].ending_date_ability_limit === 'before' ? d_endingDateAbilityLimit : '', time_24hr: true}" class="form-control" v-model="data.ending_date"></datepicker>
                  </template>
                  <template v-else-if="data.ending_date_type === 'date'">
                    <b-form-input type="date" :min="d_dateAndEndingDatelimit" :max="option_data[data_type].ending_date_ability_limit === 'before' ? d_endingDateAbilityLimit : ''" v-model="data.ending_date"></b-form-input>
                  </template>
                  <template v-else-if="data.ending_date_type === 'yearmonth'">
                    <input type="month" :min="d_dateAndEndingDatelimit" :max="option_data[data_type].ending_date_ability_limit === 'before' ? d_endingDateAbilityLimit : '9999-12'" v-model="data.ending_date">
                  </template>
                  <template v-else-if="data.ending_date_type === 'year'">
                    <input type="number" :min="d_dateAndEndingDatelimit" :max="option_data[data_type].ending_date_ability_limit === 'before' ? d_endingDateAbilityLimit : '9999'" step="1" v-model="data.ending_date" />
                  </template>
                </b-col>
                <b-col sm="11" md="3" lg="3" v-if="option_data[data_type].ending_date_ability_user_selection === 'yes'">
                  <select v-model="data.ending_date_type" class="form-control" @input="f_changeEndingDateType">
                    <option value="year">{{ $t('wdm16.12749') }}</option>
                    <option value="yearmonth">{{ $t('wdm16.12750') }}</option>
                    <option value="date">{{ $t('wdm16.12751') }}</option>
                    <option value="datetime">{{ $t('wdm16.12752') }}</option>
                  </select>
                </b-col>
              </b-row>
            </template>
          </b-card>
          <!-- *********************** BMI GFR    ********************* -->
          <template v-if="d_ruleAnalyzeData.results.list && Object.keys(d_ruleAnalyzeData.results.list).length > 0">
            <div :class="[d_ruleAnalyzeData.class]">
              <rule-engine :rule_results="d_ruleAnalyzeData.results"></rule-engine>
            </div>
          </template>
          <template v-if="option_data[data_type].bmi_gfr && option_data[data_type].bmi_gfr === 'yes'">
            <bmi-gfr-widget :patient_data="patient" :bmi_gfr_change="bmi_gfr_change" :p_showMode="d_wdmrSettings.bmi_gfr_widget.show_mode"></bmi-gfr-widget>
          </template>
          <template v-if="d_wdmrSettings.wisdom_param.settings && (d_wdmrSettings.wisdom_param.mode === 2 || d_wdmrSettings.wisdom_param.mode === 3)">
            <div style="text-align: center;">
              <b-badge pill :variant="d_wdmrSettings.wisdom_param.user_style ? 'warning' : 'primary'" @click="d_wdmrSettings.wisdom_param.user_style ? d_wdmrSettings.wisdom_param.user_style = 0 : d_wdmrSettings.wisdom_param.user_style = 1" style="cursor: pointer; text-align: center;">Wdm tasarımında {{ d_wdmrSettings.wisdom_param.user_style ? $t('wdm16.4021') : 'sistem'}} {{ $t('wdm16.13214') }}</b-badge>
            </div>
            <wdm-and-parameter-style :p_styleData="d_wdmrSettings.wisdom_param.style"></wdm-and-parameter-style>
          </template>
          <template v-if="d_wdmrSettings.settings.show">
            <b-card no-body>
              <b-tabs>
                <b-tab active>
                  <template slot="title">
                    <i class="icon-calculator"></i> Favori Parametrelerinizi seçiniz
                  </template>
                  <br>
                  <b-card-header header-bg-variant="secondary" class="p-1">
                    Favori Parametrelerinizi seçiniz
                    <b-button size="md" :title="$t('wdm16.13217')" variant="secondary" @click="f_updateFavoriteWdmParameters()" style="padding: 1px; margin-right: 5px;" class="pull-right">
                      <img src="@/icon/2087726.png" style="width: 2em;" />
                    </b-button>
                  </b-card-header>
                  <b-row style="overflow-y: auto; height: 300px; margin: 0px;">
                    <b-col sm="12">
                      <wdm-parameters :key="'d_wdmParameters.selected_index_data'" :data_type="data_type" :search_in_first_parameter_list="d_wdmParameters.search_in_first_parameter_list" :option_data="option_data" :selected_index_data="d_wdmParameters.selected_index_data" :show_parameter_features="d_wdmParameters.show_parameter_features" :d_isThisModelEditable="false" :param_change="d_wdmParameters.param_change" :selection="d_wdmParameters.selection"></wdm-parameters>
                    </b-col>
                  </b-row>
                </b-tab>
                <b-tab>
                  <template slot="title">
                    <i class="icon-basket-loaded"></i> Doldurulduğunda Başlığı Görünmeyecek Parametrelerinizi Seçin
                  </template>
                  <br>
                  <b-card-header header-bg-variant="secondary" class="p-1">
                    Doldurulduğunda Başlığı Görünmeyecek Parametrelerinizi seçiniz
                    <b-button size="md" :title="$t('wdm16.13220')" variant="secondary" @click="f_updateDontShowCaptionWdmParameters()" style="padding: 1px; margin-right: 5px;" class="pull-right">
                      <img src="@/icon/2087726.png" style="width: 2em;" />
                    </b-button>
                  </b-card-header>
                  <b-row style="overflow-y: auto; height: 300px; margin: 0px;">
                    <b-col sm="12">
                      <wdm-parameters :key="'d_wdmParameters.dont_show_caption_index_data'" :data_type="data_type" :search_in_first_parameter_list="d_wdmParameters.search_in_first_parameter_list" :option_data="option_data" :selected_index_data="d_wdmParameters.dont_show_caption_index_data" :show_parameter_features="d_wdmParameters.show_parameter_features" :d_isThisModelEditable="false" :param_change="d_wdmParameters.param_change" :selection="d_wdmParameters.selection"></wdm-parameters>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-card>
          </template>
          <template v-else-if="d_wdmrSettings.wdm_designer.show">
            <wdm-designer :option_data="option_data" :data_type="data_type" :p_aiRobotMatchParameterResult="d_aiRobotMatchParameterResult" :d_showModal="d_showModal" :d_patientSearchModalData="d_patientSearchModalData" :test_mode="test_mode" :p_wdmrSettings="d_wdmrSettings" :external_patient_data="external_patient_data" :anatomy="{}" :data="data" :param_change="param_change" :stage_data="stage_data" :wdm_version_wisdomera="wdm_version_wisdomera" :p_wdmrData="data.data"></wdm-designer>
          </template>
          <template v-else>
            <template v-if="mother_children.d_showWdmrData">
              <Wdmr :p_aiRobotMatchParameterResult="d_aiRobotMatchParameterResult" :d_showWdmrOldDataRegion="d_showWdmrOldDataRegion" :d_showModal="d_showModal" :d_patientSearchModalData="d_patientSearchModalData" :p_schemaEdit="p_schemaEdit" :data="data" :test_mode="test_mode" :external_patient_data="external_patient_data" :data_type="data_type" :rule_engine="rule_engine" :query_list_type="query_list_type" :polestar="polestar" :query_list="query_list" :column_list="column_list" :param_change="param_change" :option_data="option_data" :anatomy_use="''" :anatomy="{}" :selected_anatomy_parent="{}" :stage_data="stage_data" :parameter_list="parameter_list" :use_parameter_list="use_parameter_list" :user_favorite_parameter_list="d_wdmParameters.selected_index_data.index_list" :dont_show_caption_index_data="d_wdmParameters.dont_show_caption_index_data.index_list" :mother_children="mother_children" :wdm_version_wisdomera="wdm_version_wisdomera" :p_wdmrSettings="d_wdmrSettings" :p_selectedAnatomySchemaList="p_selectedAnatomySchemaList" :p_multiWdmrIndex="p_multiWdmrIndex"></Wdmr>
            </template>
          </template>
          <template v-else>
            <div style="color: red;">{{ $t('wdm16.13221') }}</div>
          </template>
        </template>
      </b-col>
      <b-col ref="right_or_bottom" sm="12" :md="d_wdmrSettings.right_page_mode.page ? '6' : '12'" v-if="!rule_engine && !polestar">
        <div v-if="d_wdmrSettings.page_divide" :style="f_calculateRightOrBottomCss()">
          <template v-if="d_wdmrSettings.right_page_mode.page === 'document_upload'">
            <b-card class="mb-1" no-body v-if="!p_schemaEdit && option_data[data_type].document_upload && option_data[data_type].document_upload === 'yes'" header-tag="header" footer-tag="footer" :key="'document_upload'">
              <b-card-header header-bg-variant="secondary" class="p-1">
                <strong>{{ $t('wdm16.3702') }}</strong>
              </b-card-header>
              <b-row style="border-bottom: solid 1px black; margin: 0px;">
                <b-col cols="6"><strong>Döküman ekle</strong> ({{ CreateAcceptedDocumentTypes() }})</b-col>
                <b-col cols="6">
                  <b-form-file @input="f_addDocument()" v-model="d_tmpFile" :accept="CreateAcceptedDocumentTypes()" :placeholder="$t('wdm16.13222')" :drop-placeholder="$t('wdm16.13223')"></b-form-file>
                  <!-- <input id="selected_document" type="file" ref="fileinput" v-on:change="AddDocument()" :accept="CreateAcceptedDocumentTypes()"> -->
                </b-col>
              </b-row>
              <template v-if="data.document_list && show_document_list">
                <template v-for="(doc, doc_ind) in data.document_list">
                  <b-row v-if="doc.record_type !== 'deleted'" style="border-bottom: solid 1px black; margin: 0px;" :key="'document-doc-ind-' + doc_ind">
                    <b-col cols="4">
                      <b-button v-if="!doc.project" size="sm" @click="f_deleteDocument(doc_ind)" :key="'document-trash-' + doc_ind"><i class="fa fa-trash"></i></b-button>
                      {{ doc.file_name }}
                      <b-button size="sm" v-if="['jpeg', 'png', 'jpg'].indexOf(doc.fileExtension.toLowerCase()) !== -1" variant="primary" @click="PreviewImage(doc_ind, doc.file_name)">{{ $t('wdm16.6653') }}</b-button>
                    </b-col>
                    <b-col cols="2">
                      <img v-if="['jpeg', 'png', 'jpg'].indexOf(doc.fileExtension) !== -1" :src="doc.document" style="width: 50%;">
                      <a v-if="['pdf', 'docx', 'doc'].indexOf(doc.fileExtension) !== -1" :href="doc.document" download>{{ $t('wdm16.13224') }}</a>
                    </b-col>
                    <b-col cols="3">
                      <b-form-select v-model="doc.status">
                        <option value="upload">{{ $t('wdm16.13184') }}</option>
                        <option value="record">{{ $t('wdm16.13185') }}</option>
                        <option value="control">{{ $t('wdm16.13186') }}</option>
                        <option value="approve">{{ $t('wdm16.4268') }}</option>
                      </b-form-select>
                    </b-col>
                    <!-- <b-col cols="3">
                  <template v-if="!doc.project">
                    <b-form-select v-model="doc.project">
                      <option value="">Proje seçimi yapılmadı</option>
                      <option v-for="(project, project_ind) in d_dataUploadProject" :value="project">{{ project.label }}</option>
                    </b-form-select>
                  </template>
                  <template v-else>
                    {{ doc.project.label }}
                  </template>
                </b-col> -->
                  </b-row>
                </template>
              </template>
            </b-card>
            <template v-if="data.document_list && data.document_list.length > 0">
              <div>
                <b-button variant="success" @click="f_rotateImage()"><i class="fa fa-rotate-right"></i> </b-button>
                <b-button variant="success" @click="f_scaleImageBig()">+</b-button>
                <b-button variant="success" @click="f_scaleImageSmall()">-</b-button>
              </div>
              <b-card class="mb-1" no-body v-if="document_show_data.file && show_image" header-tag="header">
                <div style="overflow-x: auto; overflow-y: auto;">
                  <img :style="'transform: scale(' + d_scale.toString() + ', ' + d_scale.toString() + ') rotate(' + rotate_degree.toString() + 'deg); position: relative;'" :src="document_show_data.file">
                </div>
              </b-card>
            </template>
          </template>
          <template v-if="d_wdmrSettings.right_page_mode.page === 'wisdomdatashow' && d_wdmrSettings.right_page_mode.page">
            <b-card class="mb-1" no-body header-tag="header" footer-tag="footer">
              <b-card-header header-bg-variant="primary" class="p-1">
                <strong>{{ $t('wdm16.13228') }}</strong>
              </b-card-header>
              <template v-if="d_showWisdomDataShowTrueFalse">
                <wisdom-data-show :change="'0'" :view_mode="wisdom_data_show_view_mode" :data="data" :data_type="data_type" :option_data="option_data[data_type]"></wisdom-data-show>
              </template>
            </b-card>
          </template>
        </div>
      </b-col>
    </b-row>
    <!-- <b-modal title="Şema kayıt ekranı." centered class="modal-success" v-model="showSchemaModal" @ok="showSchemaModal = false" ok-variant="success" hide-footer>
      <b-row>
        <b-col cols="3">
          <strong style="color: #20a8d8;">şablon başlığı</strong>
        </b-col>
        <b-col cols="9">
          <b-form-textarea id="textarea" v-model="schema_caption" placeholder="Şema başlığını yazınız..." rows="3" max-rows="6"></b-form-textarea>
        </b-col>
      </b-row>
      <br>
      <b-row class="pull-right">
        <b-col cols="12">
          <b-button v-if="!edit_schema" variant="success" @click="saveWisdomDataSchema('new')">yeni şablon kaydet</b-button>
          <b-button v-if="edit_schema && selected_schema && selected_schema_data.id" variant="success" @click="saveWisdomDataSchema('edit')">şablon güncelle</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal title="Anatomi bölgesi şema kayıt ekranı." centered class="modal-success" v-model="showSchemaAnatomyModal" @ok="showSchemaAnatomyModal = false" ok-variant="success" hide-footer>
      <b-row>
        <b-col cols="3">
          <strong style="color: #20a8d8;">şablon başlığı</strong>
        </b-col>
        <b-col cols="9">
          <b-form-textarea id="textarea" v-model="schema_anatomy_caption" placeholder="Şema başlığını yazınız..." rows="3" max-rows="6"></b-form-textarea>
        </b-col>
      </b-row>
      <br>
      <b-row class="pull-right">
        <b-col cols="12">
          <b-button v-if="!edit_schema_anatomy" variant="success" @click="saveWisdomDataAnatomySchema('new')">yeni şablon kaydet</b-button>
          <b-button v-if="edit_schema_anatomy && selected_anatomy_schema && selected_schema_anatomy_data.id" variant="success" @click="saveWisdomDataAnatomySchema('edit')">şablon güncelle</b-button>
        </b-col>
      </b-row>
    </b-modal> -->
    <b-modal :title="$t('wdm16.13229')" centered class="modal-success" v-model="d_showNewWdmSchemaGroup" @ok="d_showNewWdmSchemaGroup = false" ok-variant="success" hide-footer>
      <b-row v-if="user && user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1 || user.permissions_result.indexOf('administrator') !== -1)">
        <b-col cols="3">
          <strong>{{ $t('wdm16.13230') }}</strong>
        </b-col>
        <b-col cols="9">
          <select v-model="d_newWdmSchemaGroupOwnerType" class="form-control">
            <option value="user">{{ $t('wdm16.13231') }}</option>
            <option value="hospital">{{ $t('wdm16.13232') }}</option>
            <option value="wisdom">{{ $t('wdm16.13233') }}</option>
          </select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <strong>{{ $t('wdm16.7119') }}</strong>
        </b-col>
        <b-col cols="9">
          <b-form-input type="text" class="form-control-warning" id="d_newWdmSchemaGroupTitle" v-model="d_newWdmSchemaGroupTitle"></b-form-input>
        </b-col>
      </b-row>
      <br>
      <b-row class="pull-right">
        <b-col cols="12">
          <b-button v-if="d_newWdmSchemaGroupTitle !== ''" variant="success" @click="f_saveNewWdmSchemaGroup()">{{ $t('wdm16.4061') }}</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal title="Şablon kaydetme ekranı" centered class="modal-success" v-model="d_showNewWdmSchema" @ok="d_showNewWdmSchema = false" ok-variant="success" hide-footer>
      <b-row>
        <b-col cols="3">
          <strong>{{ $t('wdm16.6772') }}</strong>
        </b-col>
        <b-col cols="9">
          <b-form-input type="text" class="form-control-warning" id="d_newWdmSchemaTitle" v-model="d_newWdmSchemaTitle"></b-form-input>
        </b-col>
      </b-row>
      <br>
      <b-row class="pull-right">
        <b-col cols="12">
          <b-button v-if="d_newWdmSchemaTitle !== ''" variant="success" @click="f_saveNewWdmSchema()">{{ $t('wdm16.4061') }}</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal :title="$t('wdm16.13229')" centered class="modal-success" v-model="d_showNewWdmAnatomySchemaGroup" @ok="d_showNewWdmAnatomySchemaGroup = false" ok-variant="success" hide-footer>
      <b-row v-if="user && user.permissions_result && (user.permissions_result.indexOf('wisdom') !== -1 || user.permissions_result.indexOf('administrator') !== -1)">
        <b-col cols="3">
          <strong>{{ $t('wdm16.13230') }}</strong>
        </b-col>
        <b-col cols="9">
          <select v-model="d_newWdmAnatomySchemaGroupOwnerType" class="form-control">
            <option value="user">{{ $t('wdm16.13231') }}</option>
            <option value="hospital">{{ $t('wdm16.13232') }}</option>
            <option value="wisdom">{{ $t('wdm16.13233') }}</option>
          </select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <strong>{{ $t('wdm16.7119') }}</strong>
        </b-col>
        <b-col cols="9">
          <b-form-input type="text" class="form-control-warning" id="d_newWdmAnatomySchemaGroupTitle" v-model="d_newWdmAnatomySchemaGroupTitle"></b-form-input>
        </b-col>
      </b-row>
      <br>
      <b-row class="pull-right">
        <b-col cols="12">
          <b-button v-if="d_newWdmAnatomySchemaGroupTitle !== ''" variant="success" @click="f_saveNewWdmAnatomySchemaGroup()">{{ $t('wdm16.4061') }}</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal title="Şablon kaydetme ekranı" centered class="modal-success" v-model="d_showNewWdmAnatomySchema" @ok="d_showNewWdmAnatomySchema = false" ok-variant="success" hide-footer>
      <b-row>
        <b-col cols="3">
          <strong>{{ $t('wdm16.6772') }}</strong>
        </b-col>
        <b-col cols="9">
          <b-form-input type="text" class="form-control-warning" id="d_newWdmAnatomySchemaTitle" v-model="d_newWdmAnatomySchemaTitle"></b-form-input>
        </b-col>
      </b-row>
      <br>
      <b-row class="pull-right">
        <b-col cols="12">
          <b-button v-if="d_newWdmAnatomySchemaTitle !== ''" variant="success" @click="f_saveNewWdmAnatomySchema()">{{ $t('wdm16.4061') }}</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_showDateModal" :title="$t('wdm16.13239')" centered class="modal-success" v-model="d_showDateModal" @ok="d_showDateModal = false" ok-variant="success" hide-footer>
      <b-row style="margin: 2px;" v-if="option_data[data_type].date_ability === 'yes' && d_showDate">
        <b-col sm="12" md="8" lg="8">
          <template v-if="data.date_type === 'datetime'">
            <datepicker :config="{ enableTime: true, minDate: option_data[data_type].date_ability_limit === 'after' ? d_dateAbilityLimit : '', maxDate: option_data[data_type].date_ability_limit === 'before' ? d_dateAbilityLimit : '', time_24hr: true}" class="form-control" v-model="data.date" @input="f_calculateEndingDateLimit"></datepicker>
          </template>
          <template v-else-if="data.date_type === 'date'">
            <b-form-input type="date" :min="option_data[data_type].date_ability_limit === 'after' ? d_dateAbilityLimit : ''" :max="option_data[data_type].date_ability_limit === 'before' ? d_dateAbilityLimit : ''" v-model="data.date" @input="f_calculateEndingDateLimit"></b-form-input>
          </template>
          <template v-else-if="data.date_type === 'yearmonth'">
            <input type="month" :min="option_data[data_type].date_ability_limit === 'after' ? d_dateAbilityLimit : '1000-01'" :max="option_data[data_type].date_ability_limit === 'before' ? d_dateAbilityLimit : '9999-12'" v-model="data.date" @input="f_calculateEndingDateLimit">
          </template>
          <template v-else-if="data.date_type === 'year'">
            <input type="number" :min="option_data[data_type].date_ability_limit === 'after' ? d_dateAbilityLimit : '1000'" :max="option_data[data_type].date_ability_limit === 'before' ? d_dateAbilityLimit : '9999'" step="1" v-model="data.date" @input="f_calculateEndingDateLimit">
          </template>
        </b-col>
        <b-col sm="12" md="3" lg="4" v-if="option_data[data_type].date_ability_user_selection === 'yes'">
          <select v-model="data.date_type" class="form-control" @input="f_changeDateType">
            <option value="year">{{ $t('wdm16.12749') }}</option>
            <option value="yearmonth">{{ $t('wdm16.12750') }}</option>
            <option value="date">{{ $t('wdm16.12751') }}</option>
            <option value="datetime">{{ $t('wdm16.12752') }}</option>
          </select>
        </b-col>
      </b-row>
    </b-modal>
    <modal v-if="d_showModal.patient_search" @close="d_showModal.patient_search = false" :width="'1500'">
      <h3 slot="header">{{ $t('wdm16.13244') }}</h3>
      <div slot="body">
        <patient-search :param_change="param_change" :p_patientListDoc="d_patientSearchModalData" :p_wdmrData="data" :p_wdmrType="data_type"></patient-search>
      </div>
      <div slot="footer">
        <b-button @click="f_saveTreeListData()" variant="success" size="sm" style="margin-right: 3px;"> <i class="fa fa-save"></i> {{ $t('wdm16.3959') }}</b-button>
        <b-button @click="d_showModal.patient_search = false" variant="danger" size="sm" style="margin-right: 3px;"> <i class="fa fa-ban"></i> {{ $t('wdm16.3748') }}</b-button>
      </div>
    </modal>
    <modal v-if="d_parameterDesignWdmEdit.show" @close="d_parameterDesignWdmEdit.show = false" :width="'1500'">
      <h3 slot="header">
        {{ d_parameterDesignWdmEdit.wdm.name.label + this.$t('wdm16.6286') + d_parameterDesignWdmEdit.wdm.version }}
      </h3>
      <div slot="body">
        <parameter-design ref="parameter_design" :p_wdmData="d_parameterDesignWdmEdit"></parameter-design>
      </div>
      <div slot="footer">
        <b-button @click="d_parameterDesignWdmEdit.show = false" variant="danger" size="sm" style="margin-right: 3px;"> <i class="fa fa-ban"></i> {{ $t('wdm16.3748') }}</b-button>
        <b-button @click="f_getChangesOfWdm()" variant="success" size="sm" style="margin-right: 3px;"> <i class="fa fa-ban"></i> {{ $t('wdm16.13245') }}</b-button>
      </div>
    </modal>
    <b-modal v-if="d_languageChange.show" v-model="d_languageChange.show" size="lg" :title="$t('wdm16.13247')" centered hide-footer body-bg-variant="secondary" header-bg-variant="secondary">
      <b-row>
        <b-col cols="4">
          Aktif Dil
        </b-col>
        <b-col cols="8">
          <b-form-select v-model="d_languageChange.active_lang">
            <option v-for="(lang, lang_ind) in d_languageChange.language_list" :value="lang.value">
              {{ lang.label }}
            </option>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row style="min-height: 50px; margin-top: 20px;">
        <b-col cols="12">
          <b-button variant="success" class="pull-right" v-if="option_data[data_type].language.value !== d_languageChange.active_lang" @click="f_updateWdmLanguage()">{{ $t('wdm16.4061') }}</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-if="d_printWdmr.show" v-model="d_printWdmr.show" size="lg" :title="data.label ? data.label : 'Veri Modeli Yazdır'" centered hide-footer body-bg-variant="white" header-bg-variant="white">
      <b-card no-body header-tag="header" footer-tag="footer" border-variant="primary" class="mb-1">
        <b-card-header header-bg-variant="primary" class="p-1">
          <strong>Yazdırma Formatı</strong>
          <i class="fa fa-print pull-right" @click="f_printScreen()" style="font-size:22px;"></i>
        </b-card-header>
        <b-row style="margin: 10px;">
          <b-col cols="12">
            <div v-html="d_printWdmr.html" ref="report_here"></div>
          </b-col>
        </b-row>
      </b-card>
      <b-card no-body header-tag="header" footer-tag="footer" border-variant="primary" class="mb-1">
        <b-card-header header-bg-variant="primary" class="p-1">
          <strong>Html Formatı</strong>
        </b-card-header>
        <b-row style="border: solid 1px #00a4b2; margin: 0px; min-height: 50px; margin-top: 20px; overflow-y: auto; overflow-x: hidden; height: 300px;">
          <b-col cols="12">
            {{ d_printWdmr.html }}
          </b-col>
        </b-row>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import {
  default as ParameterDesign
} from '@/components/widgets/ParameterDesign';
import RuleEngineService from "@/services/rule_engine";
import Datepicker from "wisdom-vue-datepicker";
import { mapGetters } from "vuex";
import moment from "moment";
import {
  default as Anatomy
} from "./Anatomy";
import vSelect from "vue-select";
import {
  default as WisdomParamCycle
} from "./WisdomParamCycle";
import {
  default as BmiGfrWidget
} from "./BmiGfrWidget";
import {
  default as RuleEngine
} from "./RuleEngine";
import { DosageCalculator } from '@/services/public/functions';
import { ClsWdmrForever } from "@/services/public/wdm";
import {
  default as WisdomDataShow
} from "./WisdomDataShow";
import {
  default as Wdmr
} from "./Wdmr";
import {
  default as PatientSearch
} from "@/components/widgets/PatientSearch";
import {
  default as WdmAndParameterStyle
} from "@/components/widgets/WdmAndParameterStyle";
import {
  default as WdmDesigner
} from "@/components/widgets/WdmDesigner";
import {
  default as WdmAiRobot
} from "@/components/widgets/WdmAiRobot";
import {
  default as WdmParameters
} from "@/components/widgets/WdmParameters";
import OptionService from "@/services/option";
import WdmService from "@/services/wdm";
import PatientService from "@/services/patient";
import store from '@/store';
import { ClsUserSettings } from '@/services/public/user';
import FileTransferService from '@/services/file_transfer';
import SchemaService from "@/services/schema";
import UserService from '@/services/users';
import GlobalService from '@/services/global';
import {
  default as Modal
} from "@/components/widgets/Modal";

export default {
  name: "WisdomDataModal",
  computed: mapGetters({
    lang: "lang",
    patient_tmp: "patient_selected",
    help: 'help'
  }),
  props: {
    p_waiTalkAlgorithm: {
      type: Boolean,
      required: false
    },
    parameter_list: {
      type: Array,
      required: false,
      default: () => []
    },
    p_wdmrSettings: {
      type: Object,
      required: false
    },
    data: {
      type: Object,
      required: true,
      default: () => {}
    },
    wdmr_change: {
      type: Object,
      required: false,
      default: () => {}
    },
    option_data: {
      type: Object,
      required: true,
      default: () => {}
    },
    data_type: {
      type: String,
      required: true
    },
    query_list_type: {
      type: String,
      required: false
    },
    rule_engine: {
      type: Boolean,
      required: false,
      default: false
    },
    polestar: {
      type: Boolean,
      required: false
    },
    query_list: {
      type: Array,
      required: false,
      default: () => []
    },
    column_list: {
      type: Array,
      required: false,
      default: () => []
    },
    external_patient_data: {
      type: Object,
      required: false,
      default: () => {}
    },
    test_mode: {
      type: Boolean,
      required: false,
      default: false
    },
    p_schemaEdit: {
      type: Object,
      required: false,
      default: () => {}
    },
    p_selectedAnatomySchemaList: {
      type: Array,
      required: false
    },
    p_multiWdmrIndex: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      d_printWdmr: {
        'show': false,
        'html': ''
      },
      d_languageChange: {
        'show': false,
        'language_list': [],
        'active_lang': ''
      },
      d_parameterDesignWdmEdit: { 'show': false, 'wdm': {} },
      d_ruleAnalyzeData: {
        'class': '',
        'last_analyzed_wdmr_data': '',
        'dmp_list': '',
        'timeout': 0,
        'results': { 'list': '' }
      },
      d_showDateModal: false,
      d_referenceWdmKey: '',
      d_aiRobotMatchParameterResult: {
        'pr': [],
        'op': '',
        'op_data': '',
        'op_acc': 0,
        'acc': 0
      },
      d_showAiRobot: false,
      d_autoNaming: false,
      d_changeWdmrCreator: false,
      d_tmpFile: null,
      d_fullScreen: false,
      d_showWdmrOldDataRegion: false,
      bmi_gfr_change: { 'status': false },
      d_showWisdomDataShowTrueFalse: true,
      wisdom_data_show_view_mode: 'table',
      mother_children: { 'd_showWdmrData': true, 'd_showAnatomyData': true, 'd_showNonAnatomyData': true },
      d_scale: 1,
      d_showModal: { 'patient_search': false },
      d_patientSearchModalData: { 'document_key': '', 'document_bucket': '' },
      show_image: true,
      rotate_degree: 0,
      rotate: false,
      document_show_data: { 'file_name': '', 'file': '' },
      use_parameter_list: true,
      patient: {},
      user: {},
      d_userListWithDepartments: [],
      d_dataUploadProject: [],
      show_document_list: true,
      store: store,
      showSchemaAnatomyModal: false,
      showSchemaModal: false,
      edit_schema: false,
      edit_schema_anatomy: false,
      selected_anatomy_parent: {},
      param_change: {
        'wdmr_data_update': 0,
        'type': '',
        'param': '',
        'id': 0,
        'staging': 0,
        'toxicity': 0,
        'favorite_show_mode': 0,
        'bmi': 0,
        'drug_from_chemotherapy': 0,
        'wdm3_group_version': 0,
        'rule': 0,
        'dosage_calculate': 0,
        'forceUpdate': 0
      },
      anatomy_use: "any",
      selected_schema_data: {},
      selected_schema_anatomy_data: {},
      schema_scope: "public",
      schema_caption: "",
      schema_anatomy_caption: "",
      selected_schema: "",
      selected_anatomy_schema: "",
      schema_list: [],
      schema_anatomy_list: [],
      show_staging_group_selectable: true,
      stage_data: {},
      select_types: ["select", "object_list"],
      selected_anatomy_region: {},
      selected_old_test: "",
      selected_old_anatomy_test: "",
      old_tests: [],
      old_anatomy_tests: [],
      d_userProjects: [],
      favorite_show_mode: false,
      d_dateAbilityLimit: '',
      d_endingDateAbilityLimit: '',
      d_dateAndEndingDatelimit: '',
      d_showEndingDate: true,
      d_showDate: true,
      d_selectedWdmSchemaType: 'wisdom',
      d_wdmSchemaGroupList: [],
      d_wisdomWdmSchemaGroupList: [],
      d_userWdmSchemaGroupList: [],
      d_hospitalWdmSchemaGroupList: [],
      d_selectedWdmSchemaGroup: {},
      d_selectedWdmSchemaGroupType: 'all',
      d_selectedWdmSchema: {},
      d_wdmSchemaList: [],
      d_showNewWdmSchemaGroup: false,
      d_newWdmSchemaGroupTitle: '',
      d_newWdmSchemaGroupOwnerType: 'user',
      d_newWdmSchemaTitle: '',
      d_showNewWdmSchema: false,
      d_rightDividedWidth: 0,
      d_rightDividedHeight: 0,
      d_selectedWdmAnatomySchemaType: 'wisdom',
      d_wdmAnatomySchemaGroupList: [],
      d_wisdomWdmAnatomySchemaGroupList: [],
      d_userWdmAnatomySchemaGroupList: [],
      d_hospitalWdmAnatomySchemaGroupList: [],
      d_selectedWdmAnatomySchemaGroup: {},
      d_selectedWdmAnatomySchemaGroupType: 'all',
      d_selectedWdmAnatomySchema: {},
      d_wdmAnatomySchemaList: [],
      d_showNewWdmAnatomySchemaGroup: false,
      d_newWdmAnatomySchemaGroupTitle: '',
      d_newWdmAnatomySchemaGroupOwnerType: 'user',
      d_newWdmAnatomySchemaTitle: '',
      d_showNewWdmAnatomySchema: false,
      d_editSchemaGroupList: [],
      d_wdmAiWdmList: [],
      d_allUserList: [],
      wdm_version_wisdomera: {},
      d_wdmrSettings: {
        'wisdom_param': {
          'settings': 0,
          'mode': 1, // 1 normal, 2 label value mode up down parameter, 3 label value left to right,
          'user_style': 0,
          'style': {
            'caption': {
              'fontSize': 12,
              'color': 'black',
              'after': '',
              'backgroundColor': 'white',
              'border': {
                'type': 'solid',
                'size': 0,
                'color': 'black'
              },
              'borderRadius': 0
            },
            'value': {
              'fontSize': 10,
              'color': 'black',
              'after': '',
              'backgroundColor': 'white',
              'border': {
                'type': 'solid',
                'size': 0,
                'color': 'black'
              },
              'borderRadius': 0
            }
          }
        },
        'wdm_designer': {
          'show': 0,
          'settings': 0,
          'table': []
        },
        'right_page_mode': {
          'page': ''
        },
        'column_settings': {
          'cols': 1
        },
        'wdmr_schema': {
          'wdm_anatomy': {
            'show': 0,
            'view_mode': 'basic'
          },
          'wdm': {
            'show': 0,
            'view_mode': 'basic'
          }
        },
        'bmi_gfr_widget': {
          'show_mode': 'left_right'
        },
        'page_divide': 1,
        'settings': {
          'show': 0
        },
        'anatomy_component': {
          'mode': 2,
        },
        'anatomy_group': {
          'cols': 1
        },
        'favorite_show_mode': 0,
        'group': {
          'show': 1,
          'mode': 2
        },
        'date': {
          'show_as_row': 0
        },
        'wdmr_header_mode': 3, // 1 or 2 or 3 or 4
        'document_upload': {
          'show': 0
        }
      },
      d_wdmParameters: {
        'selection': {
          'times': 'multi', // one, multi
          'type': 'all', // all, this_index, this_types
          'this_index': []
        },
        'brief_list': {},
        'selected_wdm': '',
        'user_selected_brief_wdm_list': [],
        'option_data': {},
        'data_type': '',
        'search_in_first_parameter_list': '',
        'selected_index_data': { 'index': '', 'index_list': [] },
        'dont_show_caption_index_data': { 'index': '', 'index_list': [] },
        'show_parameter_features': '',
        'param_change': { 'update': 0, 'new_parameter_added': 0 }
      }
    };
  },
  created: function () {
    this.d_referenceWdmKey = 'wdm_' + this.option_data[this.data_type].owner_type + '_' + this.data_type + '_v' + this.option_data[this.data_type].version;
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.option_data[this.data_type].owner_type === 'hospital' && this.user) {
      this.d_referenceWdmKey = 'wdm_' + this.option_data[this.data_type].owner_type + '_' + this.data_type + '_' + this.user.hospital_group + '_' + this.user.hospital_id + '_v' + this.option_data[this.data_type].version;
    }
    if (this.p_wdmrSettings !== undefined) {
      this.d_wdmrSettings = this.p_wdmrSettings;
    } else if (this.user && this.user.settings && this.user.settings.wdmr_record && this.user.settings.wdmr_record.settings.wdm_general && this.user.settings.wdmr_record.settings.wdm_general.settings[this.d_referenceWdmKey]) {
      this.d_wdmrSettings = JSON.parse(JSON.stringify(this.user.settings.wdmr_record.settings.wdm_general.settings[this.d_referenceWdmKey]));
    }
    try {
      let analyze_in_wdmr_edit = JSON.parse(localStorage.getItem('analyze_in_wdmr_edit'));
      if (analyze_in_wdmr_edit) {
        for (let dmp_key in analyze_in_wdmr_edit) {
          for (let rule_key in analyze_in_wdmr_edit[dmp_key].rule_list) {
            if (analyze_in_wdmr_edit[dmp_key].rule_list[rule_key]['wdm_list'][this.data_type] && analyze_in_wdmr_edit[dmp_key].wdm_key_list.indexOf(this.d_referenceWdmKey) !== -1) {
              if (this.d_ruleAnalyzeData.dmp_list === '') {
                this.d_ruleAnalyzeData.dmp_list = {};
              }
              this.d_ruleAnalyzeData.dmp_list[dmp_key] = analyze_in_wdmr_edit[dmp_key];
              this.d_ruleAnalyzeData.dmp_list[dmp_key].rule_type = 'selected';
            }
          }
        }
      }
    } catch (err) {}
    if (!this.rule_engine && !this.polestar) {
      if (this.data.date && this.data.date !== '') {
        if (this.data.date_type === 'datetime') {
          if (this.data.date.indexOf('T') !== -1) {
            this.data.date = this.data.date.split('T')[0] + ' ' + this.data.date.split('T')[1].split(':')[0] + ':' + this.data.date.split('T')[1].split(':')[1]
          } else if (this.data.date.indexOf(' ') !== -1) {
            this.data.date = this.data.date.split(' ')[0] + ' ' + this.data.date.split(' ')[1].split(':')[0] + ':' + this.data.date.split(' ')[1].split(':')[1]
          }
        } else if (this.data.date_type === 'date') {
          if (this.data.date.indexOf('T') !== -1) {
            this.data.date = this.data.date.split('T')[0]
          } else if (this.data.date.indexOf(' ') !== -1) {
            this.data.date = this.data.date.split(' ')[0]
          }
        } else if (this.data.date_type === 'yearmonth') {
          if (this.data.date.indexOf('T') !== -1) {
            this.data.date = this.data.date.split('T')[0].split('-')[0] + '-' + this.data.date.split('T')[0].split('-')[1]
          } else if (this.data.date.indexOf(' ') !== -1) {
            this.data.date = this.data.date.split(' ')[0].split('-')[0] + '-' + this.data.date.split(' ')[0].split('-')[1]
          }
        } else if (this.data.date_type === 'year') {
          if (this.data.date.indexOf('T') !== -1) {
            this.data.date = this.data.date.split('T')[0].split('-')[0]
          } else if (this.data.date.indexOf(' ') !== -1) {
            this.data.date = this.data.date.split(' ')[0].split('-')[0]
          }
        }
      }
      if (this.data.ending_date && this.data.ending_date !== '') {
        if (this.data.ending_date_type === 'datetime') {
          if (this.data.date.indexOf('T') !== -1) {
            this.data.ending_date = this.data.ending_date.split('T')[0] + ' ' + this.data.ending_date.split('T')[1].split(':')[0] + ':' + this.data.ending_date.split('T')[1].split(':')[1]
          } else if (this.data.date.indexOf(' ') !== -1) {
            this.data.ending_date = this.data.ending_date.split(' ')[0] + ' ' + this.data.ending_date.split(' ')[1].split(':')[0] + ':' + this.data.ending_date.split(' ')[1].split(':')[1]
          }
        } else if (this.data.ending_date_type === 'date') {
          if (this.data.date.indexOf('T') !== -1) {
            this.data.ending_date = this.data.ending_date.split('T')[0]
          } else if (this.data.date.indexOf(' ') !== -1) {
            this.data.ending_date = this.data.ending_date.split(' ')[0]
          }
        } else if (this.data.ending_date_type === 'yearmonth') {
          if (this.data.date.indexOf('T') !== -1) {
            this.data.ending_date = this.data.ending_date.split('T')[0].split('-')[0] + '-' + this.data.ending_date.split('T')[0].split('-')[1]
          } else if (this.data.date.indexOf(' ') !== -1) {
            this.data.ending_date = this.data.ending_date.split(' ')[0].split('-')[0] + '-' + this.data.ending_date.split(' ')[0].split('-')[1]
          }
        } else if (this.data.ending_date_type === 'year') {
          if (this.data.date.indexOf('T') !== -1) {
            this.data.ending_date = this.data.ending_date.split('T')[0].split('-')[0]
          } else if (this.data.date.indexOf(' ') !== -1) {
            this.data.ending_date = this.data.ending_date.split(' ')[0].split('-')[0]
          }
        }
      }
      this.f_calculateDateData();
      // this.f_changeDateType();
      // this.f_changeEndingDateType();
      let reference_wdm = {
        'username': this.user ? this.user.username : '',
        'data_type': this.data_type,
        'owner_type': this.option_data[this.data_type].owner_type,
        'version': this.option_data[this.data_type].version
      };
      this.d_wdmAiWdmList.push({ 'reference_wdm': reference_wdm, 'wdm_data': '', 'wdmr_data': '' });
      this.d_wdmAiWdmList[0].wdmr_data = this.data;
    } else {
      this.f_createWdmSettings();
    }

    // let d_userProjects = JSON.parse(localStorage.getItem('user_projects'));
    // for (let i in d_userProjects) {
    //   if (d_userProjects[i].data.members && d_userProjects[i].data.members.length > 0) {
    //     for (let m in d_userProjects[i].data.members) {
    //       if (this.user.username === d_userProjects[i].data.members[m].username && d_userProjects[i].data.members[m].data_models && d_userProjects[i].data.members[m].data_models[this.data_type] && d_userProjects[i].data.members[m].data_models[this.data_type].permissions.indexOf('upload') !== -1) {
    //         // kullanıcının data upload yetkisi olduğu projelerin listesini hazırladık.
    //         this.d_dataUploadProject.push({ 'label': d_userProjects[i].name, 'value': d_userProjects[i].id });
    //       }
    //     }
    //   }
    // }
    if (this.external_patient_data) {
      this.patient = JSON.parse(JSON.stringify(this.external_patient_data))
    } else {
      this.patient = JSON.parse(JSON.stringify(this.patient_tmp))
    }
    this.f_updateUserFavoriteShowModeSettings();
    this.f_updateUserDontShowCaptionSettings();
    if (this.d_wdmParameters.selected_index_data.index_list.length === 0) {
      this.d_wdmrSettings.favorite_show_mode = 0;
    }
    for (let i in this.option_data[this.data_type].param_group.parameters) {
      if (!this.data.data[i]) {
        this.data.data[i] = {};
      }
    }
    this.f_beforeShowingWdmr();
  },
  mounted () {
    // if (this.option_data[this.data_type].schema && this.option_data[this.data_type].schema === 'yes' && !this.rule_engine && !this.polestar) {
    //   this.f_getWdmAndAnatomySchemaGroups();
    // }

    // this.f_getWisdomAndUserWdmAnatomySchemaGroups();
    // if (!this.rule_engine && !this.polestar) {
    //   this.data.patient_id = this.patient.id;
    //   let schemas = JSON.parse(localStorage.getItem('wisdomDataSchemas'));
    //   if (schemas && schemas.wisdom_data_anatomy_schema && schemas.wisdom_data_anatomy_schema[this.data_type]) {
    //     this.schema_anatomy_list = schemas.wisdom_data_anatomy_schema[this.data_type].list;
    //   }
    //   if (schemas && schemas.wisdom_data_schema && schemas.wisdom_data_schema[this.data_type]) {
    //     this.schema_list = schemas.wisdom_data_schema[this.data_type].list;
    //   }
    //   // this.get_wisdom_data_schema_list();
    //   // this.get_wisdom_data_anatomy_schema_list();
    //   if (this.patient && this.patient.id) {
    //     this.get_tests();
    //   }
    // }
    if (this.data['data'].general && this.data['data'].general.staging_group && (!this.data['data'].general.stage || (this.data['data'].general.stage && this.data['data'].general.stage.val === ''))) {
      this.get_staging_details();
    }
    if (this.data_type === 'toxicity_model') {
      this.get_toxicity_details();
    }
    this.f_getWidthFromId();
    if (this.data.document_list && this.data.document_list.length > 0) {
      this.d_wdmrSettings.document_upload.show = true;
    }
    // kullanıcının favori parametre gösterimi var mı ve bu data modeli favori gösterim modunda var mı bakıyoruz. Önceliğimiz bu favorilerin gösterimi
  },
  methods: {
    f_printScreen: function () {
      let x = new Date();
      let now_date = moment(x.toString()).format('DD/MM/YYYY HH:mm');
      let w = window.open('', '_blank', 'height=500,width=800,toolbar=0,location=0,menubar=0');
      let pageHtml = htmlTemplate
        .replace('##HEADER##', 'Rapor')
        .replace('##BODY##', this.$refs.report_here.innerHTML)
        .replace('##FOOTER##', 'Çıktıyı Alan: ' + this.user.first_name + ' ' + this.user.last_name + '<br />Çıktı Tarihi: ' + now_date + '<br />Bu protokol sağlıkta yapay zeka sistemi WisdomEra (www.wisdomera.io) ile hazırlanmıştır.');
      w.document.write(pageHtml);
      w.document.close();
      w.onload = function () {
        w.focus();
        w.print();
      };
    },
    f_printWdmr: function () {
      let return_data = ClsWdmrForever.turn(this.data, this.option_data[this.data_type], ['name', 'delete']);
      let data = {
        'wdmr': return_data.wdmr,
        'wdm': this.option_data[this.data_type]
      };
      WdmService.print_wdmr(data)
        .then(resp => {
          // console.log(resp.data.result);
          if (resp.data.status === 'success') {
            this.d_printWdmr.show = true;
            this.d_printWdmr.html = resp.data.result.wdmr.print.html;
          } else {
            alert(resp.data.message);
          }
        });
    },
    f_showLanguageChange: function () {
      this.d_languageChange.active_lang = this.option_data[this.data_type].language.value;
      this.d_languageChange.show = true;
      this.d_languageChange.language_list = []
      if (localStorage.getItem('wisdomera_system')) {
        this.d_languageChange.language_list = JSON.parse(localStorage.getItem('wisdomera_system')).languages;
      }
    },
    f_updateWdmLanguage: function () {
      let data = {
        'wdm_list': [{
          'value': this.d_referenceWdmKey,
          'bucket': this.option_data[this.data_type].owner_type === 'wisdom' ? 'option' : 'hospital'
        }],
        'target_lang': this.d_languageChange.active_lang
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': this.$t('wdm16.12708') } });
      WdmService.get_wdm_list(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.option_data[this.data_type] = resp.data.result.wdm_list[0];
            this.d_languageChange.show = false;
            this.f_showWdmrTrueFalse();
          } else {
            console.log('errror ', resp.data.message);
          }
        });
    },
    f_showWdmrTrueFalse: function () {
      this.mother_children.d_showWdmrData = false;
      setTimeout(function () {
        this.mother_children.d_showWdmrData = true;
        this.$forceUpdate();
      }.bind(this), 500)
    },
    f_getChangesOfWdm: function () {
      this.$refs.parameter_design.f_saveWisdomDataModel();
      this.option_data[this.data_type] = this.d_parameterDesignWdmEdit.wdm;
      this.d_parameterDesignWdmEdit.show = false;
      this.f_showWdmrTrueFalse();
    },
    f_canEditThisWdm: function () {
      if (this.option_data[this.data_type].version === 'draft') {
        let wdm_version = JSON.parse(localStorage.getItem('wdm_version'));
        let owner_type = this.option_data[this.data_type].owner_type;
        if (wdm_version[owner_type].all[this.data_type] && wdm_version[owner_type].all[this.data_type].permission.indexOf('edit_data_model') !== -1) {
          return true;
        }
      }
      return false;
    },
    f_editWdm: function () {
      this.d_parameterDesignWdmEdit.wdm = JSON.parse(JSON.stringify(this.option_data[this.data_type]));
      this.d_parameterDesignWdmEdit.show = true;
    },
    f_changeAnatomyComponentMode: function () {
      if (this.d_wdmrSettings.anatomy_component.mode === 1) {
        this.d_wdmrSettings.anatomy_component.mode = 2;
      } else if (this.d_wdmrSettings.anatomy_component.mode === 2) {
        this.d_wdmrSettings.anatomy_component.mode = 3;
      } else if (this.d_wdmrSettings.anatomy_component.mode === 3) {
        this.d_wdmrSettings.anatomy_component.mode = 1;
      }
      this.$forceUpdate();
    },
    f_changeWisdomParamInputMode: function () {
      if (this.d_wdmrSettings.wisdom_param.mode === 1) {
        this.d_wdmrSettings.wisdom_param.mode = 2;
      } else if (this.d_wdmrSettings.wisdom_param.mode === 2) {
        this.d_wdmrSettings.wisdom_param.mode = 3;
      } else if (this.d_wdmrSettings.wisdom_param.mode === 3) {
        this.d_wdmrSettings.wisdom_param.mode = 1;
      }
      this.$forceUpdate();
    },
    f_updateWdmSettings: function (type) {
      let user_data = JSON.parse(localStorage.getItem('user'));
      if (type === 'delete') {
        try {
          this.$delete(user_data.settings.wdmr_record.settings.wdm_general.settings, this.d_referenceWdmKey);
        } catch (err) {}
      } else if (type === 'add') {
        ClsUserSettings.free_structure(user_data);
        user_data.settings.wdmr_record.settings.wdm_general.settings[this.d_referenceWdmKey] = JSON.parse(JSON.stringify(this.d_wdmrSettings));
      }
      let data = {
        'wdmr_settings': this.d_wdmrSettings,
        'reference_wdm_key': this.d_referenceWdmKey,
        'type': type
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': this.$t('wdm16.12709') } });
      UserService.update_wdm_general_settings(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            alert(this.$t('wdm16.12713'));
            localStorage.setItem('user', JSON.stringify(user_data));
            this.user = user_data;
            this.$forceUpdate();
          } else {
            alert(this.$t('wdm16.12714'), resp.data.message);
          }
        });
      // localStorage.user = JSON.stringify(user_data);
    },
    f_changeGroupMode: function () {
      if (this.d_wdmrSettings.group.mode === 1) {
        this.d_wdmrSettings.group.mode = 2;
      } else if (this.d_wdmrSettings.group.mode === 2) {
        this.d_wdmrSettings.group.mode = 3;
      } else if (this.d_wdmrSettings.group.mode === 3) {
        this.d_wdmrSettings.group.mode = 1;
      }
      this.f_paramChange();
      this.$forceUpdate();
    },
    f_groupColumnDivide: function () {
      if (this.d_wdmrSettings.column_settings.cols === 1) {
        this.d_wdmrSettings.column_settings.cols = 2;
      } else if (this.d_wdmrSettings.column_settings.cols === 2) {
        this.d_wdmrSettings.column_settings.cols = 3;
      } else if (this.d_wdmrSettings.column_settings.cols === 3) {
        this.d_wdmrSettings.column_settings.cols = 4;
      } else if (this.d_wdmrSettings.column_settings.cols === 4) {
        this.d_wdmrSettings.column_settings.cols = 6;
      } else if (this.d_wdmrSettings.column_settings.cols === 6) {
        this.d_wdmrSettings.column_settings.cols = 12;
      } else if (this.d_wdmrSettings.column_settings.cols === 12) {
        this.d_wdmrSettings.column_settings.cols = 1;
      }
      this.$forceUpdate();
    },
    f_anatomyGroupColumnDivide: function () {
      if (this.d_wdmrSettings.anatomy_group.cols === 1) {
        this.d_wdmrSettings.anatomy_group.cols = 2;
      } else if (this.d_wdmrSettings.anatomy_group.cols === 2) {
        this.d_wdmrSettings.anatomy_group.cols = 3;
      } else if (this.d_wdmrSettings.anatomy_group.cols === 3) {
        this.d_wdmrSettings.anatomy_group.cols = 4;
      } else if (this.d_wdmrSettings.anatomy_group.cols === 4) {
        this.d_wdmrSettings.anatomy_group.cols = 6;
      } else if (this.d_wdmrSettings.anatomy_group.cols === 6) {
        this.d_wdmrSettings.anatomy_group.cols = 12;
      } else if (this.d_wdmrSettings.anatomy_group.cols === 12) {
        this.d_wdmrSettings.anatomy_group.cols = 1;
      }
      this.$forceUpdate();
    },
    f_updateFavoriteWdmParameters: function () {
      // When this.d_wdmParameters.selected_index_data.index_list is free, we clear the favorite list. Then the user does not have favorite list for this wmd.
      // It means that user does not want to use favorite mod of this wdm.
      let user_data = JSON.parse(localStorage.getItem('user'));
      // IF A USER CAN USE THIS WDM, IT MEANS THAT a data variable comes from props. Then it has this.data.reference_wdm and this.data.reference_wdm.key
      if (this.d_wdmParameters.selected_index_data.index_list.length > 0) {
        ClsUserSettings.free_structure(user_data);
        if (!user_data.settings.wdmr_record.settings.favorite_show_mode.settings[this.data.reference_wdm.key]) {
          user_data.settings.wdmr_record.settings.favorite_show_mode.settings[this.data.reference_wdm.key] = {
            'name': this.option_data[this.data_type]['name'],
            'index_list': []
          }
        }
        user_data.settings.wdmr_record.settings.favorite_show_mode.settings[this.data.reference_wdm.key]['index_list'] = this.d_wdmParameters.selected_index_data.index_list;
      } else {
        try {
          this.$delete(user_data.settings.wdmr_record.settings.favorite_show_mode.settings, this.data.reference_wdm.key);
        } catch (err) {}
      }
      let data = {
        'reference_wdm_key': this.d_referenceWdmKey,
        'index_list': this.d_wdmParameters.selected_index_data.index_list
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': this.$t('wdm16.12712') } });
      UserService.update_favorite_wdm_parameters(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            alert(this.$t('wdm16.12713'));
            localStorage.setItem('user', JSON.stringify(user_data));
            this.user = user_data;
          } else {
            alert(this.$t('wdm16.12714'), resp.data.message);
          }
        });
      // localStorage.user = JSON.stringify(user_data);
    },
    f_updateDontShowCaptionWdmParameters: function () {
      // When this.d_wdmParameters.selected_index_data.index_list is free, we clear the favorite list. Then the user does not have favorite list for this wmd.
      // It means that user does not want to use favorite mod of this wdm.
      let user_data = JSON.parse(localStorage.getItem('user'));
      // IF A USER CAN USE THIS WDM, IT MEANS THAT a data variable comes from props. Then it has this.data.reference_wdm and this.data.reference_wdm.key
      if (this.d_wdmParameters.dont_show_caption_index_data.index_list.length > 0) {
        ClsUserSettings.free_structure(user_data);
        if (!user_data.settings.wdmr_record.settings.dont_show_caption_mode.settings[this.data.reference_wdm.key]) {
          user_data.settings.wdmr_record.settings.dont_show_caption_mode.settings[this.data.reference_wdm.key] = {
            'name': this.option_data[this.data_type]['name'],
            'index_list': []
          }
        }
        user_data.settings.wdmr_record.settings.dont_show_caption_mode.settings[this.data.reference_wdm.key]['index_list'] = this.d_wdmParameters.selected_index_data.index_list;
      } else {
        try {
          this.$delete(user_data.settings.wdmr_record.settings.dont_show_caption_mode.settings, this.data.reference_wdm.key);
        } catch (err) {}
      }
      let data = {
        'reference_wdm_key': this.d_referenceWdmKey,
        'index_list': this.d_wdmParameters.dont_show_caption_index_data.index_list
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': this.$t('wdm16.12715') } });
      UserService.update_dont_show_caption_wdm_parameters(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            alert(this.$t('wdm16.12713'));
            localStorage.setItem('user', JSON.stringify(user_data));
            this.user = user_data;
          } else {
            alert(this.$t('wdm16.12714'), resp.data.message);
          }
        });
      // localStorage.user = JSON.stringify(user_data);
    },
    f_changeWdmrSettingsHeaderMode: function () {
      if (this.d_wdmrSettings.wdmr_header_mode === 3) {
        this.d_wdmrSettings.date.show_as_row = 1;
        this.d_wdmrSettings.wdmr_header_mode = 4;
      } else if (this.d_wdmrSettings.wdmr_header_mode === 4) {
        this.d_wdmrSettings.date.show_as_row = 0;
        this.d_wdmrSettings.wdmr_header_mode = 3;
      }
      this.$forceUpdate();
    },
    f_beforeShowingWdmr: function () {
      if (['wdm3', 'rule', 'wdm24'].indexOf(this.data_type) !== -1) {
        this.mother_children.d_showWdmrData = false;
        GlobalService.get_one_cb_document({ 'key': 'wdm_version_wisdomera', 'bucket': 'option' })
          .then(resp => {
            if (resp.data.status === 'success') {
              this.mother_children.d_showWdmrData = true;
              this.wdm_version_wisdomera = resp.data.result;
            } else {
              console.log('errror ', resp.data.message);
            }
          });
      }
    },
    f_createWdmSettings: function () {
      if (this.rule_engine || this.polestar) {
        if (!this.option_data[this.data_type].wdm_settings) {
          this.f_getAllUsersList();
          this.option_data[this.data_type].wdm_settings = {
            'parameters': {
              'created_at': {
                'type': 'datetime',
                'name': { 'label': this.$t('wdm16.12718'), 'value': 'created_at' },
                'show': 'yes'
              },
              'created_by': {
                'type': 'select',
                'name': { 'label': this.$t('wdm16.12719'), 'value': 'created_by' },
                'options': this.d_allUserList,
                'show': 'yes'
              },
              'real_created_by': {
                'type': 'select',
                'name': { 'label': this.$t('wdm16.12720'), 'value': 'real_created_by' },
                'options': this.d_allUserList,
                'show': 'yes'
              },
            },
            'param_order': ['created_by', 'real_created_by', 'created_at']
          };
          if (this.option_data[this.data_type].date_ability && this.option_data[this.data_type].date_ability === 'yes') {
            this.option_data[this.data_type].wdm_settings.parameters['date'] = {
              'type': 'date',
              'name': { 'label': this.$t('wdm16.1289'), 'value': 'date' },
              'show': 'yes'
            };
            this.option_data[this.data_type].wdm_settings.param_order.splice(0, 0, 'date');
          }
          this.option_data[this.data_type].param_group.parameters['wdm_settings'] = { 'value': 'wdm_settings', 'label': this.$t('wdm16.12721') };
          this.option_data[this.data_type].param_group.param_order.push('wdm_settings');
          this.data.data.wdm_settings = {};
          this.data.data.wdm_settings.created_at = { 'val': '' };
          this.data.data.wdm_settings.created_by = { 'val': '' };
          this.data.data.wdm_settings.real_created_by = { 'val': '' };
        }
      }
    },
    f_getAllUsersList: function () {
      let allusers = JSON.parse(localStorage.getItem('all_users'));
      for (let i in allusers) {
        this.d_allUserList.push({ 'label': allusers[i]['first_name'] + ' ' + allusers[i]['last_name'], 'value': allusers[i]['username'] });
      }
    },
    f_changeWdmrCreator: function () {
      if (this.d_changeWdmrCreator) {
        this.d_changeWdmrCreator = false;
        if (this.data.d_changeWdmrCreator) {
          this.$delete(this.data, 'd_changeWdmrCreator');
        }
      } else {
        if (this.d_userListWithDepartments.length === 0) {
          let query = "is_active=1&only_my_hospital=1"
          this.$store.commit('Loading', { 'status': true, 'data': { 'label': this.$t('wdm16.12722') } });
          UserService.get_all_users_with_departments(query)
            .then(resp => {
              if (resp.data.status === 'success') {
                this.d_userListWithDepartments = resp.data.result;
              } else {
                console.log(resp.data.message);
              }
              this.data.d_changeWdmrCreator = {};
              this.d_changeWdmrCreator = true;
              this.$store.commit('Loading', { 'status': false, 'data': {} });
            });
        } else {
          this.data.d_changeWdmrCreator = {};
          this.d_changeWdmrCreator = true;
        }
      }
    },
    f_editSaveSchema: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': this.$t('wdm16.12724') } });
      let data = {};
      data.schema_owner = this.p_schemaEdit.schema_owner;
      if (this.p_schemaEdit.schema_owner_type === 'hospital') {
        data.key_info = this.option_data[this.data_type].owner_type + '_' + this.data_type + '_' + this.p_schemaEdit.schema_owner;
      } else {
        data.key_info = this.option_data[this.data_type].owner_type + '_' + this.data_type;
      }
      data.schema_owner_type = this.p_schemaEdit.schema_owner_type;
      data.schema_cb_key = this.p_schemaEdit.value;
      data.schema_name = this.p_schemaEdit.label;
      if (this.p_schemaEdit.wdm_or_anatomy === 'wdm_anatomy') {
        data.schema_data = this.f_calculateSchemaData().anatomy;
      } else {
        data.schema_data = this.f_calculateSchemaData();
      }
      data.schema_group = this.p_schemaEdit.new_schema_group.value;
      data.old_schema_group = this.p_schemaEdit.old_schema_group.value;
      data.wdm_version = this.option_data[this.data_type].version;
      data.wdm_or_anatomy = this.p_schemaEdit.wdm_or_anatomy;
      SchemaService.edit_schema(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          if (this.p_schemaEdit.saved) {
            this.p_schemaEdit.saved = 0;
          } else {
            this.p_schemaEdit.saved = 1;
          }
        });
    },
    f_calculateRightOrBottomCss: function () {
      if (this.d_wdmrSettings.right_page_mode.page) {
        return 'width: ' + this.d_rightDividedWidth + 'px !important; height: 70vh; overflow-x: auto; overflow-y: auto; position: fixed;';
      } else {
        return '';
      }
    },
    f_rightPageMode: function () {
      if (this.d_wdmrSettings.right_page_mode.page === '') {
        this.d_wdmrSettings.right_page_mode.page = 'document_upload';
      } else {
        this.d_wdmrSettings.right_page_mode.page = '';
      }
      setTimeout(function () {
        this.f_getWidthFromId()
      }.bind(this), 1000)
    },
    f_getWidthFromId: function () {
      if (this.$refs.right_or_bottom) {
        this.d_rightDividedWidth = parseInt(this.$refs.right_or_bottom.clientWidth) - 20;
        this.d_rightDividedHeight = parseInt(this.$refs.right_or_bottom.clientHeight) - 20;
      }
    },
    f_addNewWdmSchema: function () {
      this.d_newWdmSchemaTitle = '';
      this.d_showNewWdmSchema = true;
    },
    f_checkNewWdmSchemaGroupAdd: function () {
      // this func return for disabled status
      if (this.d_selectedWdmSchemaType === 'user') {
        return false;
      } else if (this.user.permissions_result && (this.user.permissions_result.indexOf('wisdom') !== -1 || this.user.permissions_result.indexOf('administrator') !== -1 || this.user.permissions_result.indexOf('general_open-test-model') !== -1) && this.d_selectedWdmSchemaType === 'wisdom') {
        return false;
      } else if (this.user.permissions_result && (this.user.permissions_result.indexOf('wisdom') !== -1 || this.user.permissions_result.indexOf('SAMISAMISAMI') !== -1) && this.d_selectedWdmSchemaType === 'hospital') {
        return false;
      }
      return true;
    },
    f_checkNewWdmSchemaAdd: function () {
      // this func return for disabled status
      if (this.d_selectedWdmSchemaType === 'user' && this.d_selectedWdmSchemaGroupType === 'selected' && this.d_selectedWdmSchemaGroup.value) {
        return false;
      } else if (this.user.permissions_result && (this.user.permissions_result.indexOf('wisdom') !== -1 || this.user.permissions_result.indexOf('administrator') !== -1 || this.user.permissions_result.indexOf('general_open-test-model') !== -1) && this.d_selectedWdmSchemaType === 'wisdom' && this.d_selectedWdmSchemaGroupType === 'selected' && this.d_selectedWdmSchemaGroup.value) {
        return false;
      } else if (this.user.permissions_result && (this.user.permissions_result.indexOf('wisdom') !== -1 || this.user.permissions_result.indexOf('SAMISAMISAMI') !== -1) && this.d_selectedWdmSchemaType === 'hospital' && this.d_selectedWdmSchemaGroupType === 'selected' && this.d_selectedWdmSchemaGroup.value) {
        return false;
      }
      return true;
    },
    f_saveNewWdmSchema: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': this.$t('wdm16.12724') } });
      let data = {};
      data.owner_type = this.d_selectedWdmSchemaType;
      data.username = this.user.username;
      if (this.d_selectedWdmSchemaType === 'hospital') {
        data.key_info = this.option_data[this.data_type].owner_type + '_' + this.data_type + '_' + this.user.hospital_group + '_' + this.user.hospital_id;
      } else {
        data.key_info = this.option_data[this.data_type].owner_type + '_' + this.data_type;
      }
      data.schema_name = this.d_newWdmSchemaTitle;
      data.reference_wdm = { 'data_type': this.data_type, 'owner_type': this.option_data[this.data_type].owner_type, 'version': this.option_data[this.data_type].version, 'key': 'wdm_' + this.option_data[this.data_type].owner_type + '_' + this.data_type + '_v' + this.option_data[this.data_type].version }
      if (this.option_data[this.data_type].owner_type === 'hospital') {
        data.reference_wdm.key = 'wdm_' + this.option_data[this.data_type].owner_type + '_' + this.data_type + '_' + this.user.hospital_group + '_' + this.user.hospital_id + '_v' + this.option_data[this.data_type].version;
      }
      data.schema_data = this.f_calculateSchemaData();
      data.schema_group = this.d_selectedWdmSchemaGroup.value;
      data.wdm_version = this.option_data[this.data_type].version;
      SchemaService.new_wdm_schema(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_wdmSchemaList.push(resp.data.result);
          }
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          this.d_showNewWdmSchema = false;
        });
    },
    f_saveNewWdmSchemaGroup: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': this.$t('wdm16.12728') } });
      let data = {};
      data.owner_type = this.d_newWdmSchemaGroupOwnerType;
      data.username = this.user.username;
      data.wisdom_data_model = { 'wdm': this.data_type, 'owner_type': this.option_data[this.data_type].owner_type, 'hospital_group': '', 'hospital_id': '' }
      if (this.d_newWdmSchemaGroupOwnerType === 'user') {
        data.key = 'schema_group_user_wdm_' + this.option_data[this.data_type].owner_type + '_' + this.data_type + '_' + this.user.username;
      } else if (this.d_newWdmSchemaGroupOwnerType === 'wisdom') {
        data.key = 'schema_group_wisdom_wdm_' + this.option_data[this.data_type].owner_type + '_' + this.data_type;
      } else if (this.d_newWdmSchemaGroupOwnerType === 'hospital') {
        data.key = 'schema_group_hospital_wdm_' + this.option_data[this.data_type].owner_type + '_' + this.data_type + '_' + this.user.hospital_group + '_' + this.user.hospital_id;
      }
      if (this.option_data[this.data_type].owner_type === 'hospital') {
        data.wisdom_data_model.hospital_group = this.user.hospital_group;
        data.wisdom_data_model.hospital_id = this.user.hospital_id;
      }
      data.group_name = this.d_newWdmSchemaGroupTitle;
      SchemaService.new_wdm_schema_group(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.f_getWdmAndAnatomySchemaGroups();
          } else {
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          }
          this.d_showNewWdmSchemaGroup = false;
        });
    },
    f_addNewWdmSchemaGroup: function () {
      this.d_newWdmSchemaGroupTitle = '';
      this.d_newWdmSchemaGroupOwnerType = this.d_selectedWdmSchemaType;
      this.d_showNewWdmSchemaGroup = true;
    },
    f_changeSelectedWdmSchemaGroupType: function () {
      this.d_wdmSchemaList = [];
      this.d_selectedWdmSchemaGroup = {};
      if (this.d_selectedWdmSchemaGroupType === 'all') {
        for (let i in this.d_wdmSchemaGroupList) {
          if (this.d_wdmSchemaGroupList[i].list[this.option_data[this.data_type].version]) {
            let group = this.d_wdmSchemaGroupList[i].list[this.option_data[this.data_type].version];
            for (let k in group) {
              this.d_wdmSchemaList.push(group[k]);
            }
          }
        }
      }
      this.$forceUpdate();
    },
    f_changeSelectedWdmSchemaGroup: function () {
      if (this.d_selectedWdmSchemaGroupType === 'selected' && this.d_selectedWdmSchemaGroup.value) {
        if (this.d_selectedWdmSchemaGroup.list[this.option_data[this.data_type].version]) {
          this.d_wdmSchemaList = JSON.parse(JSON.stringify(this.d_selectedWdmSchemaGroup.list[this.option_data[this.data_type].version]));
        } else {
          this.d_wdmSchemaList = [];
        }
      }
    },
    f_changeSelectedWdmSchemaType: function () {
      // this.d_selectedWdmSchemaGroup = {};
      if (this.d_selectedWdmSchemaType === 'wisdom') {
        this.d_wdmSchemaGroupList = JSON.parse(JSON.stringify(this.d_wisdomWdmSchemaGroupList));
      } else if (this.d_selectedWdmSchemaType === 'user') {
        this.d_wdmSchemaGroupList = JSON.parse(JSON.stringify(this.d_userWdmSchemaGroupList));
      } else if (this.d_selectedWdmSchemaType === 'hospital') {
        this.d_wdmSchemaGroupList = JSON.parse(JSON.stringify(this.d_hospitalWdmSchemaGroupList));
      }
      this.d_selectedWdmSchemaGroupType = 'all';
      this.f_changeSelectedWdmSchemaGroupType();
      this.$forceUpdate();
    },
    f_getWdmAndAnatomySchemaGroups: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': this.$t('wdm16.12726') } });
      let wisdom_wdm_key = 'schema_group_wisdom_wdm_' + this.option_data[this.data_type].owner_type + '_' + this.data_type;
      let wisdom_anatomy_key = 'schema_group_wisdom_wdm_anatomy_' + this.option_data[this.data_type].owner_type + '_' + this.data_type;
      let option_bucket_key_list = [];
      option_bucket_key_list.push(wisdom_wdm_key);
      option_bucket_key_list.push(wisdom_anatomy_key);
      let user_wdm_key = 'schema_group_user_wdm_' + this.option_data[this.data_type].owner_type + '_' + this.data_type + '_' + this.user.username;
      let user_anatomy_key = 'schema_group_user_wdm_anatomy_' + this.option_data[this.data_type].owner_type + '_' + this.data_type + '_' + this.user.username;
      let hospital_wdm_key = 'schema_group_hospital_wdm_' + this.option_data[this.data_type].owner_type + '_' + this.data_type + '_' + this.user.hospital_group + '_' + this.user.hospital_id;
      let hospital_anatomy_key = 'schema_group_hospital_wdm_anatomy_' + this.option_data[this.data_type].owner_type + '_' + this.data_type + '_' + this.user.hospital_group + '_' + this.user.hospital_id;
      let hospital_bucket_key_list = [];
      hospital_bucket_key_list.push(user_wdm_key);
      hospital_bucket_key_list.push(user_anatomy_key);
      hospital_bucket_key_list.push(hospital_wdm_key);
      hospital_bucket_key_list.push(hospital_anatomy_key);
      GlobalService.get_multiple_cb_documents({ 'key_list': option_bucket_key_list, 'bucket': 'option' })
        .then(resp => {
          if (resp.data.status === 'success') {
            if (resp.data.result[wisdom_wdm_key] && resp.data.result[wisdom_wdm_key].list) {
              this.d_wisdomWdmSchemaGroupList = JSON.parse(JSON.stringify(resp.data.result[wisdom_wdm_key].list));
            }
            if (resp.data.result[wisdom_anatomy_key] && resp.data.result[wisdom_anatomy_key].list) {
              this.d_wisdomWdmAnatomySchemaGroupList = JSON.parse(JSON.stringify(resp.data.result[wisdom_anatomy_key].list));
            }
          }
          GlobalService.get_multiple_cb_documents({ 'key_list': hospital_bucket_key_list, 'bucket': 'hospital' })
            .then(resp => {
              if (resp.data.status === 'success') {
                if (resp.data.result[user_wdm_key] && resp.data.result[user_wdm_key].list) {
                  this.d_userWdmSchemaGroupList = JSON.parse(JSON.stringify(resp.data.result[user_wdm_key].list));
                }
                if (resp.data.result[user_anatomy_key] && resp.data.result[user_anatomy_key].list) {
                  this.d_userWdmAnatomySchemaGroupList = JSON.parse(JSON.stringify(resp.data.result[user_anatomy_key].list));
                }
                if (resp.data.result[hospital_wdm_key] && resp.data.result[hospital_wdm_key].list) {
                  this.d_hospitalWdmSchemaGroupList = JSON.parse(JSON.stringify(resp.data.result[hospital_wdm_key].list));
                }
                if (resp.data.result[hospital_anatomy_key] && resp.data.result[hospital_anatomy_key].list) {
                  this.d_hospitalWdmAnatomySchemaGroupList = JSON.parse(JSON.stringify(resp.data.result[hospital_anatomy_key].list));
                }
              }
              this.f_changeSelectedWdmSchemaType();
              this.f_changeSelectedWdmAnatomySchemaType();
              this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
              this.$forceUpdate();
              if (this.p_schemaEdit) {
                if (this.p_schemaEdit.wdm_or_anatomy === 'wdm') {
                  if (this.p_schemaEdit.schema_owner_type === 'wisdom') {
                    this.d_editSchemaGroupList = JSON.parse(JSON.stringify(this.d_wisdomWdmSchemaGroupList));
                  } else if (this.p_schemaEdit.schema_owner_type === 'user') {
                    this.d_editSchemaGroupList = JSON.parse(JSON.stringify(this.d_userWdmSchemaGroupList));
                  } else if (this.p_schemaEdit.schema_owner_type === 'hospital') {
                    this.d_editSchemaGroupList = JSON.parse(JSON.stringify(this.d_hospitalWdmSchemaGroupList));
                  }
                } else {
                  if (this.p_schemaEdit.schema_owner_type === 'wisdom') {
                    this.d_editSchemaGroupList = JSON.parse(JSON.stringify(this.d_wisdomWdmAnatomySchemaGroupList));
                  } else if (this.p_schemaEdit.schema_owner_type === 'user') {
                    this.d_editSchemaGroupList = JSON.parse(JSON.stringify(this.d_userWdmAnatomySchemaGroupList));
                  } else if (this.p_schemaEdit.schema_owner_type === 'hospital') {
                    this.d_editSchemaGroupList = JSON.parse(JSON.stringify(this.d_hospitalWdmAnatomySchemaGroupList));
                  }
                }
              }
            });
        });
    },
    f_addNewWdmAnatomySchema: function () {
      this.d_newWdmAnatomySchemaTitle = '';
      this.d_showNewWdmAnatomySchema = true;
    },
    f_checkNewWdmAnatomySchemaGroupAdd: function () {
      // this func return for disabled status
      if (this.d_selectedWdmAnatomySchemaType === 'user') {
        return false;
      } else if (this.user.permissions_result && (this.user.permissions_result.indexOf('wisdom') !== -1 || this.user.permissions_result.indexOf('administrator') !== -1 || this.user.permissions_result.indexOf('general_open-test-model') !== -1) && this.d_selectedWdmAnatomySchemaType === 'wisdom') {
        return false;
      } else if (this.user.permissions_result && (this.user.permissions_result.indexOf('wisdom') !== -1 || this.user.permissions_result.indexOf('SAMISAMISAMI') !== -1) && this.d_selectedWdmAnatomySchemaType === 'hospital') {
        return false;
      }
      return true;
    },
    f_checkNewWdmAnatomySchemaAdd: function () {
      // this func return for disabled status
      if (this.d_selectedWdmAnatomySchemaType === 'user' && this.d_selectedWdmAnatomySchemaGroupType === 'selected' && this.d_selectedWdmAnatomySchemaGroup.value) {
        return false;
      } else if (this.user.permissions_result && (this.user.permissions_result.indexOf('wisdom') !== -1 || this.user.permissions_result.indexOf('administrator') !== -1 || this.user.permissions_result.indexOf('general_open-test-model') !== -1) && this.d_selectedWdmAnatomySchemaType === 'wisdom' && this.d_selectedWdmAnatomySchemaGroupType === 'selected' && this.d_selectedWdmAnatomySchemaGroup.value) {
        return false;
      } else if (this.user.permissions_result && (this.user.permissions_result.indexOf('wisdom') !== -1 || this.user.permissions_result.indexOf('SAMISAMISAMI') !== -1) && this.d_selectedWdmAnatomySchemaType === 'hospital' && this.d_selectedWdmAnatomySchemaGroupType === 'selected' && this.d_selectedWdmAnatomySchemaGroup.value) {
        return false;
      }
      return true;
    },
    f_saveNewWdmAnatomySchema: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': this.$t('wdm16.12727') } });
      let data = {};
      data.owner_type = this.d_selectedWdmAnatomySchemaType;
      data.username = this.user.username;
      if (this.d_selectedWdmAnatomySchemaType === 'hospital') {
        data.key_info = this.option_data[this.data_type].owner_type + '_' + this.data_type + '_' + this.user.hospital_group + '_' + this.user.hospital_id;
      } else {
        data.key_info = this.option_data[this.data_type].owner_type + '_' + this.data_type;
      }
      data.schema_name = this.d_newWdmAnatomySchemaTitle;
      data.reference_wdm = { 'data_type': this.data_type, 'owner_type': this.option_data[this.data_type].owner_type, 'version': this.option_data[this.data_type].version, 'key': 'wdm_' + this.option_data[this.data_type].owner_type + '_' + this.data_type + '_v' + this.option_data[this.data_type].version };
      if (this.option_data[this.data_type].owner_type === 'hospital') {
        data.reference_wdm.key = 'wdm_' + this.option_data[this.data_type].owner_type + '_' + this.data_type + '_' + this.user.hospital_group + '_' + this.user.hospital_id + '_v' + this.option_data[this.data_type].version;
      }
      data.schema_data = this.f_calculateSchemaData().anatomy;
      data.schema_group = this.d_selectedWdmAnatomySchemaGroup.value;
      data.wdm_version = this.option_data[this.data_type].version;
      SchemaService.new_wdm_anatomy_schema(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_wdmAnatomySchemaList.push(resp.data.result);
          }
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          this.d_showNewWdmAnatomySchema = false;
        });
    },
    f_saveNewWdmAnatomySchemaGroup: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': this.$t('wdm16.12728') } });
      let data = {};
      data.owner_type = this.d_newWdmAnatomySchemaGroupOwnerType;
      data.username = this.user.username;
      data.wisdom_data_model = { 'wdm': this.data_type, 'owner_type': this.option_data[this.data_type].owner_type, 'hospital_group': '', 'hospital_id': '' }
      if (this.d_newWdmAnatomySchemaGroupOwnerType === 'user') {
        data.key = 'schema_group_user_wdm_anatomy_' + this.option_data[this.data_type].owner_type + '_' + this.data_type + '_' + this.user.username;
      } else if (this.d_newWdmAnatomySchemaGroupOwnerType === 'wisdom') {
        data.key = 'schema_group_wisdom_wdm_anatomy_' + this.option_data[this.data_type].owner_type + '_' + this.data_type;
      } else if (this.d_newWdmAnatomySchemaGroupOwnerType === 'hospital') {
        data.key = 'schema_group_hospital_wdm_anatomy_' + this.option_data[this.data_type].owner_type + '_' + this.data_type + '_' + this.user.hospital_group + '_' + this.user.hospital_id;
      }
      if (this.option_data[this.data_type].owner_type === 'hospital') {
        data.wisdom_data_model.hospital_group = this.user.hospital_group;
        data.wisdom_data_model.hospital_id = this.user.hospital_id;
      }
      data.group_name = this.d_newWdmAnatomySchemaGroupTitle;
      SchemaService.new_wdm_anatomy_schema_group(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.f_getWdmAndAnatomySchemaGroups();
          } else {
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          }
          this.d_showNewWdmAnatomySchemaGroup = false;
        });
    },
    f_addNewWdmAnatomySchemaGroup: function () {
      this.d_newWdmAnatomySchemaGroupTitle = '';
      this.d_newWdmAnatomySchemaGroupOwnerType = this.d_selectedWdmAnatomySchemaType;
      this.d_showNewWdmAnatomySchemaGroup = true;
    },
    f_changeSelectedWdmAnatomySchemaGroupType: function () {
      this.d_wdmAnatomySchemaList = [];
      this.d_selectedWdmAnatomySchemaGroup = {};
      if (this.d_selectedWdmAnatomySchemaGroupType === 'all') {
        for (let i in this.d_wdmAnatomySchemaGroupList) {
          if (this.d_wdmAnatomySchemaGroupList[i].list[this.option_data[this.data_type].version]) {
            let group = this.d_wdmAnatomySchemaGroupList[i].list[this.option_data[this.data_type].version];
            for (let k in group) {
              this.d_wdmAnatomySchemaList.push(group[k]);
            }
          }
        }
      }
      this.$forceUpdate();
    },
    f_changeSelectedWdmAnatomySchemaGroup: function () {
      if (this.d_selectedWdmAnatomySchemaGroupType === 'selected' && this.d_selectedWdmAnatomySchemaGroup.value) {
        if (this.d_selectedWdmAnatomySchemaGroup.list[this.option_data[this.data_type].version]) {
          this.d_wdmAnatomySchemaList = JSON.parse(JSON.stringify(this.d_selectedWdmAnatomySchemaGroup.list[this.option_data[this.data_type].version]));
        } else {
          this.d_wdmAnatomySchemaList = [];
        }
      }
    },
    f_changeSelectedWdmAnatomySchemaType: function () {
      // this.d_selectedWdmAnatomySchemaGroup = {};
      if (this.d_selectedWdmAnatomySchemaType === 'wisdom') {
        this.d_wdmAnatomySchemaGroupList = JSON.parse(JSON.stringify(this.d_wisdomWdmAnatomySchemaGroupList));
      } else if (this.d_selectedWdmAnatomySchemaType === 'user') {
        this.d_wdmAnatomySchemaGroupList = JSON.parse(JSON.stringify(this.d_userWdmAnatomySchemaGroupList));
      } else if (this.d_selectedWdmAnatomySchemaType === 'hospital') {
        this.d_wdmAnatomySchemaGroupList = JSON.parse(JSON.stringify(this.d_hospitalWdmAnatomySchemaGroupList));
      }
      this.d_selectedWdmAnatomySchemaGroupType = 'all';
      this.f_changeSelectedWdmAnatomySchemaGroupType();
      this.$forceUpdate();
    },
    f_getWisdomAndUserWdmAnatomySchemaGroups: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': this.$t('wdm16.12729') } });
      let wisdom_key = 'schema_group_wisdom_wdm_anatomy_' + this.option_data[this.data_type].owner_type + '_' + this.data_type;
      let user_key = 'schema_group_user_wdm_anatomy_' + this.option_data[this.data_type].owner_type + '_' + this.data_type + '_' + this.user.username;
      let hospital_key = 'schema_group_hospital_wdm_anatomy_' + this.option_data[this.data_type].owner_type + '_' + this.data_type + '_' + this.user.hospital_group + '_' + this.user.hospital_id;
      let key_list = [];
      key_list.push(wisdom_key);
      key_list.push(user_key);
      key_list.push(hospital_key);
      GlobalService.get_multiple_cb_documents({ 'key_list': key_list, 'bucket': 'option' })
        .then(resp => {
          if (resp.data.status === 'success') {
            if (resp.data.result[wisdom_key] && resp.data.result[wisdom_key].list) {
              this.d_wisdomWdmAnatomySchemaGroupList = JSON.parse(JSON.stringify(resp.data.result[wisdom_key].list));
            }
            if (resp.data.result[user_key] && resp.data.result[user_key].list) {
              this.d_userWdmAnatomySchemaGroupList = JSON.parse(JSON.stringify(resp.data.result[user_key].list));
            }
            if (resp.data.result[hospital_key] && resp.data.result[hospital_key].list) {
              this.d_hospitalWdmAnatomySchemaGroupList = JSON.parse(JSON.stringify(resp.data.result[hospital_key].list));
            }
          }
          this.f_changeSelectedWdmAnatomySchemaType();
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          this.$forceUpdate();
        });
    },
    f_calculateDateData: function () {
      if (this.data.date && this.option_data[this.data_type].date_ability === 'yes') {
        if (!this.data.date_type) {
          this.data.date_type = this.option_data[this.data_type].date_ability_type;
        }
      }
      if (this.data.ending_date && this.option_data[this.data_type].ending_date_ability === 'yes') {
        if (!this.data.ending_date_type) {
          this.data.ending_date_type = this.option_data[this.data_type].ending_date_ability_type;
        }
      }
      if (!this.data.date && this.option_data[this.data_type].date_ability === 'yes') {
        let today = new Date();
        this.d_showDate = false;
        if (!this.data.date_type) {
          this.data.date_type = this.option_data[this.data_type].date_ability_type;
        }
        if (this.data.date_type === 'datetime') {
          this.data.date = moment(today).format("YYYY-MM-DD HH:mm");
          this.d_dateAbilityLimit = moment(today).format("YYYY-MM-DD HH:mm");
        } else if (this.data.date_type === 'date') {
          this.data.date = moment(today).format("YYYY-MM-DD");
          this.d_dateAbilityLimit = moment(today).format("YYYY-MM-DD");
        } else if (this.data.date_type === 'yearmonth') {
          this.data.date = moment(today).format("YYYY-MM");
          this.d_dateAbilityLimit = moment(today).format("YYYY-MM");
        } else if (this.data.date_type === 'year') {
          this.data.date = moment(today).format("YYYY");
          this.d_dateAbilityLimit = moment(today).format("YYYY");
        }
      }
      if (!this.data.ending_date && this.option_data[this.data_type].ending_date_ability === 'yes') {
        let today = new Date();
        if (!this.data.ending_date_type) {
          this.data.ending_date_type = this.option_data[this.data_type].ending_date_ability_type;
        }
        if (this.data.ending_date_type === 'datetime') {
          this.d_endingDateAbilityLimit = moment(today).format("YYYY-MM-DD HH:mm");
        } else if (this.data.ending_date_type === 'date') {
          this.d_endingDateAbilityLimit = moment(today).format("YYYY-MM-DD");
        } else if (this.data.ending_date_type === 'yearmonth') {
          this.d_endingDateAbilityLimit = moment(today).format("YYYY-MM");
        } else if (this.data.ending_date_type === 'year') {
          this.d_endingDateAbilityLimit = moment(today).format("YYYY");
        }
      }
      if (this.data.date) {
        this.f_calculateEndingDateLimit();
      }
    },
    f_showTrueFalse: function () {
      this.show_image = false;
      setTimeout(function () {
        this.show_image = true;
      }.bind(this), 1000)
    },
    f_showWisdomDataShowTrueFalse: function () {
      this.d_showWisdomDataShowTrueFalse = false;
      setTimeout(function () {
        this.d_showWisdomDataShowTrueFalse = true;
      }.bind(this), 1000)
    },
    f_rotateImage: function () {
      // this.f_showTrueFalse();
      if (this.rotate_degree === 360) {
        this.rotate_degree = 90;
      } else {
        this.rotate_degree += 90;
      }
    },
    f_scaleImageBig: function () {
      // this.f_showTrueFalse();
      this.d_scale += 0.1;
    },
    f_scaleImageSmall: function () {
      // this.f_showTrueFalse();
      this.d_scale -= 0.1;
    },
    PreviewImage: function (doc_ind, file_name) {
      if (this.data.document_list[doc_ind].document) {
        this.document_show_data.file_name = this.data.document_list[doc_ind].file_name;
        this.document_show_data.file = this.data.document_list[doc_ind].document;
      } else {
        let data = { 'key_list': [] };
        let key = this.data_type + '_' + this.data.id + '_doc' + doc_ind.toString();
        data.key_list.push(key);
        FileTransferService.get_file_with_key(data)
          .then(resp => {
            if (resp.data.status === 'success') {
              if (resp.data.result[key].file) {
                this.document_show_data.file_name = file_name;
                this.document_show_data.file = resp.data.result[key].file;
              }
            } else {
              alert('error: ', resp.data.message);
            }
          });
      }
    },
    f_saveUserSettings: function () {
      ClsUserSettings.saveSettings(JSON.parse(localStorage.getItem('user')));
    },
    f_updateUserFavoriteShowModeSettings: function () {
      this.user = JSON.parse(localStorage.getItem('user'));
      try {
        this.d_wdmParameters.selected_index_data.index_list = this.user.settings.wdmr_record.settings.favorite_show_mode.settings[this.data.reference_wdm.key].index_list;
        this.$forceUpdate();
      } catch (err) {}
    },
    f_updateUserDontShowCaptionSettings: function () {
      this.user = JSON.parse(localStorage.getItem('user'));
      try {
        this.d_wdmParameters.dont_show_caption_index_data.index_list = this.user.settings.wdmr_record.settings.dont_show_caption_mode.settings[this.data.reference_wdm.key].index_list;
        this.$forceUpdate();
      } catch (err) {}
    },
    f_controlParamGroup: function (param_group) {
      if (this.use_parameter_list) {
        if (this.parameter_list && this.parameter_list.length > 0) {
          let x = { 'y': false };
          this.f_controlParameterInThisGroup(this.option_data[this.data_type][param_group]['parameters'], x);
          if (x.y) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    f_controlParameterInThisGroup: function (parameters, x) {
      if (!x.y) {
        for (let p in parameters) {
          if (this.parameter_list.indexOf(p) !== -1) {
            x.y = true;
          }
          if (x.y) {
            break;
          } else {
            if (parameters['parameters']) {
              this.f_controlParameterInThisGroup(parameters['parameters'], x);
            }
          }
        }
      }
    },
    f_deleteDocument: function (doc_ind) {
      if (this.data.document_list[doc_ind]) {
        if (this.data.document_list[doc_ind].record_status && this.data.document_list[doc_ind].record_status === 'add_edit') {
          this.data.document_list.splice(doc_ind, 1);
        } else if (this.data.document_list[doc_ind].record_status && this.data.document_list[doc_ind].record_status === 'recorded') {
          this.data.document_list[doc_ind].record_status = 'deleted';
        }
        this.$forceUpdate();
      }
    },
    // f_lookForThisUserHasThisProjectPermission: function(project, perm) {
    //   if (project && project.value) {
    //     let d_userProjects = JSON.parse(localStorage.getItem('user_projects'));
    //     for (let i in d_userProjects) {
    //       if (project.value === d_userProjects[i].id) {
    //         if (d_userProjects[i].data.members && d_userProjects[i].data.members.length > 0) {
    //           for (let m in d_userProjects[i].data.members) {
    //             if (this.user.username === d_userProjects[i].data.members[m].username && d_userProjects[i].data.members[m].data_models && d_userProjects[i].data.members[m].data_models[this.data_type].permissions.indexOf(perm) !== -1) {
    //               return true;
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    //   return false;
    // },
    f_addDocument: function () {
      let file_name = this.d_tmpFile.name;
      let arr = this.d_tmpFile.name.split('.');
      let fileExtension = arr[arr.length - 1];
      // console.log(fileExtension);
      if (["jpg", "jpeg", "png", "docx", "doc", "pdf", "xls", "xlsx"].indexOf(fileExtension.toLowerCase()) === -1) {
        this.d_tmpFile = '';
        this.$forceUpdate();
        alert('yasaklı dosya tipi');
        return;
      }
      if (this.d_tmpFile.size > 10485760) {
        this.d_tmpFile = '';
        this.$forceUpdate();
        alert(this.$t('wdm16.12730'));
        return;
      }

      var reader = new FileReader();
      var vm = this;
      if (!this.data.document_list) {
        this.data.document_list = [];
      }
      let doc_data = {
        'record_status': 'add_edit',
        'status': 'upload',
        'user': this.user.username,
        'document': '',
        'information': '',
        'fileExtension': fileExtension,
        'file_name': file_name
      };
      this.data.document_list.push(doc_data);

      var reader = new FileReader();
      reader.addEventListener("load", function () {
        vm.data.document_list[vm.data.document_list.length - 1].document = reader.result;
      }, false);
      reader.readAsDataURL(this.d_tmpFile);
      this.d_tmpFile = '';
    },
    AddDocumentOld: function () {
      if (document.getElementById('selected_document').value) {
        this.show_document_list = false;
        let arr = document.getElementById('selected_document').value.split('.');
        let fileExtension = arr[arr.length - 1];
        let document_type_list = JSON.parse(JSON.stringify(this.option_data[this.data_type].document_type_list));
        document_type_list = document_type_list.concat(['xlsx', 'xls']);
        if (document_type_list.indexOf(fileExtension.toLowerCase()) === -1) {
          document.getElementById('selected_document').value = '';
          alert(this.$t('wdm16.12731') + document_type_list + this.$t('wdm16.12732'));
          return;
        }
        var files = document.getElementById('selected_document').files;
        if (!files.length) {
          return;
        }
        let file_name = files[0].name;
        if (files[0].size > 10000000) {
          document.getElementById('selected_document').value = '';
          alert(this.$t('wdm16.12733'));
          return;
        }
        var reader = new FileReader();
        var vm = this;
        if (!this.data.document_list) {
          this.data.document_list = [];
        }
        let doc_data = {
          'record_status': 'add_edit',
          'status': 'upload',
          'user': this.user.username,
          'document': '',
          'information': '',
          'fileExtension': fileExtension,
          'file_name': file_name
        };
        this.data.document_list.push(doc_data);
        reader.onload = (e) => {
          vm.data.document_list[vm.data.document_list.length - 1].document = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.show_document_list = true;
        this.$forceUpdate();
      }
    },
    CreateAcceptedDocumentTypes: function () {
      let accept = '';
      for (let i in this.option_data[this.data_type].document_type_list) {
        accept += '.' + this.option_data[this.data_type].document_type_list[i] + ',';
      }
      accept += '.xlsx,.xls,.pdf';
      return accept;
    },
    f_paramChange: function () {
      if (this.param_change.id) {
        this.param_change.id = 0;
      } else {
        this.param_change.id = 1;
      }
    },
    copy_anatomy_data_to_this_anatomy (copy_ana_ind, ana_ind) {
      this.data['data'].anatomy[ana_ind].signs = this.data['data'].anatomy[copy_ana_ind].signs;
      this.$forceUpdate();
    },
    f_calculateSchemaData: function () {
      let return_data = ClsWdmrForever.turn(this.data, this.option_data[this.data_type], ['schema']);
      return return_data.schema;
    },
    calculate_name: function () {
      // let name = "";

      let return_data = ClsWdmrForever.turn(this.data, this.option_data[this.data_type], ['name', 'delete']);
      this.data.label = return_data.name;

      // if (this.data['date'] && this.data['date'] !== null) {
      //   if (this.data['date'].includes('T')) {
      //     name += this.DateFormat(this.data['date'].split('T')[0]) + ' ' + this.data['date'].split('T')[1].split(':')[0] + ':' + this.data['date'].split('T')[1].split(':')[1] + ', ';
      //   } else if (this.data['date'].includes(' ')) {
      //     name += this.DateFormat(this.data['date'].split(' ')[0]) + ' ' + this.data['date'].split(' ')[1].split(':')[0] + ':' + this.data['date'].split(' ')[1].split(':')[1] + ', ';
      //   } else {
      //     name += this.DateFormat(this.data['date']) + ', ';
      //   }
      // }
      // for (let i in this.option_data[this.data_type].param_group.param_order) {
      //   let pg = this.option_data[this.data_type].param_group.param_order[i];
      //   if (pg === "anatomy") {
      //     for (let ana in this.data['data'].anatomy) {
      //       let x = this.main_param_func(this.data['data'].anatomy[ana].signs, this.option_data[this.data_type].anatomy);
      //       if (x) {
      //         name += this.data['data'].anatomy[ana].name.label + " [" + x + "]; ";
      //       }
      //     }
      //   } else {
      //     name += this.main_param_func(this.data['data'][pg], this.option_data[this.data_type][pg]);
      //   }
      // }
      // if (name.endsWith(' ')) {
      //   name = name.slice(0, -1);
      // }
      // if (name.endsWith(',')) {
      //   name = name.slice(0, -1);
      // }
      // console.log('name', name);
      this.$forceUpdate();
    },
    main_param_func: function (data, group_parameters) {
      let name = "";
      for (let i in group_parameters.param_order) {
        let param = group_parameters.param_order[i];
        if (group_parameters.parameters[param].naming) {
          if (group_parameters.parameters[param].type === "object_list_options" && typeof data[param] === "object" && Object.keys(data[param]).length > 0) {
            name += this.object_list_options_parameter(data[param], group_parameters.parameters[param], group_parameters.parameters[param].name.label);
          } else if (group_parameters.parameters[param].type === "list_parameter" && data[param] && data[param].list && data[param].list.length > 0) {
            name += this.list_parameter(data[param].list, group_parameters.parameters[param], group_parameters.parameters[param].name.label);
          } else if (group_parameters.parameters[param].type !== "object_list" && data[param] && group_parameters.parameters[param].type !== "object_list_options") {
            name += this.other_parameter(data, group_parameters, param);
          }
        }
      }
      return name;
    },
    other_parameter: function (data, group_parameters, param) {
      let name = "";
      if (data[param] && data[param]['val']) {
        if (group_parameters.parameters[param].naming) {
          if (group_parameters.parameters[param].type === "date") {
            name += this.DateFormat(data[param]['val']) + ", ";
          } else if (["select"].indexOf(group_parameters.parameters[param].type) !== -1 && data[param]['val']['label']) {
            name += data[param]['val'].label + ", ";
          } else if (["integer", "float", "textarea", "text"].indexOf(group_parameters.parameters[param].type) !== -1) {
            name += data[param]['val'] + ", ";
          }
        }
      } else {
        // console.log(data, param);
        // this.$delete(data, param);
      }
      return name;
    },
    list_parameter: function (list_data, mother_parameter_data, list_caption) {
      let name = "";
      let list_name = "";
      for (let i in list_data) {
        let x = this.main_param_func(list_data[i], mother_parameter_data);
        if (x) {
          list_name += (parseInt(i) + 1).toString() + ")" + x;
        }
      }
      if (list_name) {
        name = list_caption + "= " + list_name;
      }
      return name;
    },
    object_list_options_parameter: function (
      data,
      mother_parameter_data,
      list_caption
    ) {
      let name = "";
      let detail_name = "";
      for (let i in data) {
        detail_name += data[i].name.label + ", ";
        if (data[i].detail && Object.keys(data[i].detail).length > 0) {
          let x = this.list_parameter(
            data[i].detail,
            mother_parameter_data,
            data[i].name.label
          );
          if (x) {
            detail_name += data[i].name.label + "; " + x;
          }
        }
      }
      if (detail_name) {
        name = list_caption + "; " + detail_name;
      }
      return name;
    },
    DateFormat2: function (date) {
      if (date) {
        let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY");
        return x;
      } else {
        return "";
      }
    },
    DateFormat: function (date) {
      if (date) {
        let months = {
          "01": { translation: { en: "january", tr: "ocak" } },
          "02": { translation: { en: "february", tr: "şubat" } },
          "03": { translation: { en: "march", tr: "mart" } },
          "04": { translation: { en: "april", tr: "nisan" } },
          "05": { translation: { en: "may", tr: "mayıs" } },
          "06": { translation: { en: "june", tr: "haziran" } },
          "07": { translation: { en: "july", tr: "temmuz" } },
          "08": { translation: { en: "august", tr: "ağustos" } },
          "09": { translation: { en: "september", tr: "eylül" } },
          "10": { translation: { en: "october", tr: "ekim" } },
          "11": { translation: { en: "november", tr: "kasım" } },
          "12": { translation: { en: "december", tr: "aralık" } }
        };
        let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY");
        let y = x.split("/");
        return y[0] + " " + months[y[1]].translation[this.lang] + " " + y[2];
      } else {
        return "";
      }
    },
    get_tests: function () {
      this.selected_old_test = { 'label': this.$t('wdm16.12734'), 'value': 'updating' };
      PatientService.get_patient_wdmr_for_wdm_version({ 'patient_id': this.patient.id, 'type': this.data_type, 'owner_type': this.option_data[this.data_type].owner_type, 'version': this.option_data[this.data_type].version })
        .then(resp => {
          // console.log(resp);
          this.old_tests = resp.data.result;
          this.selected_old_test = '';
          this.$forceUpdate();
        });
      // let data = { type_list: [], patient_id: "" };
      // data.patient_id = this.patient.id;
      // data.type_list.push(this.data_type);
      // PatientService.get_fast_follow(data).then(resp => {
      //   this.old_tests = resp.data.result.record_list;
      //   for (let i in this.old_tests) {
      //     if (this.old_tests[i] && this.old_tests[i]['data'] && this.old_tests[i]['data']["anatomy"]) {
      //       let k = 1;
      //       for (let a in this.old_tests[i]['data']["anatomy"]) {
      //         k += 1;
      //         let cap =
      //           this.old_tests[i]['data']["anatomy"][a].name.label +
      //           " [" +
      //           this.main_param_func(
      //             this.old_tests[i]['data']["anatomy"][a].signs,
      //             this.option_data[this.data_type].anatomy
      //           ) +
      //           "]";
      //         let y = {
      //           caption: cap,
      //           label: k.toString(),
      //           data: this.old_tests[i]['data']["anatomy"][a]
      //         };
      //         this.old_anatomy_tests.push(y);
      //       }
      //     }
      //   }
      //   this.$forceUpdate();
      //   // console.log('response   ', resp.data);
      //   this.selected_old_test = '';
      // });
    },
    get_old_anatomy_test: function () {
      if (!this.data['data'].anatomy) {
        this.data['data'].anatomy = {};
      }
      this.data['data'].anatomy[
        this.selected_old_anatomy_test.data.name.value
      ] = JSON.parse(JSON.stringify(this.selected_old_anatomy_test.data));
      this.$forceUpdate();
    },
    get_wisdom_data_schema_list: function () {
      this.selected_schema = { 'label': this.$t('wdm16.12735'), 'value': 'updating_schema_list' };
      OptionService.getWisdomDataSchemaList({
        schema_type: this.data_type,
        schema_scope: this.schema_scope
      }).then(resp => {
        if (resp.data.status === 'success') {
          this.schema_list = resp.data.result;
        } else if (resp.data.status === 'error') {
          console.log('error :::: get_wisdom_data_schema_list, service message', resp.data.result);
        }
        this.selected_schema = '';
      });
    },
    get_wisdom_data_anatomy_schema_list: function () {
      this.selected_anatomy_schema = { 'label': this.$t('wdm16.12735'), 'value': 'updating_anatomy_schema_list' };
      OptionService.getWisdomDataAnatomySchemaList({
        schema_type: this.data_type,
        schema_scope: this.schema_scope
      }).then(resp => {
        if (resp.data.status === 'success') {
          this.schema_anatomy_list = resp.data.result;
        } else if (resp.data.status === 'error') {
          console.log('error :::: get_wisdom_data_anatomy_schema_list, service message', resp.data.result);
        }
        this.selected_anatomy_schema = '';
      });
    },
    getWisdomDataSchema: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': this.selected_schema.label + this.$t('wdm16.12739') } });
      OptionService.get_wisdom_data_schema(this.selected_schema).then(resp => {
        if (resp.data.status === 'success') {
          this.selected_schema_data = resp.data.result;
          for (let group in this.option_data[this.data_type].param_group.param_order) {
            let paramGrp = this.option_data[this.data_type].param_group.param_order[group];
            if (this.data['data'][paramGrp] !== undefined) {
              this.$delete(this.data['data'], paramGrp);
            }
            if (this.selected_schema_data.data[paramGrp] !== undefined) {
              this.data['data'][paramGrp] = this.selected_schema_data.data[paramGrp];
            }
          }
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
        } else if (resp.data.status === 'error') {
          alert(this.$t('wdm16.12738'), resp.data.result);
        }
        this.$forceUpdate();
      });
    },
    getWisdomDataAnatomySchema: function () {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': this.selected_anatomy_schema.label + this.$t('wdm16.12739') } });
      OptionService.get_wisdom_data_anatomy_schema(this.selected_anatomy_schema).then(resp => {
        if (resp.data.status === 'success') {
          this.selected_schema_anatomy_data = resp.data.result;
          if (this.selected_schema_anatomy_data.data && this.selected_schema_anatomy_data.data.anatomy) {
            this.data['data'].anatomy = this.selected_schema_anatomy_data.data.anatomy;
          }
          this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
        } else if (resp.data.status === 'error') {
          alert(this.$t('wdm16.12738'), resp.data.result);
        }
        this.$forceUpdate();
      });
    },
    get_old_test: function () {
      GlobalService.get_one_cb_document({ 'key': this.selected_old_test['value'], 'bucket': 'wisdom' })
        .then(resp => {
          this.mother_children.d_showNonAnatomyData = false;
          this.data['data'] = JSON.parse(JSON.stringify(resp.data.result.data));
          setTimeout(function () {
            this.mother_children.d_showNonAnatomyData = true;
          }.bind(this), 1000)
        });
      this.$forceUpdate();
    },
    saveWisdomDataSchema: function (x) {
      this.showSchemaModal = false;
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': this.$t('wdm16.12740') } });
      let schema_data = {};
      if (x === "new") {
        schema_data = { data: {} };
        schema_data.data = JSON.parse(JSON.stringify(this.data['data']));
        schema_data.label = this.schema_caption;
        schema_data.schema_type = this.data_type;
        schema_data.scope = this.schema_scope;
      } else if (x === "edit") {
        schema_data = this.selected_schema_data;
        schema_data.data = JSON.parse(JSON.stringify(this.data['data']));
      }
      OptionService.save_wisdom_data_schema(schema_data).then(resp => {
        if (resp.data.status === 'success') {
          alert(this.$t('wdm16.12743'));
          // console.log(resp.data);
        } else if (resp.data.status === 'error') {
          alert(this.$t('wdm16.12742'), resp.data.results);
        }
        this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
      });
    },
    saveWisdomDataAnatomySchema: function (x) {
      this.showSchemaAnatomyModal = false;
      if (this.data['data'].anatomy && Object.keys(this.data['data'].anatomy).length > 0) {
        let schema_data = {};
        if (x === "new") {
          schema_data = { data: { 'anatomy': {} } };
          schema_data.data.anatomy = JSON.parse(JSON.stringify(this.data['data'].anatomy));
          schema_data.label = this.schema_anatomy_caption;
          schema_data.schema_type = this.data_type;
          schema_data.scope = this.schema_scope;
        } else if (x === "edit") {
          schema_data = this.selected_schema_data;
          schema_data.data.anatomy = JSON.parse(JSON.stringify(this.data['data'].anatomy));
        }
        OptionService.save_wisdom_data_anatomy_schema(schema_data).then(resp => {
          if (resp.data.status === 'success') {
            alert(this.$t('wdm16.12743'));
          } else if (resp.data.status === 'error') {
            alert(this.$t('wdm16.12742'), resp.data.results);
          }
        });
      } else {
        alert(this.$t('wdm16.12745'));
      }
    },
    get_toxicity_details: function () {
      let toxicity_data = JSON.parse(localStorage.getItem('toxicity'));
      this.option_data.toxicity_model.general.parameters.toxicity_group.options = toxicity_data.parameters.toxicity_group.options;
      if (this.data['data'].general.toxicity_group && this.data['data'].general.toxicity_group.val) {
        this.option_data.toxicity_model.general.parameters.toxicity_list.options = [];

        for (let i in toxicity_data.parameters.toxicity_list.options) {
          if (toxicity_data.parameters.toxicity_list.options[i].toxicity_group === this.data['data'].general.toxicity_group.val.value) {
            this.option_data.toxicity_model.general.parameters.toxicity_list.options.push(toxicity_data.parameters.toxicity_list.options[i]);

          }
        }

      } else {
        if (this.data['data'].general.toxicity_list) {
          this.data['data'].general.toxicity_list.val = '';
        }
        if (this.data['data'].general.toxicity_grade) {
          this.data['data'].general.toxicity_grade.val = '';
        }
      }
      if (this.data['data'].general.toxicity_group && this.data['data'].general.toxicity_group.val && this.data['data'].general.toxicity_list && this.data['data'].general.toxicity_list.val) {

        let det_list = false;
        for (let k in this.option_data.toxicity_model.general.parameters.toxicity_list.options) {
          if (this.data['data'].general.toxicity_list.val.toxicity_group === this.option_data.toxicity_model.general.parameters.toxicity_list.options[k].toxicity_group && this.data['data'].general.toxicity_list.val.value === this.option_data.toxicity_model.general.parameters.toxicity_list.options[k].value) {
            det_list = true;
            break;
          }
        }
        if (!det_list) {
          if (this.data['data'].general.toxicity_list) {
            this.data['data'].general.toxicity_list.val = '';
          }
          if (this.data['data'].general.toxicity_grade) {
            this.data['data'].general.toxicity_grade.val = '';
          }
        }

        this.option_data.toxicity_model.general.parameters.toxicity_grade.options = [];
        for (let i in toxicity_data.parameters.toxicity_list.options) {
          if (toxicity_data.parameters.toxicity_list.options[i].value === this.data['data'].general.toxicity_list.val.value) {
            this.option_data.toxicity_model.general.parameters.toxicity_grade.options = toxicity_data.parameters.toxicity_list.options[i].grades;
            if (this.data['data'].general.toxicity_grade) {
              let val_grade_str = JSON.stringify(this.data['data'].general.toxicity_grade.val);
              let det_grade = false;
              for (let k in this.option_data.toxicity_model.general.parameters.toxicity_grade.options) {
                let grade_str = JSON.stringify(this.option_data.toxicity_model.general.parameters.toxicity_grade.options[k]);
                if (val_grade_str === grade_str) {
                  det_grade = true;
                }
              }
              if (!det_grade) {
                if (this.data['data'].general.toxicity_grade) {
                  this.data['data'].general.toxicity_grade.val = '';
                }
              }
            }
            break;
          }
        }
      } else {
        if (this.data['data'].general.toxicity_grade) {
          this.data['data'].general.toxicity_grade.val = '';
        }
      }
    },
    get_staging_details: function () {
      // this.show_staging_group_selectable = false;
      // this.option_data.patient_staging.general.parameters.staging_group.type = 'plain_text';
      if (this.data['data'].general.staging_group && this.data['data'].general.staging_group.val && this.data['data'].general.staging_method && this.data['data'].general.staging_method.val) {
        for (let i in this.stage_data.stage_order) {
          if (this.data['data'].general[this.stage_data.stage_order[i]]) {
            this.$delete(this.data['data'].general, this.stage_data.stage_order[i]);
          }
          this.option_data.patient_staging.general.parameters[this.stage_data.stage_order[i]].show = "no";
        }
        // console.log('before service this.data', JSON.stringify(this.data));
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': this.$t('wdm16.12746') } });
        OptionService.search("staging", {
          search: this.data['data'].general.staging_group.val.value,
          method: this.data['data'].general.staging_method.val.value
        }).then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.results.length > 0) {
            this.stage_data = resp.data.results[0];
            if (this.stage_data.stage_order && this.stage_data.stage_order.length > 0) {
              this.option_data.patient_staging.general.parameters.stage.show = "yes";
              // this.option_data.patient_staging.general.parameters.stage.type = "plain_text";
              this.option_data.patient_staging.general.parameters.stage.options = this.stage_data.stage_result;
              this.option_data.patient_staging.general.parameters.stage_code.show = "yes";
              for (let i in this.stage_data.stage_order) {
                this.option_data.patient_staging.general.parameters[this.stage_data.stage_order[i]].show = "yes";
              }
              // console.log('after service  this.data', this.data);
            }
          } else {
            alert(this.$t('wdm16.12747'));
          }
        });
      }
    },
    add_anatomy: function () {
      if (this.selected_anatomy_region && this.selected_anatomy_region.value && !this.data['data'].anatomy[this.selected_anatomy_region.value]) {
        this.data['data'].anatomy[this.selected_anatomy_region.value] = {
          name: JSON.parse(JSON.stringify(this.selected_anatomy_region)),
          signs: {}
        };
      }
      this.$forceUpdate();
    },
    f_calculateBmiData: function () {
      //  wdm1 = bmi modeli
      if (this.data_type === 'wdm1') {
        this.data['data'].general['ideal_weight'] = { 'val': '' };
        this.data['data'].general['adjusted_weight'] = { 'val': '' };
        this.data['data'].general['bmi'] = { 'val': '' };
        this.data['data'].general['bsa'] = { 'val': '' };
        this.data['data'].general['length_inch'] = { 'val': '' };
        this.data['data'].general['length_cm'] = { 'val': '' };
        this.data['data'].general['weight_lbs'] = { 'val': '' };
        this.data['data'].general['weight_kg'] = { 'val': '' };
        if (this.data['data'].general['length'].val) {
          if (this.data['data'].general['length'].unit.value === 'cm') {
            this.data['data'].general['length_cm'].val = parseFloat(this.data['data'].general['length'].val).toFixed(0);
            let a = parseFloat(this.data['data'].general['length'].val) / 2.54;
            this.data['data'].general['length_inch'].val = parseFloat(a).toFixed(0);
          } else {
            this.data['data'].general['length_inch'].val = parseFloat(this.data['data'].general['length'].val).toFixed(0);
            let a = parseFloat(this.data['data'].general['length'].val) * 2.54;
            this.data['data'].general['length_cm'].val = parseFloat(a).toFixed(0);
          }
        }
        if (this.data['data'].general['weight'].val) {
          if (this.data['data'].general['weight'].unit.value === 'lbs') {
            this.data['data'].general['weight_lbs'].val = parseFloat(this.data['data'].general['weight'].val).toFixed(0);
            let a = parseFloat(this.data['data'].general['weight'].val) * 0.45359237;
            this.data['data'].general['weight_kg'].val = parseFloat(a).toFixed(0);
          } else {
            this.data['data'].general['weight_kg'].val = parseFloat(this.data['data'].general['weight'].val).toFixed(0);
            let a = parseFloat(this.data['data'].general['weight'].val) / 0.45359237;
            this.data['data'].general['weight_lbs'].val = parseFloat(a).toFixed(0);
          }
        }
        if (this.data['data'].general['length_inch'].val && this.data['data'].general['weight_kg'].val && this.data['data'].general['length_cm'].val) {
          if (this.patient.sex === '0') {
            let a = 50 + (2.3 * (this.data['data'].general['length_inch'].val - 60));
            this.data['data'].general['ideal_weight'].val = parseFloat(a).toFixed(0);
          } else {
            let a = 45.5 + (2.3 * (this.data['data'].general['length_inch'].val - 60));
            this.data['data'].general['ideal_weight'].val = parseFloat(a).toFixed(0);
          }
          if (this.data['data'].general['ideal_weight'].val) {
            let a = parseFloat(this.data['data'].general['ideal_weight'].val) + 0.4 * (this.data['data'].general['weight_kg'].val - this.data['data'].general['ideal_weight'].val);
            this.data['data'].general['adjusted_weight'].val = parseFloat(a).toFixed(0);
          }
          this.data['data'].general['bmi'].val = (this.data['data'].general['weight_kg'].val / ((this.data['data'].general['length_cm'].val / 100) * (this.data['data'].general['length_cm'].val / 100))).toFixed(2);

          let a = 0.007184 * Math.pow(this.data['data'].general['length_cm'].val, 0.725) * Math.pow(this.data['data'].general['weight_kg'].val, 0.425);
          this.data['data'].general['bsa'].val = parseFloat(a).toFixed(2);
          // console.log('this.data['data'].general['ideal_weight'].val', this.data['data'].general['ideal_weight'].val);
          // console.log('this.data['data'].general['adjusted_weight'].val', this.data['data'].general['adjusted_weight'].val);
          // console.log('bmi', bmi);
          // console.log('bsa', bsa);
          // console.log('this.data['data'].general['length_inch'].val', this.data['data'].general['length_inch'].val);
          // console.log('this.data['data'].general['length_cm'].val', this.data['data'].general['length_cm'].val);
          // console.log('this.data['data'].general['weight_lbs'].val', this.data['data'].general['weight_lbs'].val);
          // console.log('this.data['data'].general['weight_kg'].val', this.data['data'].general['weight_kg'].val);
          // console.log('this.data['data'].general['weight_kg'].val', typeof(this.data['data'].general['weight_kg'].val));
        }
      }
    },
    f_addDrugFromChemotherapy: function () {
      if (this.data['data'].general['treatment_chemotherapy'].val && this.data['data'].general['treatment_chemotherapy'].val.value) {
        GlobalService.get_one_cb_document({ 'key': 'option_chemotherapy_' + this.data['data'].general['treatment_chemotherapy'].val.value, 'bucket': 'option' })
          .then(resp => {
            for (let i in resp.data.result.drugs) {
              // console.log(resp.data.result);
              if (!this.data['data'].general['treatment_drug']) {
                this.data['data'].general['treatment_drug'] = { 'list': [] };
              }
              if (!this.data['data'].general['treatment_drug']['list']) {
                this.data['data'].general['treatment_drug']['list'] = [];
              }
              let push_data = {};
              if (resp.data.result.drugs[i].type !== 'protocol_premedication') {

                push_data.td_drug = { 'val': resp.data.result.drugs[i].drug };
                push_data['td_unknown-info'] = { 'val': { 'label': 'Evet', 'value': 'yes', 'translation': { 'tr': 'Evet', 'en': 'Evet' } } };
                push_data.td_dosage = { 'val': resp.data.result.drugs[i].dosage, 'unit': resp.data.result.drugs[i].unit };
                push_data.td_frequency = { 'val': resp.data.result.drugs[i].frequency };
                push_data.td_fluid = { 'val': resp.data.result.drugs[i].fluid };
                push_data.td_fluid_value = { 'val': resp.data.result.drugs[i].fluid_value };
                push_data.td_dosage_form = { 'val': resp.data.result.drugs[i].dosage_form };
                push_data.td_duration = { 'val': resp.data.result.drugs[i].duration };
                push_data.td_period = { 'val': resp.data.result.drugs[i].period };
                push_data.td_repeat = { 'val': resp.data.result.drugs[i].repeat };

                for (let mx in resp.data.result.drugs[i].drug_mix) {
                  let mx_push_data = {};
                  if (!push_data.td_mix) {
                    push_data.td_mix = { 'list': [] };
                  }
                  mx_push_data.td_mx_drug = { 'val': resp.data.result.drugs[i].drug_mix[mx].drug };
                  mx_push_data['td_mx_unknown-info'] = { 'val': { 'label': 'Evet', 'value': 'yes', 'translation': { 'tr': 'Evet', 'en': 'Evet' } } };
                  mx_push_data.td_mx_dosage = { 'val': resp.data.result.drugs[i].drug_mix[mx].dosage, 'unit': resp.data.result.drugs[i].drug_mix[mx].unit };
                  mx_push_data.td_mx_frequency = { 'val': resp.data.result.drugs[i].drug_mix[mx].frequency };
                  mx_push_data.td_mx_fluid = { 'val': resp.data.result.drugs[i].drug_mix[mx].fluid };
                  mx_push_data.td_mx_fluid_value = { 'val': resp.data.result.drugs[i].drug_mix[mx].fluid_value };
                  mx_push_data.td_mx_dosage_form = { 'val': resp.data.result.drugs[i].drug_mix[mx].dosage_form };
                  mx_push_data.td_mx_duration = { 'val': resp.data.result.drugs[i].drug_mix[mx].duration };
                  push_data.td_mix.list.push(mx_push_data);
                }

                for (let bf in resp.data.result.drugs[i].before) {
                  let bf_push_data = {};
                  if (!push_data.td_before) {
                    push_data.td_before = { 'list': [] };
                  }
                  bf_push_data.td_bf_drug = { 'val': resp.data.result.drugs[i].before[bf].drug };
                  bf_push_data['td_bf_unknown-info'] = { 'val': { 'label': 'Evet', 'value': 'yes', 'translation': { 'tr': 'Evet', 'en': 'Evet' } } };
                  bf_push_data.td_bf_dosage = { 'val': resp.data.result.drugs[i].before[bf].dosage, 'unit': resp.data.result.drugs[i].before[bf].unit };
                  bf_push_data.td_bf_frequency = { 'val': resp.data.result.drugs[i].before[bf].frequency };
                  bf_push_data.td_bf_fluid = { 'val': resp.data.result.drugs[i].before[bf].fluid };
                  bf_push_data.td_bf_fluid_value = { 'val': resp.data.result.drugs[i].before[bf].fluid_value };
                  bf_push_data.td_bf_dosage_form = { 'val': resp.data.result.drugs[i].before[bf].dosage_form };
                  bf_push_data.td_bf_duration = { 'val': resp.data.result.drugs[i].before[bf].duration };
                  push_data.td_before.list.push(bf_push_data);
                }

                for (let dr in resp.data.result.drugs[i].during) {
                  let dr_push_data = {};
                  if (!push_data.td_during) {
                    push_data.td_during = { 'list': [] };
                  }
                  dr_push_data.td_dr_drug = { 'val': resp.data.result.drugs[i].during[dr].drug };
                  dr_push_data['td_dr_unknown-info'] = { 'val': { 'label': 'Evet', 'value': 'yes', 'translation': { 'tr': 'Evet', 'en': 'Evet' } } };
                  dr_push_data.td_dr_dosage = { 'val': resp.data.result.drugs[i].during[dr].dosage, 'unit': resp.data.result.drugs[i].during[dr].unit };
                  dr_push_data.td_dr_frequency = { 'val': resp.data.result.drugs[i].during[dr].frequency };
                  dr_push_data.td_dr_fluid = { 'val': resp.data.result.drugs[i].during[dr].fluid };
                  dr_push_data.td_dr_fluid_value = { 'val': resp.data.result.drugs[i].during[dr].fluid_value };
                  dr_push_data.td_dr_dosage_form = { 'val': resp.data.result.drugs[i].during[dr].dosage_form };
                  dr_push_data.td_dr_duration = { 'val': resp.data.result.drugs[i].during[dr].duration };
                  push_data.td_during.list.push(dr_push_data);
                }

                for (let af in resp.data.result.drugs[i].after) {
                  let af_push_data = {};
                  if (!push_data.td_after) {
                    push_data.td_after = { 'list': [] };
                  }
                  af_push_data.td_af_drug = { 'val': resp.data.result.drugs[i].after[af].drug };
                  af_push_data['td_af_unknown-info'] = { 'val': { 'label': 'Evet', 'value': 'yes', 'translation': { 'tr': 'Evet', 'en': 'Evet' } } };
                  af_push_data.td_af_dosage = { 'val': resp.data.result.drugs[i].after[af].dosage, 'unit': resp.data.result.drugs[i].after[af].unit };
                  af_push_data.td_af_frequency = { 'val': resp.data.result.drugs[i].after[af].frequency };
                  af_push_data.td_af_fluid = { 'val': resp.data.result.drugs[i].after[af].fluid };
                  af_push_data.td_af_fluid_value = { 'val': resp.data.result.drugs[i].after[af].fluid_value };
                  af_push_data.td_af_dosage_form = { 'val': resp.data.result.drugs[i].after[af].dosage_form };
                  af_push_data.td_af_duration = { 'val': resp.data.result.drugs[i].after[af].duration };
                  push_data.td_after.list.push(af_push_data);
                }

                this.data['data'].general['treatment_drug'].list.push(push_data);
              }
            }
            this.f_paramChange();
          });
      }
    },
    f_changeDateType: function () {
      this.data.date = '';
      this.d_showDate = false;
      this.d_showEndingDate = false;
      setTimeout(function () {
        this.f_calculateDateData();
      }.bind(this), 500)
    },
    f_changeEndingDateType: function () {
      this.data.ending_date = '';
      this.d_showEndingDate = false;
      setTimeout(function () {
        this.f_calculateDateData();
      }.bind(this), 500)
    },
    f_calculateEndingDateLimit: function () {
      if ((this.data.date || this.data.date !== '') && this.option_data[this.data_type].ending_date_ability === 'yes') {
        this.d_dateAndEndingDatelimit = '';
        let date = '';
        let date_format = '';
        if (this.data.date_type === 'datetime') {
          date = this.data.date;
          date_format = "YYYY-MM-DD HH:mm";
        } else if (this.data.date_type === 'date') {
          date = this.data.date + ' 00:00';
          date_format = "YYYY-MM-DD";
        } else if (this.data.date_type === 'yearmonth') {
          date = this.data.date + '-01 00:00';
          date_format = "YYYY-MM";
        } else if (this.data.date_type === 'year') {
          date = this.data.date + '-01-01 00:00';
          date_format = "YYYY";
        }

        if (this.data.ending_date_type === 'datetime') {
          this.d_dateAndEndingDatelimit = moment(date, date_format).format("YYYY-MM-DD HH:mm");
        } else if (this.data.ending_date_type === 'date') {
          this.d_dateAndEndingDatelimit = moment(date, date_format).format("YYYY-MM-DD");
        } else if (this.data.ending_date_type === 'yearmonth') {
          this.d_dateAndEndingDatelimit = moment(date, date_format).format("YYYY-MM");
        } else if (this.data.ending_date_type === 'year') {
          this.d_dateAndEndingDatelimit = moment(date, date_format).format("YYYY");
        }
        this.$forceUpdate();
        if (date > this.data.ending_date) {
          this.data.ending_date = '';
        }
        this.d_showDate = true;
        this.d_showEndingDate = false;
        setTimeout(function () {
          this.d_showEndingDate = true;
        }.bind(this), 1000)
      } else if ((!this.data.date || this.data.date === '') && this.option_data[this.data_type].ending_date_ability === 'yes') {
        this.data.ending_date = '';
        this.d_showDate = true;
        this.d_showEndingDate = false;
        setTimeout(function () {
          this.d_showEndingDate = true;
        }.bind(this), 1000)
      } else {
        this.d_showDate = true;
      }
    },
    f_calculateEvalWdmParameters: function () {
      let return_data = ClsWdmrForever.turn(this.data, this.option_data[this.data_type], ['calculate_eval']);
      // console.log('return_data.wdmr', JSON.stringify(return_data.wdmr));
      for (let i in return_data.wdmr) {
        this.data[i] = return_data.wdmr[i];
      }
      // this.$forceUpdate();
    },
    f_loopInDrugList: function () {
      if (!this.patient) {
        return;
      }
      let prem_types = ['before', 'after', 'during'];
      if (this.data.data.general.drug_list && this.data.data.general.drug_list.list && this.data.data.general.drug_list.list.length > 0) {
        for (let drug_ind in this.data.data.general.drug_list.list) {
          let drug_data = this.data.data.general.drug_list.list[drug_ind];
          this.f_createPremDrugSelectedDaysForWdm10(drug_data);
          this.f_calculateDosage(drug_data);
          this.f_calculateFluidSpeed(drug_data);
          this.f_calculateDropSpeed(drug_data);
          if (drug_data.mix && drug_data.mix.list && drug_data.mix.list.length > 0) {
            for (let mx_ind in drug_data.mix.list) {
              let mix_drug_data = drug_data.mix.list[mx_ind];
              this.f_calculateDosage(mix_drug_data);
              this.f_calculateFluidSpeed(mix_drug_data);
              this.f_calculateDropSpeed(mix_drug_data);
            }
          }
          for (let pr_ind in prem_types) {
            let pr_type = prem_types[pr_ind];
            if (drug_data[pr_type] && drug_data[pr_type].list && drug_data[pr_type].list.length > 0) {
              for (let prem_index in drug_data[pr_type].list) {
                let prem_drug_data = drug_data[pr_type].list[prem_index];
                this.f_calculateDosage(prem_drug_data);
                this.f_calculateFluidSpeed(prem_drug_data);
                this.f_calculateDropSpeed(prem_drug_data);
                if (prem_drug_data.mix && prem_drug_data.mix.list && prem_drug_data.mix.list.length > 0) {
                  for (let mx_ind in prem_drug_data.mix.list) {
                    let mix_drug_data = prem_drug_data.mix.list[mx_ind];
                    this.f_calculateDosage(mix_drug_data);
                    this.f_calculateFluidSpeed(mix_drug_data);
                    this.f_calculateDropSpeed(mix_drug_data);
                  }
                }
              }
            }
          }
        }
      }
      this.$forceUpdate();
    },
    f_createPremDrugSelectedDaysForWdm10: function (drug_data) {
      // this function updates wdm10 main_drug_options object_list options.
      // then the user can add main_drug_selected_days
      let prem_types = ['before', 'after', 'during'];
      let main_drug_selected_days = [];
      if (drug_data.selected_days && Object.keys(drug_data.selected_days).length > 0) {
        for (let i in drug_data.selected_days) {
          main_drug_selected_days.push({ 'label': i, 'value': i });
        }
        if (main_drug_selected_days.length > 0) {
          for (let pr_ind in prem_types) {
            let pr_type = prem_types[pr_ind];
            if (this.option_data[this.data_type].general.parameters['drug_list'].parameters[pr_type].parameters['main_drug_selected_days']) {
              this.option_data[this.data_type].general.parameters['drug_list'].parameters[pr_type].parameters['main_drug_selected_days'].options = main_drug_selected_days;
            }
            // We will control the main_drug_selected_days parameter in this.data. Because when we change main drug day options, 
          }
        }
      }
    },
    f_calculateDropSpeed: function (drug_data) {
      try {
        drug_data.infusion_rate_drop_minutes = {
          'val': Math.round(((drug_data.fluid_volume.val / (drug_data.duration.val / 60)) * 33) / 100)
        };
      } catch (err) {}
    },
    f_calculateFluidSpeed: function (drug_data) {
      try {
        drug_data.infusion_rate_cc_hour = {
          'val': Math.round(drug_data.fluid_volume.val / (drug_data.duration.val / 60))
        };
      } catch (err) {}
    },
    f_calculateDosage: function (drug_data) {
      try {
        drug_data.calculated_dosage = {
          'val': DosageCalculator.calculate(drug_data.dosage.unit.value, this.patient, drug_data.dosage.val)
        };
        drug_data.planned_dosage = JSON.parse(JSON.stringify(drug_data.calculated_dosage));
      } catch (err) {}
    },
    f_ruleFlashing: function () {
      this.d_ruleAnalyzeData.class = 'rule_result_css';
      setTimeout(function () {
        this.d_ruleAnalyzeData.class = '';
      }.bind(this), 2000);

    },
    f_analyzeWdmrWithRules: function () {
      /*
      this.d_ruleAnalyzeData = {
        'last_analyzed_wdmr_data': {..},
        'dmp_list': {}
      }
      */
      if (this.d_ruleAnalyzeData.dmp_list) {
        let return_data = ClsWdmrForever.turn(this.data, this.option_data[this.data_type], []);
        let only_one_param_is_filled = return_data.only_one_param_is_filled;
        if (only_one_param_is_filled) {
          if (JSON.stringify(this.d_ruleAnalyzeData.last_analyzed_wdmr_data) !== JSON.stringify(this.data)) {
            clearTimeout(this.d_ruleAnalyzeData.timeout);
            this.d_ruleAnalyzeData.timeout = setTimeout(function () {
              let data = {
                'patient_id': this.patient.id ? this.patient.id : '',
                'wdmr_list': {},
                'dmp_list': this.d_ruleAnalyzeData.dmp_list
              };
              data.wdmr_list[this.data_type] = [this.data];
              // console.log(data);
              RuleEngineService.analyze_wdmr_list(data)
                .then(resp => {
                  // console.log(resp.data.result);
                  if (resp.data.status === 'success') {
                    this.d_ruleAnalyzeData.results = resp.data.result;
                    this.f_ruleFlashing();
                  } else {
                    alert('Error ', resp.data.message);
                  }
                })
            }.bind(this), 2000);
          }
        }
      }
    }
  },
  watch: {
    'data_type': function () {
      this.f_createWdmSettings();
    },
    'bmi_gfr_change.status': function () {
      console.log('bmi_gfr_change........');
      this.f_loopInDrugList();
      // this.mother_children_add_edit();
    },
    "param_change.wdmr_data_update": function () {
      // console.log(this.d_wdmAiWdmList[0].wdmr_data);
      for (let i in this.data) {
        this.data[i] = this.d_wdmAiWdmList[0].wdmr_data[i];
      }
      this.$forceUpdate();
    },
    "param_change.drug_from_chemotherapy": function () {
      this.f_addDrugFromChemotherapy();
      this.$forceUpdate();
    },
    "param_change.id": function () {
      // console.log('param_change ', JSON.stringify(this.param_change));
      this.f_analyzeWdmrWithRules();
      if (this.d_autoNaming) {
        let return_data = ClsWdmrForever.turn(this.data, this.option_data[this.data_type], ['name']);
        this.data.label = return_data.name;
      }
      if (!this.rule_engine && !this.polestar && this.d_wdmAiWdmList && this.d_wdmAiWdmList[0]) {
        this.d_wdmAiWdmList[0].wdmr_data = this.data;
      }
      if (this.data_type === 'wdm1') {
        this.f_calculateBmiData();
      }
      if (this.data_type === 'wdm17') {
        this.d_showModal.patient_search = false;
      }
      if (this.d_wdmrSettings.right_page_mode.page === 'wisdomdatashow') {
        this.f_showWisdomDataShowTrueFalse();
      }
      // console.log(this.param_change.param);
      if (this.data_type === 'wdm10') {
        if (this.param_change.param === 'dosage') {
          // console.log('param_change, wdm10');
          this.f_loopInDrugList();
        }
        if (this.param_change.param === 'fluid_volume') {
          // console.log('param_change, wdm10');
          this.f_loopInDrugList();
        }
        if (this.param_change.param === 'duration') {
          // console.log('param_change, wdm10');
          this.f_loopInDrugList();
        }
        if (this.param_change.type === 'olo') {
          // console.log('param_change, wdm10');
          this.f_loopInDrugList();
        }
      }
      if (this.data_type === 'wdm11') {
        if (this.param_change.param === 'dosage') {
          // console.log('param_change, wdm11');
          this.f_calculateDosage(this.data.data.general);
        }
        if (this.param_change.param === 'fluid_volume') {
          // console.log('param_change, wdm11');
          this.f_calculateFluidSpeed(this.data.data.general);
          this.f_calculateDropSpeed(this.data.data.general);
        }
        if (this.param_change.param === 'duration') {
          // console.log('param_change, wdm11');
          this.f_calculateFluidSpeed(this.data.data.general);
          this.f_calculateDropSpeed(this.data.data.general);
        }
      }

      if (this.option_data[this.data_type] && this.option_data[this.data_type].calculate_eval) {
        this.f_calculateEvalWdmParameters();
      }
      // console.log('changed in wisdom data modal');
      this.$forceUpdate();
    },
    "param_change.staging": function (id) {
      // console.log("staging changed in wisdom data modal");
      this.get_staging_details();
      this.$forceUpdate();
    },
    "param_change.toxicity": function (id) {
      // console.log("toxicity changed in wisdom data modal");
      this.get_toxicity_details();
      this.$forceUpdate();
    },
    "param_change.wdm3_group_version": function (id) {
      // console.log("toxicity changed in wisdom data modal");
      this.$forceUpdate();
    },
    "param_change.bmi": function () {
      // console.log("toxicity changed in wisdom data modal");
      this.f_calculateBmiData();
      this.$forceUpdate();
    },
    "param_change.rule": function () {
      // console.log('rule change');
      if (this.wdmr_change.rule) {
        this.wdmr_change.rule = 0;
      } else {
        this.wdmr_change.rule = 1;
      }
      this.$forceUpdate();
    },
    "param_change.forceUpdate": function () {
      this.$forceUpdate();
    },
    "data.data.general.weight": function () {
      // console.log('sdasd');
      this.f_calculateBmiData();
      this.$forceUpdate();
    },
    "data.data.general.length": function () {
      // console.log('sdasd');
      this.f_calculateBmiData();
      this.$forceUpdate();
    }
  },
  components: {
    Anatomy,
    BmiGfrWidget,
    Datepicker,
    Modal,
    ParameterDesign,
    PatientSearch,
    RuleEngine,
    vSelect,
    WdmAiRobot,
    WdmAndParameterStyle,
    WdmDesigner,
    WdmParameters,
    Wdmr,
    WisdomDataShow,
    WisdomParamCycle
  }
};

const htmlTemplate = `
<!DOCTYPE html>
<html>
<head>
  <meta charset="utf-8">
  <title></title>
  <style type="text/css">
        @page {
        size: A4;
        margin: 10mm 8mm 10mm 8mm;
      }
    @media print {
      .drug_line {
          width: 100%;
          display: inline-block;
        }
      .col-1 {width:8%;  float:left;}
        .col-2 {width:16%; float:left;}
        .col-3 {width:25%; float:left;}
        .col-4 {width:33%; float:left;}
        .col-5 {width:42%; float:left;}
        .col-6 {width:50%; float:left;}
        .col-7 {width:58%; float:left;}
        .col-8 {width:66%; float:left;}
        .col-9 {width:75%; float:left;}
        .col-10{width:83%; float:left;}
        .col-11{width:92%; float:left;}
        .col-12{width:100%; float:left;}

      html, body {
        width: 210mm;
        background: white!important;
        height: 297mm;
      }
      .page-header {
        
      }
      .page-footer {
        position: fixed;
        bottom: 0;
      }
      .page-body {
      font-size: 14px;
      width: 100%;
      height: 95%;
      }
    }
    .page-footer {
      font-size: 8px;
      position: fixed;
      bottom: 0;
    }
    .text-right {
      text-align: right;
    }
    table, td, tr {
      border: 1px solid black;
    }
    .page-body {
      font-size: 14px;
      width: 100%;
      height: 95%;
    }

    ##CSS##
  </style>

</head>
<body>
  <div class="page-header">
    ##HEADER##
  </div>
  <div class="page-body">
    ##BODY##
  </div>
  <div class="page-footer">
    ##FOOTER##
  </div>
</body>
</html>
`;

</script>

<style>
@keyframes rule_result_css_keyframe {
  from {
    background-color: #00ff85;
  }
  to {
    background-color: white;
  }
}

.rule_result_css {
  background-color: #f00000;
  transition: opacity .3s ease;
  -webkit-transition: opacity .3s ease;
  transition: all 5s;
  animation-name: rule_result_css_keyframe;
  animation-duration: 4s;
}

.rotateimg180 {
  top: 0px;
  width: 100%;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rotateimg90 {
  top: 0px;
  width: 100%;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.normal-mode {}

.full-screen-mode {
  margin: 0px;
  background-color: white;
  height: 500px;
  overflow-y: auto;
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0, 0, 0, .5);*/
  /*display: table;*/
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

</style>

