<template>
    <div>
      <div class="card">
        <div class="card-header">
          <strong style="color: #1985ac;">{{ $t('wdm16.4206') }} => </strong> {{ param_data.param_group }}
          <strong style="color: #1985ac;">{{ $t('wdm16.11229') }} => </strong> {{ param_data.param }}, 
          <strong v-if="param_data.sub_param" style="color: #1985ac;">1. {{ $t('wdm16.11291') }} => </strong> {{ param_data.sub_param }}
          <strong v-if="param_data.sub_param2" style="color: #1985ac;">2. {{ $t('wdm16.11291') }} => </strong> {{ param_data.sub_param2 }}
        </div>
        <div class="card-body">
          <div class="form-group col-12">
             <template v-if="active_param_data && active_param_data.name && active_param_data.name.label">
              <div class="card">
                <div class="card-header">
                  <strong style="color: #1985ac;">{{ $t('wdm16.1129') }}</strong>
                </div>
                <div class="card-body">
                  <table class="table-striped">
                    <thead>
                      <tr>
                        <th>label</th>
                        <th>{{ $t('wdm16.1129') }}</th>
                        <th>tr</th>
                        <th>en</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><input type="text" class="form-control" v-model="active_param_data.name.value"></td>
                        <td><input type="text" class="form-control" v-model="active_param_data.name.label"></td>
                        <td><input type="text" class="form-control" v-model="active_param_data.name.translation.tr"></td>
                        <td><input type="text" class="form-control" v-model="active_param_data.name.translation.en"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="card" v-if="select_types.indexOf(active_param_data.type) !== -1">
                <div class="card-header">
                  <strong style="color: #1985ac;">Seçenekler</strong>
                </div>
                <div class="card-body">
                  <div class="form-group row" style="border-bottom: solid 1px #1985ac;">
                    <div class="col-2"> <strong>Seçenek yöntemi</strong> </div>
                    <div class="col-10">
                      <input type="radio" v-model="option_method" value="manuel"> manuel ekleme <br>
                      <input type="radio" v-model="option_method" value="option_search"> fonksiyonla arama
                    </div>
                  </div>
                  <div class="form-group row" style="border-bottom: solid 1px #1985ac;" v-if="option_method === 'option_search'">
                    <div class="col-2"> <strong>Seçenek listesi</strong> </div>
                    <div class="col-3">
                      <select v-model="active_param_data.option_search" class="form-control" @click="$forceUpdate()">
                        <option value="">seçim yapılamadı</option>
                        <option value="rule_packages">Kural paketleri</option>
                        <option value="drugs">ilaçlar</option>
                        <option value="fluids">mayiler</option>
                        <option value="favorite_drugs">favori ilaçlar</option>
                        <option value="get_diagnosis">tanılar</option>
                        <option value="get_oncology_treatment_protocols">onkoloji tedavi protokolleri</option>
                        <option value="get_tnm_options">TNM.... evreleme seçenekleri</option>
                      </select>
                    </div>
                  </div>
                  <table class="table-striped">
                    <thead>
                      <tr style="color: #1985ac;">
                        <th>Yeni =></th>
                        <th>label</th>
                        <th>{{ $t('wdm16.11292') }}</th>
                        <th>tr</th>
                        <th>en</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <td><input type="text" class="form-control" v-model="new_option.value"></td>
                        <td><input type="text" class="form-control" v-model="new_option.label"></td>
                        <td><input type="text" class="form-control" v-model="new_option.translation.tr"></td>
                        <td><input type="text" class="form-control" v-model="new_option.translation.en"></td>
                      </tr>
                      <tr>
                        <td colspan="5">
                            <div class="pull-right">
                              <button type="button" class="btn btn-primary" @click="add_option()">yeni seçeneği ekle</button>
                            </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table class="table-striped">
                    <tbody>
                      <tr>
                        <td>
                          <div class="btn-group" role="group" style="padding-top: 8px;">
                            <button id="btnGroupDrop1" type="button" style="border-radius: 10px;" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            </button>
                              <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                <button type="button" class="dropdown-item" @click="convert_text_option_to_json('delete', 'number')">
                                  textten ekle (mevcut seçenekleri silerek, label değerleri sayı ile)
                                </button>
                                <button type="button" class="dropdown-item" @click="convert_text_option_to_json('delete', 'own_label')">
                                  textten ekle (mevcut seçenekleri silerek ve label değerlerini koruyarak)
                                </button>
                                <button type="button" class="dropdown-item" @click="convert_text_option_to_json('not_delete', 'number')">
                                  textten ekle (mevcut seçenekleri silmeden, label değerleri sayı ile)
                                </button>
                                <button type="button" class="dropdown-item" @click="convert_text_option_to_json('not_delete', 'own_label')">
                                  textten ekle (mevcut seçenekleri silmeden ve label değerlerini koruyarak)
                                </button>
                              </div> 
                          </div>
                        </td>
                        <td style="width: 20%;">parametre yazıyla ekleyerek sisteme ekleme</td>
                        <td style="width: 70%;">
                          <textarea v-model="parameter_option_text" style="width: 100%;" placeholder="Yandaki şekilde bir veri yapısı hazırladığınız takdirde mevcut seçenekleri silerek yeni seçenekler eklenecektir. Veri yapınızda tek tırnak değil çift tırnak kullanınız. [{'caption': '', 'label': '', translation: {'tr': '', 'en': ''}}]"></textarea>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="table-striped">
                    <tbody>
                      <tr>
                        <td></td>
                        <td>
                          <div class="btn-group" role="group" style="padding-top: 8px;">
                            <button id="btnGroupDrop1" type="button" style="border-radius: 10px;" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              label işlemleri
                            </button>
                              <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                <button type="button" class="dropdown-item" @click="renumerate()">
                                  görünürdeki sırayla yeniden numaralandır
                                </button>
                              </div> 
                          </div>
                          <button type="button" class="btn-success fa fa-arrow-down" @click="order_list('label')">A-Z</button>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <input type="checkbox" v-model="show_op_not_include_if" name="show_op_not_include_if"> if içermeyen seçenekleri göster
                        </td>
                        <td colspan="2">
                          <input type="text" v-model="search_option_text" name="search_option_text" class="form-control" placeholder="caption, label ve translationlar içerisinde arama yapar">
                        </td>
                        <td colspan="2">
                          <input type="text" v-model="search_option_if" name="search_option_if" class="form-control" placeholder="option if içerisinde arama yapar">
                        </td>
                      </tr>
                      <template v-for="(op, op_ind) in active_param_data.options" v-if="calculate_option_show(op_ind)">
                        <tr :style="selected_option === op_ind ? 'background-color: #7fefbf; cursor: pointer;' : 'cursor: pointer;'" @click="selected_option = op_ind; $forceUpdate();">
                          <td>
  <!--                           <div class="btn-group" role="group" style="padding-top: 8px;">
                              <button id="btnGroupDrop1" type="button" style="border-radius: 10px;" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              </button>
                                <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                  <button type="button" class="dropdown-item" @click="delete_option(op_ind)">
                                    sil
                                  </button>
                                  <button type="button" class="dropdown-item fa fa-arrow-up" @click="list_param_order(active_param_data.options, op_ind, 'up')">
                                    yukarı al
                                  </button>
                                  <button type="button" class="dropdown-item fa fa-arrow-up" @click="list_param_order(active_param_data.options, op_ind, 'down')">
                                    aşağı al
                                  </button>
                                </div> 
                            </div> -->
                            <button type="button" class="btn-primary fa fa-trash" @click="delete_option(op_ind)">
                            </button>
                            <button type="button" class="btn-primary fa fa-arrow-up" @click="list_param_order(active_param_data.options, op_ind, 'up')">
                            </button>
                            <button type="button" class="btn-primary fa fa-arrow-down" @click="list_param_order(active_param_data.options, op_ind, 'down')">
                            </button>
                            <div class="btn-group" role="group">
                              <button id="btnGroupDrop1" type="button" style="border-radius: 10px;" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              </button>
                                <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                  <button v-if="copy_option_if_data" type="button" class="dropdown-item fa fa-plus" @click="add_copied_op_if(op)">
                                    {{ copy_option_if_data }}
                                  </button>
                                </div>
                            </div>
                          </td>
                          <td><input type="text" class="form-control" v-model="op.value"></td>
                          <td><input type="text" class="form-control" v-model="op.label"></td>
                          <td><input type="text" class="form-control" v-model="op.translation.tr"></td>
                          <td><input type="text" class="form-control" v-model="op.translation.en"></td>
                        </tr>
                        <tr v-if="op.if">
                          <td colspan="5">
                            <div v-if="op.if.friend && Object.keys(op.if.friend).length > 0" class="form-group row">
                              <div class="col-6" style="color: green; font-weight: bold;">
                                Dolu olması gereken grup ve parametre
                              </div>
                              <div class="col-6" style="color: green; font-weight: bold;">
                                Dolu olması gerekli parametrenin seçenekleri ya da değeri
                              </div>
                            </div>
                             <div v-if="op.if.friend && Object.keys(op.if.friend).length > 0" v-for="(pr, pr_ind) in op.if.friend" class="form-group row">
                              <div class="col-6">
                                <div class="btn-group" role="group">
                                  <button id="btnGroupDrop1" type="button" style="border-radius: 10px;" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  </button>
                                    <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                      <button type="button" class="dropdown-item fa fa-trash" @click="$delete(op.if.friend, pr_ind); $forceUpdate()">
                                        sil
                                      </button>
                                      <button type="button" class="dropdown-item fa fa-copy" @click="copy_option_if(op.if)">
                                        kopyala
                                      </button>
                                    </div>
                                </div>
                                
                                <strong> {{ data.param_group.parameters[pr.param_group].label }} => </strong>
                                <span v-if="pr.param">
                                  {{ data[pr.param_group].parameters[pr.param].parameters[pr_ind].name.label }}
                                </span>
                                <span v-else>
                                  {{ data[pr.param_group].parameters[pr_ind].name.label }}
                                </span>
                              </div>
                              <div class="col-6">
                               <ul>
                                 <li v-for="(x, x_ind) in pr.operation">
                                  <button type="button" class="btn-primary fa fa-trash" @click="$delete(pr.operation, x_ind); $forceUpdate()"></button>
                                  <span style="color: red;"> {{ x_ind }} </span>
                                  <ul v-if="x_ind === 'and_options' || x_ind === 'or_options'">
                                    <li v-for="(op, op_ind) in x">
                                      <button type="button" class="btn-primary fa fa-trash" style="margin: 2px;" @click="x.splice(op_ind,1); $forceUpdate()"></button>
                                      {{ calculate_option_caption(op, pr_ind, pr.param_group, pr.param) }}
                                    </li>
                                  </ul>
                                 </li>
                               </ul>
                              </div>
                             </div>
                             <div class="form-group row" v-if="op.if.mother && Object.keys(param_data.mother).length > 0">
                              <div class="col-3"> 
                                <strong> Anne parametre: {{ param_data.mother.name.label }} </strong>
                              </div>
                              <div class="col-9">
                                <span v-for="(mother_op, mother_op_ind) in op.if.mother">
                                  <button type="button" class="btn btn-primary fa fa-trash" @click="$delete(op.if.mother, mother_op_ind); $forceUpdate();" style="margin: 3px;"></button>
                                  {{ mother_op.label }} <br>
                                </span>
                              </div>
                             </div>
                            <div v-if="op.if && op.if.mother_anatomy" class="form-group row" v-for="(ana, ana_ind) in op.if.mother_anatomy">
                              <div class="col-3">
                                <button type="button" class="btn btn-primary fa fa-trash" @click="op.if.mother_anatomy.splice(ana_ind, 1); $forceUpdate()"></button>
                                <strong>anne anatomi bölgesi:</strong>
                              </div>
                              <div class="col-9">
                                {{ ana.label }}                      
                              </div>
                            </div>
                            <div v-if="op.if && op.if.ana_parent" class="form-group row" v-for="(par, par_ind) in op.if.ana_parent">
                              <div class="col-3">
                                <button type="button" class="btn btn-primary fa fa-trash" @click="op.if.ana_parent.splice(par_ind, 1); $forceUpdate()"></button>
                                <strong>anatomi aile grubu:</strong>
                              </div>
                              <div class="col-9">
                                {{ par.label }}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>


             </template> 

           <template v-if="param_data.param_group === 'anatomy'">
              <anatomy :selected_anatomy_region="selected_anatomy_region"  :selected_anatomy_parent="selected_anatomy_parent"></anatomy>
              <div class="row">
                <div class="col-12">
                  <div>
                    <div class="btn-group" role="group" style="padding-top: 8px;">
                      <button id="btnGroupDrop1" type="button" style="border-radius: 10px;" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      </button>
                        <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                          <button v-if="selected_anatomy_region" type="button" class="dropdown-item fa fa-plus" @click="add_anatomy('pr')"> 
                            parametreye ekle (bölge) ({{ selected_anatomy_region.label }}) </button>
                          <button v-if="selected_anatomy_parent.value" type="button" class="dropdown-item fa fa-plus" @click="add_parent_to_parameter('pr')"> 
                            parametreye ekle (aile) ({{ selected_anatomy_parent.label }}) </button>
                          <button v-if="selected_anatomy_region.value && selected_option !== ''" type="button" class="dropdown-item fa fa-plus" @click="add_anatomy('op')"> 
                            seçeneğe ekle (bölge) ({{ selected_anatomy_region.label }}) => ({{ active_param_data.options[selected_option].label }}) </button>
                          <button v-if="selected_anatomy_parent.value && selected_option !== ''" type="button" class="dropdown-item fa fa-plus" @click="add_parent_to_parameter('op')"> 
                            seçeneğe ekle (aile) ({{ selected_anatomy_parent.label }}) => ({{ active_param_data.options[selected_option].label }}) </button>
                        </div> 
                    </div>
                    <button type="button" class="btn btn-primary" @click="$forceUpdate()">yenile</button>
                  </div>
                </div>
              </div>

              <div class="card" v-if="active_param_data && active_param_data.if && active_param_data.if.mother_anatomy && active_param_data.if.mother_anatomy.length > 0">
                <div class="card-header">
                   <strong style="color: red;"> " {{ param_data.param }} " </strong> => 
                   <strong v-if="param_data.sub_param" style="color: red;"> " {{ param_data.sub_param }} " </strong>
                   <span v-if="param_data.sub_param">alt</span>
                     parametresinin anatomik bölgeleri
                </div>
                <div class="card-body">
                  <div class="form-group row" v-for="(ana, ana_ind) in active_param_data.if.mother_anatomy">
                    <div class="col-2">
                      <button type="button" class="btn btn-primary fa fa-trash" @click="active_param_data.if.mother_anatomy.splice(ana_ind, 1); $forceUpdate()"></button>
                    </div>
                    <div class="col-10">
                      {{ ana.label }}                      
                    </div>
                  </div>
                </div>
              </div>
              <div class="card" v-if="active_param_data && active_param_data.if && active_param_data.if.ana_parent && active_param_data.if.ana_parent.length > 0">
                <div class="card-header">
                   <strong style="color: red;"> " {{ param_data.param }} " </strong> => 
                   <strong v-if="param_data.sub_param" style="color: red;"> " {{ param_data.sub_param }} " </strong>
                   <span v-if="param_data.sub_param">alt</span>
                     parametresinin anatomi aile grubu
                </div>
                <div class="card-body">
                  <div class="form-group row" v-for="(par, par_ind) in active_param_data.if.ana_parent">
                    <div class="col-2">
                      <button type="button" class="btn btn-primary fa fa-trash" @click="active_param_data.if.ana_parent.splice(par_ind, 1); $forceUpdate()"></button>
                    </div>
                    <div class="col-10">
                      {{ par.label }}
                    </div>
                  </div>
                </div>
              </div>
           </template>

           <div class="card">
             <div class="card-header">
               <strong style="color: red;"> " {{ param_data.param }} " </strong> => 
               <strong v-if="param_data.sub_param" style="color: red;"> " {{ param_data.sub_param }} " </strong>
               <span v-if="param_data.sub_param"> alt </span>
                 parametresinin diğer parametrelerle ilişki durumunu belirle
             </div>
             <div class="card-body">
              <template v-if="active_param_data && active_param_data.if">
                <div class="form-group row">
                  <div class="col-6" style="color: green; font-weight: bold;">
                    Dolu olması gereken grup ve parametre
                  </div>
                  <div class="col-6" style="color: green; font-weight: bold;">
                    Dolu olması gerekli parametrenin seçenekleri ya da değeri
                  </div>
                </div>
                 <div v-for="(pr, pr_ind) in active_param_data.if.friend" class="form-group row">
                  <div class="col-6"> 
                    <button type="button" class="btn btn-primary fa fa-trash" @click="$delete(active_param_data.if.friend, pr_ind); $forceUpdate()"></button>
                    <strong> {{ data.param_group.parameters[pr.param_group].label }} => </strong>
                    <span v-if="pr.param">
                      {{ data[pr.param_group].parameters[pr.param].parameters[pr_ind].name.label }}
                    </span>
                    <span v-else>
                      {{ data[pr.param_group].parameters[pr_ind].name.label }}
                    </span>
                  </div>
                  <div class="col-6">
                   <ul>
                     <li v-for="(x, x_ind) in pr.operation">
                      <button type="button" class="btn-primary fa fa-trash" @click="$delete(pr.operation, x_ind); $forceUpdate()"></button>
                      <span style="color: red;"> {{ x_ind }} </span>
                      <ul v-if="x_ind === 'and_options' || x_ind === 'or_options'">
                        <li v-for="(op, op_ind) in x">
                          <button type="button" class="btn-primary fa fa-trash" style="margin: 2px;" @click="x.splice(op_ind,1); $forceUpdate()"></button>
                          {{ calculate_option_caption(op, pr_ind, pr.param_group, pr.param) }}
                        </li>
                      </ul>
                     </li>
                   </ul>
                  </div>
                 </div>
                 <div class="form-group row" v-if="Object.keys(param_data.mother).length > 0">
                  <div class="col-4"> 
                    <strong> Anne parametre: {{ param_data.mother.label }} </strong>
                  </div>
                  <div class="col-8">
                    <span v-for="(mother_op, mother_op_ind) in active_param_data.if.mother">
                      <button type="button" class="btn btn-primary fa fa-trash" @click="$delete(active_param_data.if.mother, mother_op_ind); $forceUpdate();" style="margin: 3px;"></button>
                      {{ mother_op.label }} <br>
                    </span>
                  </div>
                 </div>
              </template>
             </div>
           </div>

          <div v-if="param_data.mother && param_data.mother.name" class="card">
            <div class="card-header">
                 <strong>anne parametre değeri</strong>
            </div>
            <div class="card-body">
              <div class="form-group row">
                <div class="col-2">
                  {{ param_data.mother.name.label }}
                </div>
                <div class="col-8">
                  <v-select v-model="mother_selection" :options="param_data.mother.options"></v-select>
                </div>
                <div class="col-2">
                    <div class="btn-group" role="group" style="padding-top: 8px;">
                      <button id="btnGroupDrop1" type="button" style="border-radius: 10px;" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                         <!-- işlemler -->
                      </button>
                        <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                          <button type="button" class="dropdown-item fa fa-plus" @click="add_mother_rule()">
                            anne parametre seçeneği ekle
                          </button>
                        </div>                    
                    </div>
                </div>
              </div>
            </div>
          </div>

<!-- active_param_order active_param_order active_param_order active_param_order active_param_order -->
          <div v-if="!show_statement_parameter_options" class="card" id="accordion" role="tablist" aria-multiselectable="true"> <div class="card-header" data-toggle="collapse" data-parent="#accordion" href="#other_parameters" aria-expanded="true" style="cursor: pointer;">
             <i class="fa fa-hand-o-up"></i>
             <strong> işlem yapılan parametrenin içinde bulunduğu aktif parametre grubu </strong>
               ( <span v-if="param_data.sub_param"> " {{ param_data.param }} " </span>
                <span v-else> " {{ param_data.param_group }} " </span> )
            </div>
            <div id="other_parameters" class="collapse card-body">
              <div class="form-group row" v-for="(param, param_ind) in active_param_order" v-if="param !== param_data.sub_param"  style="border-bottom: solid 1px #3fa4da;">
                  <div class="col-2">
                      <strong> {{ active_parameters[param].name.label }} </strong>
                  </div>
                  <div class="col-2">  {{ active_parameters[param].type }} </div>
                  <div class="col-6">
                    <div class="form-group row">
                      <div class="col-12">
                          <v-select  v-if="select_types.indexOf(active_parameters[param].type) !== -1" :name="param" :ref="param" :options="active_parameters[param].options"></v-select>
                          <input  v-if="active_parameters[param].type === 'integer'" class="form-control" type="number" :name="param">
                          <textarea  v-if="active_parameters[param].type === 'textarea'" style="width: 100%;"></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="btn-group" role="group" style="padding-top: 8px;">
                      <button id="btnGroupDrop1" type="button" style="border-radius: 10px;" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                         <!-- işlemler -->
                      </button>
                        <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                          <button type="button" class="dropdown-item fa fa-plus" @click="add_statement_param(param, 'pr')">
                            parametreyi ekle (parametreye)
                          </button>
                          <button v-if="selected_option" type="button" class="dropdown-item fa fa-plus" @click="add_statement_param(param, 'op')">
                            parametreyi ekle (seçeneğe)
                          </button>
                          <button v-if="select_types.indexOf(active_parameters[param].type) !== -1" type="button" class="dropdown-item fa fa-edit" @click="select_statement_parameter(param)">
                            seçeneklerden ekleme yap
                          </button>
                        </div>                    
                    </div>
                  </div>

              </div>
            </div>
          </div>
          <div class="card" v-if="show_statement_parameter_options">
            <div class="card-header">
              <strong> {{ active_parameters[selected_statement_parameter].name.label }} </strong>
             <button type="button" class="btn-primary fa fa-edit" @click="add_statement('or_options', 'pr')">
                "Veya" durumuna ekle (parametreye)
              </button>
             <button type="button" class="btn-primary fa fa-edit" @click="add_statement('or_options', 'op')">
                "Veya" durumuna ekle (seçeneğe)
              </button>
              <button type="button" class="btn-primary fa fa-undo" @click="show_statement_parameter_options = false">
                Parametre listesine dön
              </button>
            </div>
            <div class="card-body">
              <div class="form-group row">
                <div class="col-12">Seçenekler</div>
              </div>
              <div class="form-group row" v-for="(op, op_ind) in active_parameters[selected_statement_parameter].options">
                <div class="col-12" @click="state_param_list.indexOf(op.value) === -1 ? state_param_list.push(op.value) : state_param_list.splice(state_param_list.indexOf(op.value), 1)" :style="state_param_list.indexOf(op.value) === -1 ? 'background-color: white; cursor: pointer;' : 'background-color: #20a8d8; cursor: pointer;'">
                  {{ op.label }}
                </div>
              </div>
            </div>
          </div>

<!-- group group group  group group group  group group group  group group group  group group group  -->
          <template v-if="!show_statement_other_group_parameter_options">
            <div class="card" id="accordion" role="tablist" aria-multiselectable="true" v-for="(group, group_ind) in data.param_group.param_order">
              <div class="card-header" data-toggle="collapse" data-parent="#accordion" :href="'#option' + group" aria-expanded="true" style="cursor: pointer;">
               <i class="fa fa-hand-o-up"></i>
               <strong> {{ data.param_group.parameters[group].label }} </strong>
              </div>
              <div :id="'option' + group" class="collapse card-body">
                <template v-for="(param, param_ind) in data[group].param_order">
                  <div class="form-group row" style="border-bottom: solid 1px #3fa4da;">
                    <div class="col-2">
                      <strong> {{ data[group].parameters[param].name.label }} </strong>
                    </div>
                    <div class="col-2">  {{ data[group].parameters[param].type }} </div>
                    <div class="col-6">
                      <div class="form-group row">
                        <div class="col-12">
                            <v-select v-if="select_types.indexOf(data[group].parameters[param].type) !== -1" :options="data[group].parameters[param].options"></v-select>
                            <input v-if="data[group].parameters[param].type === 'integer'" class="form-control" type="number">
                            <textarea v-if="data[group].parameters[param].type === 'textarea'" style="width: 100%;"></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="btn-group" role="group" style="padding-top: 8px;">
                        <button id="btnGroupDrop1" type="button" style="border-radius: 10px;" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                           <!-- işlemler -->
                        </button>
                          <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                            <button type="button" class="dropdown-item fa fa-plus" @click="add_statement_group_param(group, param, '', 'pr')">
                              parametre ekle (parametreye)
                            </button>
                            <button type="button" class="dropdown-item fa fa-plus" @click="add_statement_group_param(group, param, '', 'op')">
                              parametre ekle (seçeneğe)
                            </button>
                            <button v-if="select_types.indexOf(data[group].parameters[param].type) !== -1" type="button" class="dropdown-item fa fa-edit" @click="select_statement_group_parameter(group, param)">
                              seçeneklerden ekleme yap
                            </button>
                          </div>                    
                      </div>
                    </div>
                  </div>
<!-- subparam subparam subparam subparam subparam subparam subparam subparam subparam subparam subparam subparam  -->
<!--                   <template v-if="data[group].parameters[param].param_order">
                    <div class="card-header">
                      {{ data[group].parameters[param].label }} alt parametreleri
                    </div>
                    <div class="form-group row" style="border-bottom: solid 1px #3fa4da;" v-for="(sub_param, sub_param_ind) in data[group].parameters[param].param_order">
                      <div class="col-2">
                        <strong> {{ data[group].parameters[param].parameters[sub_param].label }} </strong>
                      </div>
                      <div class="col-2">  {{ data[group].parameters[param].parameters[sub_param].type }} </div>
                      <div class="col-6">
                        <div class="form-group row">
                          <div class="col-12">
                              <v-select v-if="select_types.indexOf(data[group].parameters[param].parameters[sub_param].type) !== -1" :options="data[group].parameters[param].parameters[sub_param].options"></v-select>
                              <input v-if="data[group].parameters[param].parameters[sub_param].type === 'integer'" class="form-control" type="number">
                              <textarea v-if="data[group].parameters[param].parameters[sub_param].type === 'textarea'" style="width: 100%;"></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="btn-group" role="group" style="padding-top: 8px;">
                          <button id="btnGroupDrop1" type="button" style="border-radius: 10px;" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          </button>
                            <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                              <button type="button" class="dropdown-item fa fa-plus" @click="add_statement_group_param(group, param, sub_param)">
                                parametreyi ekle
                              </button>
                              <button v-if="select_types.indexOf(data[group].parameters[param].parameters[sub_param].type) !== -1" type="button" class="dropdown-item fa fa-edit" @click="select_statement_group_parameter(group, param, sub_param)">
                                seçeneklerden ekleme yap
                              </button>
                            </div>                    
                        </div>
                      </div>
                    </div>
                  </template> -->
                </template>
              </div>
            </div>
          </template>

          <div class="card" v-if="show_statement_other_group_parameter_options">
            <div class="card-header">

              <strong v-if="!selected_statement_sub_parameter"> {{ data[selected_statement_param_group].parameters[selected_statement_parameter].name.label }} </strong>
              <strong v-else> {{ data[selected_statement_param_group].parameters[selected_statement_parameter].parameters[selected_statement_sub_parameter].name.label }} </strong>
              <button type="button" class="btn-primary fa fa-edit" @click="add_statement_from_group('or_options')" title="Bu seçeneklerden en az birisi seçili olmalıdır anlamına gelmektedir.">
                "Veya" durumuna ekle
              </button>
              <button type="button" class="btn-primary fa fa-undo" @click="show_statement_other_group_parameter_options = false">
                Parametre listesine dön
              </button>
<!--               <div class="btn-group" role="group" style="padding-top: 8px;">
                <button id="btnGroupDrop1" type="button" style="border-radius: 10px;" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                </button>
                  <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                    <button type="button" class="dropdown-item fa fa-plus" @click="add_statement_from_group('and_options')">
                      "Ve" durumuna ekle (Bu seçeneklerin hepsi seçili olmalı)
                    </button>

                  </div>                    
              </div> -->
            </div>
            <div class="card-body">
              <div class="form-group row">
                <div class="col-12">Seçenekler</div>
              </div>
              <template v-if="!selected_statement_sub_parameter">
                <div class="form-group row" v-for="(op, op_ind) in data[selected_statement_param_group].parameters[selected_statement_parameter].options">
                  <div class="col-12" @click="state_param_list.indexOf(op.value) === -1 ? state_param_list.push(op.value) : state_param_list.splice(state_param_list.indexOf(op.value), 1)" :style="state_param_list.indexOf(op.value) === -1 ? 'background-color: white; cursor: pointer;' : 'background-color: #20a8d8; cursor: pointer;'">
                    {{ op.label }}
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="form-group row" v-for="(op, op_ind) in data[selected_statement_param_group].parameters[selected_statement_parameter].parameters[selected_statement_sub_parameter].options">
                  <div class="col-12" @click="state_param_list.indexOf(op.value) === -1 ? state_param_list.push(op.value) : state_param_list.splice(state_param_list.indexOf(op.value), 1)" :style="state_param_list.indexOf(op.value) === -1 ? 'background-color: white; cursor: pointer;' : 'background-color: #20a8d8; cursor: pointer;'">
                    {{ op.label }}
                  </div>
                </div>
              </template>
            </div>
          </div>

          <div class="card" id="accordion" role="tablist" aria-multiselectable="true">
            <div class="card-header" data-toggle="collapse" data-parent="#accordion" href="#rule_details" aria-expanded="true" style="cursor: pointer;">
              <div class="btn-group" role="group" style="padding-top: 8px;">
                <button id="btnGroupDrop1" type="button" style="border-radius: 10px;" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                </button>
                  <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                    <button v-if="!active_param_data.rule && ['integer', 'float', 'date', 'select', 'object_list'].indexOf(active_param_data.type) !== -1" type="button" class="dropdown-item" @click="add_rule_feature()">
                      kural yapısı ekle
                    </button>
                    <button v-else type="button" class="dropdown-item" @click="$delete(active_param_data, 'rule'); $forceUpdate()">
                      kural yapısını sil
                    </button>
                  </div> 
              </div>
              Kural detayları <span v-if="!active_param_data.rule"> (Rule yapısı bulunmuyor. Butondan ekleyebilirsiniz) </span>
            </div>
            <div id="rule_details" class="card-body collapse" v-if="active_param_data && active_param_data.rule">
              <div class="form-group row">
                <div class="col-3">
                  <strong>{{ $t('wdm16.12805') }}</strong>
                </div>
                <div class="col-9">
                  <select class="form-control" v-model="active_param_data.rule.buck">
                    <option value="wisdom">wisdom</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-3">
                  <strong>{{ $t('wdm16.12806') }}</strong>
                </div>
                <div class="col-9">
                  {{ active_param_data.rule.type }}
                  <!-- <input class="form-control" type="text" name="bucket_type" v-model="active_param_data.rule.type"> -->
<!--                   <select class="form-control" v-model="active_param_data.rule.type">
                    <option value="status">status</option>
                  </select>
 -->                
                </div>
              </div>
              <div class="form-group row">
                <div class="col-3">
                  <strong>{{ $t('wdm16.12807') }}</strong>
                </div>
                <div class="col-9">
                  <select class="form-control" v-model="active_param_data.rule.record_type">
                    <option value="many">{{ $t('wdm16.12808') }}</option>
                    <option value="only_one">{{ $t('wdm16.12809') }}</option>
                  </select>
                </div>
              </div>
<!--               <div class="form-group row">
                <div class="col-3">
                  <strong>uygun operatör ekle</strong>
                </div>
                <div class="col-8">
                  <select class="form-control" v-model="selected_operator">
                    <option v-for="(op, op_ind) in operators" :value="op"> {{ op }}  </option>
                  </select>
                </div>
                <div class="col-1">
                  <button type="button" class="btn btn-primary fa fa-plus" @click="active_param_data.rule.operators.indexOf(selected_operator) === -1 ? active_param_data.rule.operators.push(selected_operator) : ''"></button>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-3">
                  <strong>ekli operatörler</strong>
                </div>
                <div class="col-9">
                  <div v-for="(op, op_ind) in active_param_data.rule.operators">
                    <button style="margin: 3px;" type="button" class="btn btn-primary fa fa-trash" @click="active_param_data.rule.operators.splice(op_ind, 1)"></button>
                    {{ op }}
                  </div>
                </div>
              </div> -->
              <div class="form-group row">
                <div class="col-3">
                  <strong> {{ $t('wdm16.12810') }} </strong>
                </div>
                <div class="col-9">
                  <select class="form-control" v-model="active_param_data.rule.op">
                    <option v-for="(op, op_ind) in active_param_data.rule.operators" :value="op"> {{ op }}  </option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-3">
                  <strong>{{ $t('wdm16.12811') }}</strong>
                </div>
                <div class="col-8">
                  <select class="form-control" v-model="selected_data_type">
                    <option value="requesting_data">{{ $t('wdm16.12812') }}</option>
                    <option value="old_recorded_data">{{ $t('wdm16.12813') }}</option>
                  </select>
                </div>
                <div class="col-1">
                  <button type="button" class="btn btn-primary fa fa-plus" @click="active_param_data.rule.data_types.indexOf(selected_data_type) === -1 ? active_param_data.rule.data_types.push(selected_data_type) : ''"></button>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-3">
                  <strong>{{ $t('wdm16.12814') }}</strong>
                </div>
                <div class="col-9">
                  <div v-for="(op, op_ind) in active_param_data.rule.data_types">
                    <button style="margin: 3px;" type="button" class="btn btn-primary fa fa-trash" @click="active_param_data.rule.data_types.splice(op_ind, 1)"></button>
                    {{ op }}
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-3">
                  <strong> veri tipi (default)  </strong>
                </div>
                <div class="col-9">
                  <select class="form-control" v-model="active_param_data.rule.data_type">
                    <option value="requesting_data">{{ $t('wdm16.12812') }}</option>
                    <option value="old_recorded_data">{{ $t('wdm16.12813') }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-3">
                  <strong> {{ $t('wdm16.12818') }} </strong>
                </div>
                <div class="col-9">
                  <select class="form-control" v-model="active_param_data.rule.time_of_value">
                    <option value="last_value">{{ $t('wdm16.12819') }}</option>
                    <option value="last_days">{{ $t('wdm16.12820') }}</option>
                    <option value="any_value">{{ $t('wdm16.12821') }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-3">
                  <strong> {{ $t('wdm16.12822') }} </strong>
                </div>
                <div class="col-9">
                  <select class="form-control" v-model="active_param_data.rule.date">
                    <option value="1">{{ $t('wdm16.12823') }}</option>
                    <option value="0">{{ $t('wdm16.12824') }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-3">
                  <strong> {{ $t('wdm16.12825') }} </strong>
                </div>
                <div class="col-9">
                  <select class="form-control" v-model="active_param_data.rule.requesting_operation">
                    <option value="wisdom_data_save">{{ $t('wdm16.12826') }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-3">
                  <strong> {{ $t('wdm16.12827') }} </strong>
                </div>
                <div class="col-9">
                  <select class="form-control" v-model="active_param_data.rule.significant">
                    <option value="true">{{ $t('wdm16.12828') }}</option>
                    <option value="false">{{ $t('wdm16.12829') }}</option>
                  </select>
                </div>
              </div>

            </div>
          </div>

          </div>
        </div>
      </div>

    </div>
</template>

<script>
import vSelect from 'vue-select';
import {default as Anatomy} from './Anatomy';
import { mapGetters } from 'vuex';
// import OptionService from '@/services/option';
// import moment from 'moment';

export default {
  name: 'OptionEdit',
  props: {
    data: {
      type: Object,
      required: false
    },
    param_data: {
      type: Object,
      required: false
    }
  },
  mounted () {
    this.user = JSON.parse(localStorage.getItem('user'));
    let data = {};
    let pr = '';
    if (!this.param_data.sub_param && !this.param_data.sub_param2) {
      data = this.data[this.param_data.param_group];
      pr = this.param_data.param;
    } else if (this.param_data.sub_param && !this.param_data.sub_param2) {
      data = this.data[this.param_data.param_group].parameters[this.param_data.param];
      pr = this.param_data.sub_param;
    } else if (this.param_data.sub_param && this.param_data.sub_param2) {
      data = this.data[this.param_data.param_group].parameters[this.param_data.param].parameters[this.param_data.sub_param];
      pr = this.param_data.sub_param2;
    }
    this.active_param_order = data.param_order;
    this.active_parameters = data.parameters;
    this.active_param_data = data.parameters[pr];
    if (this.active_param_data.option_search) {
      this.option_method = 'option_search';
    }
    this.parameter_option_text = JSON.stringify(this.active_param_data.options);
  },
  computed: mapGetters({
    lang: 'lang'
  }),
  data () {
    return {
      selected_option: '',
      selected_anatomy_parent: {},
      selected_anatomy_region: {},
      parameter_option_text: '',
      selected_operator: '',
      selected_data_type: '',
      operators: ['=', '<>', '>', '<', '>=', '<='],
      option_method: 'manuel',
      mother_selection: '',
      active_param_order: [],
      active_parameters: {},
      active_param_data: {},
      select_types: ['select', 'object_list', 'create_new_param', 'create_for_new_param'],
      state_param_list: [],
      selected_statement_parameter: '',
      selected_statement_sub_parameter: '',
      selected_statement_param_group: '',
      show_statement_parameter_options: false,
      show_statement_other_group_parameter_options: false,
      user: {},
      new_option: {label: '', value: '', translation: {tr: '', en: ''}},
      copy_option_if_data: {},
      show_op_not_include_if: false,
      search_option_text: '',
      search_option_if: ''
    };
  },
  methods: {
    calculate_option_show (op_ind) {
      let data = this.active_param_data.options[op_ind];
      if (this.show_op_not_include_if) {
        if (!data.op_if) {
          return false;
        } else {
          if (this.search_option_text) {
            if ((data.value + data.label + data.translation.tr + data.translation.en).toLowerCase().indexOf(this.search_option_text) === -1) {
              return false;
            }
          }
          if (this.search_option_if) {
            if (data.if && data.if.friend) {
              let det = false;
              for (let i in data.if.friend) {
                for (let k in data.if.friend[i].operation.or_options) {
                  if (data.if.friend[i].operation.or_options[k].toLowerCase().indexOf(this.search_option_if.toLowerCase()) !== -1) {
                    det = true;
                    break;
                  }
                }
                if (det) { break; }
              }
              if (!det) { return false; }
            }
          }
        }
      } else {
        if (this.search_option_text) {
          if ((data.value + data.label + data.translation.tr + data.translation.en).toLowerCase().indexOf(this.search_option_text) === -1) {
            return false;
          }
        }
        if (this.search_option_if) {
          if (data.if && data.if.friend) {
            let det = false;
            for (let i in data.if.friend) {
              for (let k in data.if.friend[i].operation.or_options) {
                if (data.if.friend[i].operation.or_options[k].toLowerCase().indexOf(this.search_option_if.toLowerCase()) !== -1) {
                  det = true;
                  break;
                }
              }
              if (det) { break; }
            }
            if (!det) { return false; }
          }
        }
      }
      return true;
    },
    copy_option_if (op_if) {
      this.copy_option_if_data = JSON.parse(JSON.stringify(op_if));
      this.$forceUpdate();
    },
    add_copied_op_if: function (op_data) {
      op_data.if = JSON.parse(JSON.stringify(this.copy_option_if_data));
      this.$forceUpdate();
    },
    order_list: function (x) {
      let list = [];
      for (let i in this.active_param_data.options) {
        if (list.indexOf(this.active_param_data.options[i][x]) === -1) {
          list.push(this.active_param_data.options[i][x]);
        }
      }
      list.sort();
      // console.log(list);
      let new_list = [];
      for (let i in list) {
        for (let op in this.active_param_data.options) {
          if (list[i] === this.active_param_data.options[op][x]) {
            new_list.push(this.active_param_data.options[op]);
          }
        }
      }
      this.active_param_data.options = new_list;
      this.$forceUpdate();
    },
    calculate_option_caption (option, related_param_ind, group, parameter) {
      if (parameter !== undefined) {
        if (this.data[group].parameters[parameter].parameters[related_param_ind].options) {
          for (let i in this.data[group].parameters[parameter].parameters[related_param_ind].options) {
            if (this.data[group].parameters[parameter].parameters[related_param_ind].options[i].value === option) {
              return this.data[group].parameters[parameter].parameters[related_param_ind].options[i].label;
            }
          }
        }
      } else {
        if (this.data[group].parameters[related_param_ind].options) {
          for (let i in this.data[group].parameters[related_param_ind].options) {
            if (this.data[group].parameters[related_param_ind].options[i].value === option) {
              return this.data[group].parameters[related_param_ind].options[i].label;
            }
          }
        }
      }
    },
    add_rule_feature: function () {
      let rule_data = {
        'buck': 'wisdom',
        'type': '',
        'time_of_value': 'any_value',
        'data_type': 'old_recorded_data',
        'data_types': ['requesting_data', 'old_recorded_data'],
        'record_type': 'many',
        'operators': [],
        'val_list': [],
        'date': '1',
        'requesting_operation': 'wisdom_data_save',
        'significant': 'true',
        'op': '='
      };
      rule_data.type = this.data.parent;
      if (['select', 'object_list'].indexOf(this.active_param_data.type) !== -1) {
        rule_data.operators = ['=', '<>'];
      } else if (['integer', 'float', 'date'].indexOf(this.active_param_data.type) !== -1) {
        rule_data.operators = ['=', '<>', '>', '<', '>=', '<='];
      }
      this.active_param_data.rule = rule_data;
      this.$forceUpdate();
    },
    renumerate: function () {
      for (let i in this.active_param_data.options) {
        this.active_param_data.options[i].value = (parseInt(i) + 1).toString();
      }
    },
    convert_text_option_to_json: function (type, label_type) {
      let option_data = [];
      try {
        option_data = JSON.parse(this.parameter_option_text);
        let a = 1;
        if (type === 'delete') {
          this.active_param_data.options = [];
        } else {
          a = this.active_param_data.options.length + 1;
        }
        for (let i in option_data) {
          if (label_type === 'number') {
            option_data[i].value = (parseInt(i) + a).toString();
          }
          this.active_param_data.options.push(option_data[i]);
        }
        this.$forceUpdate();
      } catch (err) {
        alert('lütfen veri yapınızdaki hataları gideriniz. [{"caption": "", "label": "", translation: {"tr": "", "en": ""}}]');
      }
    },
    add_anatomy: function (x) {
      let data = {};
      if (x === 'pr') {
        data = this.active_param_data;
      } else if (x === 'op') {
        if (!this.active_param_data.op_if) {
          this.active_param_data.op_if = '1';
        }
        data = this.active_param_data.options[this.selected_option];
      }
      if (data.if === undefined) {
        data.if = {friend: {}, mother: {}, mother_anatomy: []};
      }
      if (!data.if.mother_anatomy) {
        data.if.mother_anatomy = [];
      }
      data.if.mother_anatomy.push(JSON.parse(JSON.stringify(this.selected_anatomy_region)));
      this.$forceUpdate();
    },
    add_parent_to_parameter: function (x) {
      let data = {};
      if (x === 'pr') {
        data = this.active_param_data;
      } else if (x === 'op') {
        if (!this.active_param_data.op_if) {
          this.active_param_data.op_if = '1';
        }
        data = this.active_param_data.options[this.selected_option];
      }
      if (data.if === undefined) {
        data.if = {friend: {}, mother: {}, mother_anatomy: []};
      }
      if (!data.mother_anatomy) {
        data.if.mother_anatomy = [];
      }
      if (!data.if.ana_parent) {
        data.if.ana_parent = [];
      }
      data.if.ana_parent.push(JSON.parse(JSON.stringify(this.selected_anatomy_parent)));
      this.$forceUpdate();
    },
    add_mother_rule: function () {
      if (this.mother_selection) {
        if (this.active_param_data.if === undefined) {
          this.active_param_data.if = {friend: {}, mother: {}};
        }
      }
      this.active_param_data.if.mother[this.mother_selection.value] = JSON.parse(JSON.stringify(this.mother_selection));
      this.$forceUpdate();
    },
    list_param_order: function (list, index, direction) {
      let index_data = list[index];
      let count = list.length;
      let friend = '';
      if (direction === 'up') {
        if (index !== 0) {
          friend = list[index - 1];
          list[index - 1] = index_data;
          list[index] = friend;
        }
      } else {
        if (index !== count - 1) {
          friend = list[index + 1];
          list[index + 1] = index_data;
          list[index] = friend;
        }
      }
      this.$forceUpdate();
    },
    delete_state_param (pr_ind) {
      this.$delete(this.active_param_data.if.friend, pr_ind);
      this.$forceUpdate();
    },
    select_statement_parameter (param) {
      this.selected_statement_parameter = param;
      this.selected_statement_param_group = this.param_data.param_group;
      this.show_statement_parameter_options = true;
    },
    select_statement_group_parameter (group, param, sub_param = '') {
      this.selected_statement_param_group = group;
      this.selected_statement_parameter = param;
      this.selected_statement_sub_parameter = sub_param;
      this.show_statement_other_group_parameter_options = true;
    },
    add_statement_from_group (state) {
      if (this.active_param_data.if === undefined) {
        this.active_param_data.if = {friend: {}, mother: ''};
      }
      if (this.active_param_data.if.friend[this.selected_statement_parameter] === undefined) {
        this.active_param_data.if.friend[this.selected_statement_parameter] = {param_group: this.selected_statement_param_group, operation: {}};
      }
      this.active_param_data.if.friend[this.selected_statement_parameter].operation[state] = JSON.parse(JSON.stringify(this.state_param_list));
      this.$forceUpdate();
    },
    add_statement_group_param (group, param, sub_param, x) {
      let data = {};
      if (x === 'pr') {
        data = this.active_param_data;
      } else if (x === 'op') {
        if (!this.active_param_data.op_if) {
          this.active_param_data.op_if = '1';
        }
        data = this.active_param_data.options[this.selected_option];
      }
      if (data.if === undefined) {
        data.if = {friend: {}, mother: ''};
      }
      if (data.if === undefined) {
        data.if = {friend: {}, mother: ''};
      }
      if (data.if === undefined) {
        data.if = {friend: {}, mother: ''};
      }
      if (sub_param) {
        if (data.if.friend[sub_param] === undefined) {
          data.if.friend[sub_param] = {param_group: group, param: param, operation: {}};
        }
      } else {
        if (data.if.friend[param] === undefined) {
          data.if.friend[param] = {param_group: group, operation: {}};
        }
      }
      this.$forceUpdate();
    },
    add_statement (state, x) {
      let data = {};
      if (x === 'pr') {
        data = this.active_param_data;
      } else if (x === 'op') {
        if (!this.active_param_data.op_if) {
          this.active_param_data.op_if = '1';
        }
        data = this.active_param_data.options[this.selected_option];
      }
      if (data.if === undefined) {
        data.if = {friend: {}, mother: ''};
      }
      if (data.if === undefined) {
        data.if = {friend: {}, mother: ''};
      }
      if (data.if.friend[this.selected_statement_parameter] === undefined) {
        if (this.param_data.sub_param) {
          data.if.friend[this.selected_statement_parameter] = {param_group: this.selected_statement_param_group, param: this.param_data.param, operation: {}, parent_param: 'yes'};
        } else {
          data.if.friend[this.selected_statement_parameter] = {param_group: this.selected_statement_param_group, operation: {}, parent_param: 'yes'};
        }
      }
      data.if.friend[this.selected_statement_parameter].operation[state] = JSON.parse(JSON.stringify(this.state_param_list));
      this.$forceUpdate();
    },
    add_statement_param (param, x) {
      let data = {};
      if (x === 'pr') {
        data = this.active_param_data;
      } else if (x === 'op') {
        if (!this.active_param_data.op_if) {
          this.active_param_data.op_if = '1';
        }
        data = this.active_param_data.options[this.selected_option];
      }
      if (data.if === undefined) {
        data.if = {friend: {}, mother: ''};
      }
      if (data.if.friend[param] === undefined) {
        if (this.param_data.sub_param) {
          data.if.friend[param] = {param_group: this.param_data.param_group, param: this.param_data.param, operation: {}, parent_param: 'yes'};
        } else {
          data.if.friend[param] = {param_group: this.param_data.param_group, operation: {}, parent_param: 'yes'};
        }
      }
      this.$forceUpdate();
    },
    relation_with_other_param (param_group, type_list, param) {
      if (type_list.indexOf(this.data[param_group].parameters[param].type) !== -1) {
        return true;
      }
    },
    add_option: function () {
      let det = false;
      for (let i in this.active_param_data.options) {
        let x = this.active_param_data.options[i];
        if (x.value === this.new_option.value || x.label === this.new_option.label || x.translation.tr === this.new_option.translation.tr || x.translation.en === this.new_option.translation.en) {
          det = true;
          break;
        }
      }
      if (!det) {
        if (!this.active_param_data.options) {
          this.active_param_data.options = [];
        }
        this.active_param_data.options.push(JSON.parse(JSON.stringify(this.new_option)));
      } else {
        alert('Bu seçenek kayıtlıdır.');
      }
      this.$forceUpdate();
    },
    delete_option: function (option_ind) {
      this.active_param_data.options.splice(option_ind, 1);
      this.$forceUpdate();
    }
  },
  watch: {
    'selected_anatomy_parent.value': function () {
      this.$forceUpdate();
    },
    'selected_anatomy_region.value': function () {
      this.$forceUpdate();
    }
  },
  components: {
    vSelect,
    Anatomy
  }
};

</script>

<style>
</style>
