<template>
  <div>
    <b-card no-body header-tag="header" :style="'margin-bottom: 2px; background-color:' + p_menuData.style.backgroundColor + '; color:' + p_menuData.style.color" key="upper_side">
      <b-tabs>
        <b-tab v-for="(menu1, menu1_ind) in p_menuData.list" :active="menu1.value === d_selectedMenu" @click="d_selectedMenu = menu1.value">
          <template slot="title">
            <img :src="menu1.icon" style="width: 1.5em;">
            <span :style="'color:' + p_menuData.style.tab_header.color">{{ menu1.label }}</span>
          </template>
          <template v-if="menu1.value === d_selectedMenu">
            <b-row style="height: 75px; margin-left: 0px;;">
              <b-col cols="12">
                <div style="float: left;">
                  <img :src="menu1.icon" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                </div>
                <template v-for="(menu2, menu2_ind) in menu1.list">
                  <div class="menuitem">
                    <template v-if="menu2.label">
                      {{ menu2.label }}
                      <br>
                    </template>
                    <template v-for="(menu3, menu3_ind) in menu2.list">
                      <div class="menusubitem">
                        <template v-if="menu3.type === 'button'">
                          <b-button :title="menu3.title" variant="white" size="sm" @click="menu3.click">
                            <img :src="menu3.icon" style="width: 2em;" /> {{ menu3.label }}
                          </b-button>
                        </template>
                        <template v-else-if="menu3.type === 'link'">
                          <b-button :title="menu3.title" variant="white" size="sm" @click="f_goToLink(menu3.link)">
                            <img :src="menu3.icon" style="width: 2em;" /> {{ menu3.label }}
                          </b-button>
                        </template>
                        <template v-else-if="menu3.type === 'buttongroup'">
                          <b-dropdown variant="white" style="padding: 0px;">
                            <template slot="button-content">
                              <img :src="menu3.icon" style="border-radius: 50%; width: 2em;" />
                              <span :style="'color:' + p_menuData.style.color">{{ menu3.label }}</span>
                            </template>
                            <template v-for="(menu4, menu4_ind) in menu3.list">
                              <template v-if="menu4.type === 'button'">
                                <b-dropdown-item @click="menu4.click">
                                  <img :src="menu4.icon" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> {{ menu4.label }}
                                </b-dropdown-item>
                              </template>
                              <template v-else-if="menu4.type === 'link'">
                                <b-dropdown-item @click="f_goToLink(menu4.link)">
                                  <img :src="menu4.icon" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> {{ menu4.label }}
                                </b-dropdown-item>
                              </template>
                            </template>
                          </b-dropdown>
                        </template>
                        <template v-else-if="menu3.type === 'color'">
                          <div style="width: 50px; float: left;" :title="menu3.title">{{ menu3.label }}</div>
                          <input style="border: 0px; padding: 0px; margin-right: 5px;" type="color" :title="menu3.title" v-model="menu3.color" @change="f_changeStyle()">
                        </template>
                      </div>
                    </template>
                  </div>
                </template>
              </b-col>
            </b-row>
          </template>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
  name: 'WTopMenu',
  computed: mapGetters({
    lang: 'lang',
    device: 'device'
  }),
  props: {
    p_menuData: {
      type: Object,
      required: true
    }
  },
  components: {},
  data () {
    return {
      d_selectedMenu: ''
    };
  },
  created: function () {
    if (this.p_menuData.selected_menu && this.p_menuData.selected_menu !== '') {
      this.d_selectedMenu = this.p_menuData.selected_menu;
    }
  },
  mounted: function () {},
  methods: {
    f_goToLink: function (uri) {
      window.open(uri, '_blank');
    },
    f_changeStyle: function () {
      return;
      setTimeout(function () {
        this.$forceUpdate();
      }.bind(this), 300)
    }
  }
};

</script>

<style type="text/css">
.menusubitem {
  float: left;
  min-width: 50px;
}

.menuitem {
  float: left;
  min-width: 75px;
  height: 100%;
  padding: 3px;
}

</style>

