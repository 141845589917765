import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  user_wdm_permission_get (data) {
    return Vue.http.post(API_BASE_URL + 'v1/user/wdm/permission/get', data);
  },
  user_wdm_permission_all_users_save (data) {
    return Vue.http.post(API_BASE_URL + 'v1/user/wdm/permission/all_users/save', data);
  },
  wdm_create (data) {
    return Vue.http.post(API_BASE_URL + 'v1/wdm/create', data);
  },
  wdm_save (data) {
    return Vue.http.post(API_BASE_URL + 'v1/wdm/save', data);
  },
  wdm_publish (data) {
    return Vue.http.post(API_BASE_URL + 'v1/wdm/publish', data);
  }
};
