<template>
  <div>
    <template v-if="selectedParamData.if">
      <b-row v-if="selectedParamData.if.friend" style="margin: 0px;">
        <b-col sm="12" md="6">
          Arkadaşlık şekli
        </b-col>
        <b-col sm="12" md="6">
          <select v-if="selectedParamData.if" v-model="selectedParamData.if.friend_type" style="padding: 3px; ">
            <option value="all_friend_match">{{ $t('wdm16.12838') }}</option>
            <option value="any_one_of_friend_match">{{ $t('wdm16.12839') }}</option>
          </select>
        </b-col>
      </b-row>
      <template v-for="(pr, pr_ind) in selectedParamData.if.friend">
        <b-card no-body class="mb-1">
          <b-card-header header-bg-variant="white" header-text-variant="dark" class="p-1">
            <b-row>
              <b-col sm="12" md="12">
                1. parametre <strong>"{{ selectedParamData.name.label }}"</strong> {{ $t('wdm16.6597') }}
              </b-col>
            </b-row>
          </b-card-header>
          <b-row v-if="pr.parent_param === 'yes'">
            <b-col sm="12" md="12" lg="4">
              <b-row>
                <b-col sm="12" md="12" lg="2" v-if="d_isThisModelEditable">
                  <b-dropdown variant="warning" size="sm">
                    <b-dropdown-item @click="$delete(selectedParamData.if.friend, pr_ind); $forceUpdate()">{{ $t('wdm16.12840') }}</b-dropdown-item>
                    <!-- <b-dropdown-item @click="copy_if_data('', 'friend', pr, pr_ind)">{{ $t('wdm16.12841') }}</b-dropdown-item> -->
                  </b-dropdown>
                </b-col>
                <b-col sm="12" md="12" lg="10">
                  <strong>{{ pr.name.label }}</strong> <small style="color: red;"> ({{ $t('wdm16.12842') }})</small>
                </b-col>
              </b-row>
            </b-col>
            <template v-if="!pr.math_operator">
              <b-col sm="12" md="12" lg="8">
                <template v-for="(x, x_ind) in pr.operation">
                  <div style="color: blue; padding-bottom: 3px;">
                    <b-dropdown v-if="d_isThisModelEditable" variant="warning" size="sm">
                      <b-dropdown-item @click="$delete(pr.operation, x_ind); $forceUpdate()">{{ $t('wdm16.6765') }}</b-dropdown-item>
                    </b-dropdown>
                    {{ x_ind }}
                  </div>
                  <div v-if="x_ind === 'and_options' || x_ind === 'or_options'" v-for="(op, op_ind) in x" :key="pr_ind + op">
                    <b-button v-if="d_isThisModelEditable" size="sm" variant="danger" @click="x.splice(op_ind, 1); $forceUpdate();"><i class="fa fa-trash"></i></b-button>
                    {{ f_getSelectedParamOptionLabel(pr, pr_ind, op) }}
                  </div>
                </template>
              </b-col>
            </template>
            <template v-else>
              <b-col cols="4">
                <b-form-group label="operator" label-for="type" :label-cols="3">
                  <b-form-select id="math_operator.operator" v-model="pr.math_operator.operator">
                    <option value=">"> {{ 'büyüktür' }} </option>
                    <option value="<"> {{ 'küçüktür' }} </option>
                    <option value="==="> {{ 'eşittir' }} </option>
                    <option value="!=="> {{ 'eşit değildir' }} </option>
                    <option value="between"> {{ 'arasındadır' }} </option>
                    <option value="not_between"> {{ 'arasında değildir' }} </option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Sayı" label-for="pr.math_operator.number_1" :label-cols="6">
                  <b-form-input type="number" step="0.01" class="form-control" v-model="pr.math_operator.number_1" name="pr.math_operator.number_1"></b-form-input>
                </b-form-group>
                <b-form-group v-if="pr.math_operator.operator === 'between' || pr.math_operator.operator === 'not_between'" label="İkinci Sayı" label-for="pr.math_operator.number_2" :label-cols="6">
                  <b-form-input type="number" step="0.01" class="form-control" v-model="pr.math_operator.number_2" name="pr.math_operator.number_2"></b-form-input>
                </b-form-group>
              </b-col>
            </template>
          </b-row>
          <b-row v-else>
            <b-col sm="12" md="12" lg="4">
              <b-row>
                <b-col sm="12" md="12" lg="2" v-if="d_isThisModelEditable">
                  <b-dropdown variant="warning" size="sm">
                    <b-dropdown-item @click="$delete(selectedParamData.if.friend, pr_ind); $forceUpdate()">{{ $t('wdm16.12840') }}</b-dropdown-item>
                    <!-- <b-dropdown-item @click="copy_if_data('', 'friend', pr, pr_ind)">{{ $t('wdm16.12841') }}</b-dropdown-item> -->
                  </b-dropdown>
                </b-col>
                <b-col sm="12" md="12" lg="10">
                  <strong>
                                    {{ pr.name.label }}
                                  </strong>
                  <small style="color: red;"> ({{ $t('wdm16.12846') }})</small>
                </b-col>
              </b-row>
            </b-col>
            <template v-if="!pr.math_operator">
              <b-col sm="12" md="12" lg="8">
                <template v-for="(x, x_ind) in pr.operation">
                  <div style="color: blue; padding-bottom: 3px;">
                    <b-dropdown v-if="d_isThisModelEditable" variant="warning" size="sm">
                      <b-dropdown-item @click="$delete(pr.operation, x_ind); $forceUpdate()">{{ $t('wdm16.6765') }}</b-dropdown-item>
                    </b-dropdown>
                    {{ x_ind }}
                  </div>
                  <div v-if="x_ind === 'and_options' || x_ind === 'or_options'" v-for="(op, op_ind) in x" :key="pr_ind + op">
                    <b-button v-if="d_isThisModelEditable" size="sm" variant="danger" @click="x.splice(op_ind, 1); $forceUpdate();"><i class="fa fa-trash"></i></b-button>
                    {{ f_getSelectedParamOptionLabel(pr, pr_ind, op) }}
                  </div>
                </template>
              </b-col>
            </template>
            <template v-else>
              <b-col sm="12" md="12" lg="8">deneme</b-col>
            </template>
          </b-row>
        </b-card>
      </template>
      <template v-if="selectedParamData.if.mother && Object.keys(selectedParamData.if.mother).length > 0">
        <b-row>
          <b-col sm="12" md="12" lg="4">
            <b-row>
              <b-col v-if="d_isThisModelEditable" sm="12" md="12" lg="2">
                <b-dropdown variant="warning" size="sm">
                  <b-dropdown-item @click="$delete(selectedParamData.if, 'mother'); $forceUpdate()"><i class="fa fa-trash"></i>{{ $t('wdm16.12847') }} </b-dropdown-item>
                </b-dropdown>
              </b-col>
              <b-col sm="12" md="12" lg="10">
                <strong>{{ MotherObjectListParam.name.label }}</strong> <small style="color: red;"> ({{ $t('wdm16.12848') }})</small>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="12" md="12" lg="8">
            <template v-for="(pr, pr_ind) in selectedParamData.if.mother">
              <div :key="pr_ind">
                <b-button v-if="d_isThisModelEditable" size="sm" variant="danger" @click="$delete(selectedParamData.if.mother, pr_ind); $forceUpdate();"><i class="fa fa-trash"></i></b-button>
                {{ pr.label }}
              </div>
            </template>
          </b-col>
        </b-row>
      </template>
    </template>
    <template v-else>
      Parametre arkadaş ilişkisi yok
    </template>
  </div>
</template>

<script>
import { ClsWdm } from '@/services/public/wdm';
import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
import {
  default as Modal
} from "@/components/widgets/Modal";
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
  name: 'ParameterFriend',
  computed: mapGetters({
    lang: 'lang',
    device: 'device'
  }),
  props: {
    d_isThisModelEditable: {
      type: Boolean,
      required: true
    },
    copy_if_data: {
      type: Function,
      required: true
    },
    f_mainParameterSelectionStyle: {
      type: Function,
      required: true
    },
    selectedParamData: {
      type: Object,
      required: true
    },
    MotherObjectListParam: {
      type: Object,
      required: true
    },
    upper_parameters: {
      type: Object,
      required: true
    },
    upper_param_order: {
      type: Array,
      required: true
    },
    option_data: {
      type: Object,
      required: true
    },
    param_change: {
      type: Object,
      required: true
    },
    data_type: {
      type: String,
      required: true
    },
    page_type: {
      type: String,
      required: true
    }
  },
  components: {
    Modal,
    WisdomDataModal
  },
  data () {
    return {};
  },
  created: function () {
    this.d_wisdomeraSystem = JSON.parse(localStorage.getItem('wisdomera_system'));
    this.w_presentation = JSON.parse(localStorage.getItem('presentation'));
    this.d_wdmr22 = JSON.parse(localStorage.getItem('wdm22'));
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  mounted: function () {},
  methods: {
    f_getSelectedParamOptionLabel: function (pr, pr_ind, op_value) {
      let x = op_value;
      if (pr.parent_param === 'yes' && this.upper_parameters[pr_ind].options) {
        for (let i in this.upper_parameters[pr_ind].options) {
          if (this.upper_parameters[pr_ind].options[i].value === op_value) {
            x = this.upper_parameters[pr_ind].options[i].label;
            break;
          }
        }
      } else if (pr.index_list && pr.index_list.length > 0) {
        let param_data = ClsWdm.getParamDataWithIndexList(this.option_data[this.data_type], pr.index_list);
        if (param_data.options) {
          for (let i in param_data.options) {
            if (param_data.options[i].value === op_value) {
              x = param_data.options[i].label;
              break;
            }
          }
        }
      }
      return x;
    }
  },
  watch: {
    'param_change.update': function () {
      this.$forceUpdate();
    }
  }
};

</script>

<style type="text/css">
.menusubitem {
  float: left;
  min-width: 50px;
}

.menuitem {
  float: left;
  min-width: 75px;
  height: 100%;
  padding: 3px;
}

</style>

