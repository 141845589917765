<!-- !!!!!!!!!!!!! RİGHT BETWEEN DİV TAGS TO TEST YOUR RENDERED TEMPLATE THAT YOU LOGGED TO CONSOLE. -->
<!-- 
<template>
  <div></div>
</template>
 -->

<script>
import { ClsWdm } from '@/services/public/wdm';
import Vue from 'vue';
import { mapGetters } from 'vuex';

var templateRenderFns = [];
export default {
  name: 'WdmParameters',
  render (h) {
    return h('div', [
      (this.template ? this.template() : '')
    ]);
  },
  staticRenderFns: templateRenderFns,
  components: {
    Modal: () =>
      import ('@/components/widgets/Modal'),
  },
  props: {
    selection: {
      type: Object,
      required: false
    },
    option_data: {
      type: Object,
      required: true
    },
    data_type: {
      type: String,
      required: true
    },
    search_in_first_parameter_list: {
      type: String,
      required: true
    },
    show_parameter_features: {
      type: String,
      required: true
    },
    d_isThisModelEditable: {
      type: Boolean,
      required: true
    },
    selected_index_data: {
      type: Object,
      required: false,
      default: () => {}
    },
    param_change: {
      type: Object,
      required: true
    },
    brief_list: {
      type: Array,
      required: false
    }
  },
  computed: mapGetters({
    lang: 'lang',
    help: 'help'
  }),
  mounted () {},
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.render_template();
  },
  data () {
    return {
      d_level: 0,
      d_levelCount: 10,
      d_hmfParamData: {},
    };
  },
  methods: {
    f_showThisChars: function (text, count=20) {
      let str = '';
      try {
        text = text.toString();
        let x = text.length > count ? count : text.length;
        for (let i = 0; i < x; i++) {
          if (text[i]) {
            str += text[i];
          } else {
            str += '.';
          }
        }
        if (text.length > count) {
          str += '...';
        }
      } catch (err) {}
      return str;
    },
    f_startDragText: function (evt, ind, from_list_info) {
      // console.log('evt ', evt);
      // console.log('com_ind ', com_ind);
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      evt.dataTransfer.setData('fromInd', ind);
      evt.dataTransfer.setData('from_list_info', from_list_info);
    },

    f_onDropCompileStrText: function (evt, toComInd, to_list_info) {
      // console.log('from_list_info', evt.dataTransfer.getData('from_list_info'));
      // console.log('to_list_info', to_list_info);
      let fromInd = parseInt(evt.dataTransfer.getData('fromInd'));
      let toInd = parseInt(toComInd);
      // console.log('100')
      if (JSON.stringify(to_list_info) === JSON.stringify(evt.dataTransfer.getData('from_list_info').split(','))) {
        // console.log('102')
        if (fromInd > toInd) {
          to_list_info.splice(toInd + 1, 0, to_list_info[fromInd]);
          to_list_info.splice(fromInd + 1, 1);
        } else {
          to_list_info.splice(toInd + 1, 0, to_list_info[fromInd]);
          to_list_info.splice(fromInd, 1);
        }
      }
      this.$forceUpdate();

      // this.f_controlCompileStrFirstError();
    },
    render_template: function () {
      this.d_level = 0; // When we call this function again, this.d_level must be 0.
      let max_deeper_value = ClsWdm.maxDeeperLevel(this.option_data[this.data_type]);
      if (max_deeper_value) {
        this.d_levelCount = max_deeper_value + 1; // to add new parameters 
      }
      // console.log('this.d_levelCount: ', this.d_levelCount);
      let html = this.build_form();
      // console.log(html);
      let compiled_template = Vue.compile(html);
      this.template = compiled_template.render;
      templateRenderFns.length = 0;
      for (var i in compiled_template.staticRenderFns) {
        templateRenderFns.push(compiled_template.staticRenderFns[i]);
      }
      this.$forceUpdate();
    },
    build_form: function () {
      // console.log('build_form');
      let html = '';
      html += '<b-row>';
      html += '   <b-col cols="12">';
      html += '      <strong>WDM ID: </strong> {{ data_type }}';
      html += '   </b-col>';
      html += '</b-row>';
      html += '<template v-if="option_data[data_type].about && option_data[data_type].about.length > 0">';
      html += '   <b-row>';
      html += '      <b-col cols="12">';
      html += '         <strong>Veri Modeli Hakkında </strong> ';
      html += '      </b-col>';
      html += '   </b-row>';
      html += '   <ul>';
      html += '      <li v-for="(about, about_ind) in option_data[data_type].about">';
      html += '         {{ about }} ';
      html += '      </li>';
      html += '   </ul>';
      html += '</template>';
      html += '<template v-for="(group, group_ind) in option_data[data_type].param_group.param_order">';
      html += '<b-row class="wdm-print-param-group-not-selected" :key="group">';
      html += '<b-col cols="12">';
      html += '<span class="wdm-print-group-level"></span> <span>{{ option_data[data_type].param_group.parameters[group].label }}</span>';
      html += '</b-col>';
      html += '</b-row>';
      html += this.main_param_func();
      html += '</template>';
      return '<div id="wdm-print" style="font-size:12px; font-family: Times New Roman, Times, serif; overflow-x: hidden; overflow-y: auto; height: 500px;">' + html + '</div>';
    },
    main_param_func: function () {
      let html = '';
      this.d_level += 1;
      let param_data = this.f_paramData();
      let param_index_list = this.f_paramIndexList();
      html += '<template v-for="(param' + this.d_level.toString() + ', param_ind' + this.d_level.toString() + ') in ' + this.f_paramorderString() + '">';
      html += '<ul>';
      html += '<li>';
      html += '<b-row>';
      html += '<b-col cols="12">';
      html += '<strong> {{ ' + param_data + '.name.label }} </strong>';
      html += '<ul>';
      html += '   <li>';
      html += '      <b-row>';
      html += '         <b-col cols="12">';
      html += '            <span>Parametre Tipi: {{ ' + param_data + '.type }}</span>';
      html += '         </b-col>';
      html += '      </b-row>';
      html += '   </li>';
      html += '   <li>';
      html += '      <b-row>';
      html += '         <b-col cols="12">';
      html += '            <span>Parametre Değeri: {{ param' + this.d_level.toString() + ' }}</span>';
      html += '         </b-col>';
      html += '      </b-row>';
      html += '   </li>';
      html += '   <li>';
      html += '      <b-row>';
      html += '         <b-col cols="12">';
      html += '            <span>Parametre Hakkında: {{ ' + param_data + '.info }}</span>';
      html += '         </b-col>';
      html += '      </b-row>';
      html += '   </li>';
      html += '   <li>';
      html += '      <b-row>';
      html += '         <b-col cols="12">';
      html += '            <span>Parametre Zorunluluğu: {{ ' + param_data + '.required ? "zorunlu" : "zorunlu değil" }}</span>';
      html += '         </b-col>';
      html += '      </b-row>';
      html += '   </li>';
      html += '   <li v-if="[\'select\', \'checkbox\', \'object_list\', \'radiogroup\'].indexOf(' + param_data + '.type) !== -1">';
      html += '      <b-row>';
      html += '         <b-col cols="12">';
      html += '            <span>Seçenekler:</span>';
      html += '            <ul>';
      html += '               <li v-for="(op, op_ind) in ' + param_data + '.options">';
      html += '                  <b-row>';
      html += '                     <b-col cols="12">';
      html += '                        <span> {{ op.label }} : {{ op.value }}</span>';
      html += '                     </b-col>';
      html += '                  </b-row>';
      html += '               </li>';
      html += '            </ul>';
      html += '         </b-col>';
      html += '      </b-row>';
      html += '   </li>';
      html += '</ul>';
      html += '</b-col>';
      html += '</b-row>';
      html += '</li>';
      html += '</ul>';
      if (this.d_level <= this.d_levelCount) {
        html += this.main_param_func();
      }
      html += '</template>';
      return html;
    },
    f_paramData: function () {
      let str = 'option_data[data_type][group]';
      for (let i = 1; i <= this.d_level; i++) {
        str += '.parameters[param' + i.toString() + ']';
      }
      return str;
    },
    f_calculateParamStyle: function (additional=0) {
      let margin_left = 20 + additional;
      margin_left *= this.d_level;
      return 'padding-left: 20px; margin-left: ' + margin_left.toString() + 'px;';
    },
    CalculateVariant: function (param_type) {
      if (['select', 'checkbox', 'radiogroup'].indexOf(param_type) !== -1) {
        return 'warning';
      } else if (['textarea', 'text', 'html_editor'].indexOf(param_type) !== -1) {
        return 'secondary';
      } else if (['integer', 'float'].indexOf(param_type) !== -1) {
        return 'primary';
      } else if (['list_parameter'].indexOf(param_type) !== -1) {
        return 'success';
      } else if (['object_list_options', 'object_list'].indexOf(param_type) !== -1) {
        return 'info';
      } else if (['date', 'time', 'datetime'].indexOf(param_type) !== -1) {
        return 'dark';
      }
    },
    list_param_order: function (list, index, direction) {
      let index_data = list[index];
      let count = list.length;
      let friend = '';
      if (direction === 'up') {
        if (index !== 0) {
          friend = list[index - 1];
          list[index - 1] = index_data;
          list[index] = friend;
        }
      } else if (direction === 'down') {
        if (index !== count - 1) {
          friend = list[index + 1];
          list[index + 1] = index_data;
          list[index] = friend;
        }
      } else if (direction === 'top') {
        list.splice(index, 1);
        list.splice(0, 0, index_data);
      } else if (direction === 'bottom') {
        list.splice(index, 1);
        list.push(index_data);
      }
      this.$forceUpdate();
    },
    f_paramIndexList: function (use_brackets = true) {
      let str = '';
      if (use_brackets) {
        str = '[group,';
      }
      for (let i = 1; i <= this.d_level; i++) {
        str += 'param' + i.toString();
        if (i !== this.d_level) {
          str += ',';
        }
      }
      if (use_brackets) {
        str += ']';
      }
      return str;
    },
    f_paramorderString: function () {
      if (this.d_level === 1) {
        return 'option_data[data_type][group].param_order';
      } else if (this.d_level > 1) {
        let str = 'option_data[data_type][group]';
        for (let i = 1; i < this.d_level; i++) {
          str += '.parameters[param' + i.toString() + ']';
        }
        return str + '.param_order';
      }
    },
    f_selectIndex: function (index_list) {
      /*
          'times': 'multi', // one, multi
          'type': 'this_index', // all, this_index, this_types
          'this_index': [
            ['article_summary', 'article_summary_purpose', 'article_summary_purpose_info'],
            ['article_summary', 'article_summary_methods', 'article_summary_methods_info'],
            ['article_summary', 'article_summary_results', 'article_summary_results_info'],
            ['article_summary', 'article_summary_conclusion', 'article_summary_conclusion_info'],
            ['article_summary', 'article_general_information', 'article_general_information_info'],
            ['article_summary', 'article_methods', 'article_methods_info'],
            ['article_summary', 'article_statistics', 'article_statistics_info'],
            ['article_summary', 'article_results', 'article_results_info'],
            ['article_summary', 'article_conclusion', 'article_conclusion_info']
          ]
      */
      // console.log(index_list);
      if (this.selection) {
        if (this.selection.times === 'one') {
          if (this.selection.type === 'all') {
            this.selected_index_data.index_list.splice(0, this.selected_index_data.index_list.length);
            this.selected_index_data.index_list = index_list;
          } else if (this.selection.type === 'this_index') {
            let det = false;
            for (let i in this.selection.this_index) {
              if (this.selection.this_index[i].join() === index_list.join()) {
                det = true;
                break;
              }
            }
            if (det) {
              this.selected_index_data.index_list.splice(0, this.selected_index_data.index_list.length);
              this.selected_index_data.index_list = index_list;
            }
          }
        } else if (this.selection.times === 'multi') {
          if (this.selection.type === 'all') {
            let ind = '';
            for (let i in this.selected_index_data.index_list) {
              if (this.selected_index_data.index_list[i].join() === index_list.join()) {
                ind = i;
                break;
              }
            }
            if (ind !== '') {
              this.selected_index_data.index_list.splice(ind, 1);
            } else {
              this.selected_index_data.index_list.push(index_list);
            }
          } else if (this.selection.type === 'this_index') {
            let det = false;
            for (let i in this.selection.this_index) {
              if (this.selection.this_index[i].join() === index_list.join()) {
                det = true;
                break;
              }
            }
            if (det) {
              let ind = '';
              for (let i in this.selected_index_data.index_list) {
                if (this.selected_index_data.index_list[i].join() === index_list.join()) {
                  ind = i;
                  break;
                }
              }
              if (ind !== '') {
                this.selected_index_data.index_list.splice(ind, 1);
              } else {
                this.selected_index_data.index_list.push(index_list);
              }
            }
          }
        }
      } else {
        this.selected_index_data.index_list = index_list;
      }
      this.$forceUpdate();
    },
    param_name_func: function (param_data) {
      param_data.naming ? this.$delete(param_data, 'naming') : param_data.naming = '1';
      this.$forceUpdate();
    },
    param_require_func: function (param_data) {
      if (param_data.required && param_data.required === 'yes') {
        param_data.required = 'no';
      } else {
        param_data.required = 'yes';
      }
      this.$forceUpdate();
    },
    param_fav_show_func: function (param_data) {
      if (param_data.fav_show && param_data.fav_show === 'yes') {
        param_data.fav_show = 'no';
      } else {
        param_data.fav_show = 'yes';
      }
      this.$forceUpdate();
    },
    param_show_func: function (param_data) {
      if (param_data.show && param_data.show === 'yes') {
        param_data.show = 'no';
      } else {
        param_data.show = 'yes';
      }
      this.$forceUpdate();
    },
    param_show_size_units_func: function (param_data) {
      param_data.show_size_units ? this.$delete(param_data, 'show_size_units') : param_data.show_size_units = '1';
      this.$forceUpdate();
    },
    param_show_weight_units_func: function (param_data) {
      param_data.show_weight_units ? this.$delete(param_data, 'show_weight_units') : param_data.show_weight_units = '1';
      this.$forceUpdate();
    },
    param_show_dosage_units_func: function (param_data) {
      param_data.show_dosage_units ? this.$delete(param_data, 'show_dosage_units') : param_data.show_dosage_units = '1';
      this.$forceUpdate();
    },
    param_not_schema_func: function (param_data) {
      param_data.not_schema ? this.$delete(param_data, 'not_schema') : param_data.not_schema = '1';
      this.$forceUpdate();
    },
    param_anonym_func: function (param_data) {
      param_data.anonym ? this.$delete(param_data, 'anonym') : param_data.anonym = '1';
      this.$forceUpdate();
    },
    param_option_search_func: function (param_data) {
      param_data.option_search ? this.$delete(param_data, 'option_search') : param_data.option_search = '';
      this.$forceUpdate();
    },
    param_page_not_show_func: function (param_data) {
      param_data.page_not_show ? this.$delete(param_data, 'page_not_show') : param_data.page_not_show = '0';
      this.$forceUpdate();
    },
  },
  watch: {
    'param_change.update': function () {
      this.$forceUpdate();
    },
    'param_change.new_parameter_added': function () {
      this.render_template();
    }
  }
};

</script>

<style>

.wdm-print-param-group-not-selected {
  font-weight: bold;
}

.wdm-print-group-level {
  padding-left: 20px;
}


</style>

