<!-- !!!!!!!!!!!!! RİGHT BETWEEN DİV TAGS TO TEST YOUR RENDERED TEMPLATE THAT YOU LOGGED TO CONSOLE. -->
<!-- 
<template>
  <div></div>
</template>
 -->

<script>
import { ClsWdm } from '@/services/public/wdm';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import {
  default as HimsMatchOperations
} from "@/components/widgets/HimsMatchOperations";

var templateRenderFns = [];
export default {
  name: 'WdmParameters',
  render (h) {
    return h('div', [
      (this.template ? this.template() : '')
    ]);
  },
  staticRenderFns: templateRenderFns,
  components: {
    HimsMatchOperations,
    Modal: () =>
      import ('@/components/widgets/Modal'),
  },
  props: {
    selection: {
      type: Object,
      required: false
    },
    option_data: {
      type: Object,
      required: true
    },
    data_type: {
      type: String,
      required: true
    },
    search_in_first_parameter_list: {
      type: String,
      required: true
    },
    show_parameter_features: {
      type: String,
      required: true
    },
    d_isThisModelEditable: {
      type: Boolean,
      required: true
    },
    selected_index_data: {
      type: Object,
      required: false,
      default: () => {}
    },
    param_change: {
      type: Object,
      required: true
    },
    brief_list: {
      type: Array,
      required: false
    }
  },
  computed: mapGetters({
    lang: 'lang',
    help: 'help'
  }),
  mounted () {},
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.render_template();
  },
  data () {
    return {
      d_level: 0,
      d_levelCount: 10,
      d_showHimsMatchOperationsModal: false,
      d_hmfParamData: {},
      d_hmfHimsData: {}
    };
  },
  methods: {
    f_showThisChars: function (text, count=20) {
      let str = '';
      try {
        text = text.toString();
        let x = text.length > count ? count : text.length;
        for (let i = 0; i < x; i++) {
          if (text[i]) {
            str += text[i];
          } else {
            str += '.';
          }
        }
        if (text.length > count) {
          str += '...';
        }
      } catch (err) {}
      return str;
    },
    f_startDragText: function (evt, ind, from_list_info) {
      // console.log('evt ', evt);
      // console.log('com_ind ', com_ind);
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      evt.dataTransfer.setData('fromInd', ind);
      evt.dataTransfer.setData('from_list_info', from_list_info);
    },

    f_onDropCompileStrText: function (evt, toComInd, to_list_info) {
      // console.log('from_list_info', evt.dataTransfer.getData('from_list_info'));
      // console.log('to_list_info', to_list_info);
      let fromInd = parseInt(evt.dataTransfer.getData('fromInd'));
      let toInd = parseInt(toComInd);
      // console.log('100')
      if (JSON.stringify(to_list_info) === JSON.stringify(evt.dataTransfer.getData('from_list_info').split(','))) {
        // console.log('102')
        if (fromInd > toInd) {
          to_list_info.splice(toInd + 1, 0, to_list_info[fromInd]);
          to_list_info.splice(fromInd + 1, 1);
        } else {
          to_list_info.splice(toInd + 1, 0, to_list_info[fromInd]);
          to_list_info.splice(fromInd, 1);
        }
      }
      this.$forceUpdate();

      // this.f_controlCompileStrFirstError();
    },
    render_template: function () {
      this.d_level = 0; // When we call this function again, this.d_level must be 0.
      let max_deeper_value = ClsWdm.maxDeeperLevel(this.option_data[this.data_type]);
      if (max_deeper_value) {
        this.d_levelCount = max_deeper_value + 1; // to add new parameters 
      }
      // console.log('this.d_levelCount: ', this.d_levelCount);
      let html = this.build_form();
      // console.log(html);
      let compiled_template = Vue.compile(html);
      this.template = compiled_template.render;
      templateRenderFns.length = 0;
      for (var i in compiled_template.staticRenderFns) {
        templateRenderFns.push(compiled_template.staticRenderFns[i]);
      }
      this.$forceUpdate();
    },
    build_form: function () {
      // console.log('build_form');
      let html = '';
      html += '<template v-for="(group, group_ind) in option_data[data_type].param_group.param_order">';
      html += '<b-row :class="[f_isThisSelected([group]) ? \'param-group-selected\' : \'param-group-not-selected\']" @click="f_selectIndex([group])" :key="group">';
      html += '<b-col cols="12">';
      html += '<span class="group-level"></span> <span :title="option_data[data_type].param_group.parameters[group].label">{{ f_showThisChars(option_data[data_type].param_group.parameters[group].label) }}</span>';
      html += '<b-badge variant="success"> grup </b-badge>';
      html += '</b-col>';
      html += '</b-row>';
      html += this.main_param_func();
      html += '</template>';
      html += '<modal v-if="d_showHimsMatchOperationsModal" @close="d_showHimsMatchOperationsModal = false" :width="\'1000\'">';
      html += '<h3 slot="header"> {{ $t(\'wdm16.13175\') }}</h3>';
      html += '<div slot="body">';
      html += '<hims-match-operations :p_paramData="d_hmfParamData" :p_himsData="d_hmfHimsData"></hims-match-operations>';
      html += '</div>';
      html += '<div slot="footer">';
      html += '<b-button variant="success" @click="d_showHimsMatchOperationsModal = false">{{ $t(\'wdm16.4053\') }}</b-button>';
      html += '</div>';
      html += '</modal>';
      return '<div>' + html + '</div>';
    },
    main_param_func: function () {
      let html = '';
      this.d_level += 1;
      let param_data = this.f_paramData();
      let param_index_list = this.f_paramIndexList();
      html += '<template v-for="(param' + this.d_level.toString() + ', param_ind' + this.d_level.toString() + ') in ' + this.f_paramorderString() + '">';
      html += '<b-row :class="[f_isThisSelected(' + param_index_list + ') ? \'param-selected-1\' : \'param-not-selected-1\']" @click="f_selectIndex(' + param_index_list + ')" v-if="' + param_data + '.name.label.toLocaleLowerCase(\'tr\').indexOf(search_in_first_parameter_list.toLocaleLowerCase(\'tr\')) !== -1"  draggable @dragstart="f_startDragText($event, param_ind' + this.d_level.toString() + ', ' + this.f_paramorderString() + ')" @drop="f_onDropCompileStrText($event, param_ind' + this.d_level.toString() + ', ' + this.f_paramorderString() + ')" @dragover.prevent @dragenter.prevent>';
      html += '<b-col sm="12" md="12" lg="12">';
      html += '<span style="' + this.f_calculateParamStyle() + '"></span>';
      html += '<span :title="' + param_data + '.name.label"> {{ f_showThisChars(' + param_data + '.name.label) }} </span>';
      html += '<b-badge :variant="CalculateVariant(' + param_data + '.type)"> {{ ' + param_data + '.type }} </b-badge>';
      html += '<b-badge v-if="show_parameter_features === \'all\' || show_parameter_features === \'match\'" :variant="' + param_data + '.himsTestData && Object.keys(' + param_data + '.himsTestData).length > 0 ? \'success\' : \'danger\'" style="cursor: pointer;">';
      html += '<template v-if="' + param_data + '.himsTestData && Object.keys(' + param_data + '.himsTestData).length > 0">';
      html += '<template v-for="(test, test_id, test_ind) in ' + param_data + '.himsTestData">';
      html += '<span @click="f_showHimsMatchOperationsModal(' + param_data + ', test)">';
      html += '{{ test.id + \'-\' + test.name }}';
      html += '<template v-if="parseInt(test_ind) !== Object.keys(' + param_data + '.himsTestData).length - 1"> | </template>';
      html += '</span>';
      html += '</template>';
      html += '</template>';
      html += '<template v-else>';
      html += '<span>eşleştirilmedi</span>';
      html += '</template>';
      html += '</b-badge>';
      html += '<template v-if="d_isThisModelEditable">';
      html += '<b-badge v-if="show_parameter_features === \'all\' || show_parameter_features === \'required\'" :variant="' + param_data + '.required === \'yes\' ? \'success\' : \'danger\'" style="cursor: pointer;" @click="param_require_func(' + param_data + ')"> RE </b-badge>';
      html += '<b-badge v-if="show_parameter_features === \'all\' || show_parameter_features === \'show_on_recording\'" :variant="' + param_data + '.show === \'yes\' ? \'success\' : \'danger\'" style="cursor: pointer;" @click="param_show_func(' + param_data + ')"> SOR </b-badge>';
      html += '<b-badge v-if="(option_data[data_type].favorite_show_mode === \'yes\') && (show_parameter_features === \'all\' || show_parameter_features === \'fav_show\')" :variant="' + param_data + '.fav_show === \'yes\' ? \'success\' : \'danger\'" style="cursor: pointer;" @click="param_fav_show_func(' + param_data + ')"> FS </b-badge>';
      html += '<b-badge v-if="show_parameter_features === \'all\' || show_parameter_features === \'show_size_units\'" :variant="' + param_data + '.show_size_units ? \'success\' : \'danger\'" style="cursor: pointer;" @click="param_show_size_units_func(' + param_data + ')"> SU </b-badge>';
      html += '<b-badge v-if="show_parameter_features === \'all\' || show_parameter_features === \'show_weight_units\'" :variant="' + param_data + '.show_weight_units ? \'success\' : \'danger\'" style="cursor: pointer;" @click="param_show_weight_units_func(' + param_data + ')"> WU </b-badge>';
      html += '<b-badge v-if="show_parameter_features === \'all\' || show_parameter_features === \'show_dosage_units\'" :variant="' + param_data + '.show_dosage_units ? \'success\' : \'danger\'" style="cursor: pointer;" @click="param_show_dosage_units_func(' + param_data + ')"> DU </b-badge>';
      html += '<b-badge v-if="show_parameter_features === \'all\' || show_parameter_features === \'not_schema\'" :variant="' + param_data + '.not_schema ? \'success\' : \'danger\'" style="cursor: pointer;" @click="param_not_schema_func(' + param_data + ')"> Ş </b-badge>';
      html += '<b-badge v-if="show_parameter_features === \'all\' || show_parameter_features === \'anonym\'" :variant="' + param_data + '.anonym ? \'success\' : \'danger\'" style="cursor: pointer;" @click="param_anonym_func(' + param_data + ')"> A </b-badge>';
      html += '<b-badge v-if="show_parameter_features === \'all\' || show_parameter_features === \'naming\'" :variant="' + param_data + '.naming ? \'success\' : \'danger\'" style="cursor: pointer;" @click="param_name_func(' + param_data + ')"> N </b-badge>';
      html += '<b-badge v-if="show_parameter_features === \'all\' || show_parameter_features === \'show_after_recorded\'" :variant="' + param_data + '.page_not_show ? \'danger\' : \'success\'" style="cursor: pointer;" @click="param_page_not_show_func(' + param_data + ')"> SAR </b-badge>';
      html += '<b-badge v-if="show_parameter_features === \'all\' || show_parameter_features === \'hims_match_operations\'" :variant="' + param_data + '.hims_match_operations ? \'success\' : \'danger\'" style="cursor: pointer;" @click="param_hims_match_operations(' + param_data + ')"> HMF </b-badge>';
      html += '<b-dropdown variant="dark" size="sm" v-if="f_isThisSelected(' + param_index_list + ')">';
      html += '<b-dropdown-item @click="list_param_order(' + this.upper_param_order() + ', param_ind' + this.d_level.toString() + ', \'up\')">yukarı</b-dropdown-item>';
      html += '<b-dropdown-item @click="list_param_order(' + this.upper_param_order() + ', param_ind' + this.d_level.toString() + ', \'down\')">aşağı</b-dropdown-item>';
      html += '<b-dropdown-item @click="list_param_order(' + this.upper_param_order() + ', param_ind' + this.d_level.toString() + ', \'top\')">en üste</b-dropdown-item>';
      html += '<b-dropdown-item @click="list_param_order(' + this.upper_param_order() + ', param_ind' + this.d_level.toString() + ', \'bottom\')">en aşağı</b-dropdown-item>';
      html += '</b-dropdown>';
      html += '</template>';
      html += '<template v-else>';
      html += '<b-badge v-if="show_parameter_features === \'all\' || show_parameter_features === \'required\'" :variant="' + param_data + '.required === \'yes\' ? \'success\' : \'danger\'" style="cursor: pointer;"> RE </b-badge>';
      html += '<b-badge v-if="show_parameter_features === \'all\' || show_parameter_features === \'show_on_recording\'" :variant="' + param_data + '.show === \'yes\' ? \'success\' : \'danger\'" style="cursor: pointer;"> SOR </b-badge>'
      html += '<b-badge v-if="(option_data[data_type].favorite_show_mode === \'yes\') && (show_parameter_features === \'all\' || show_parameter_features === \'fav_show\')" :variant="' + param_data + '.fav_show === \'yes\' ? \'success\' : \'danger\'" style="cursor: pointer;"> FS </b-badge>';
      html += '<b-badge v-if="show_parameter_features === \'all\' || show_parameter_features === \'show_size_units\'" :variant="' + param_data + '.show_size_units ? \'success\' : \'danger\'" style="cursor: pointer;"> SU </b-badge>';
      html += '<b-badge v-if="show_parameter_features === \'all\' || show_parameter_features === \'show_weight_units\'" :variant="' + param_data + '.show_weight_units ? \'success\' : \'danger\'" style="cursor: pointer;"> WU </b-badge>';
      html += '<b-badge v-if="show_parameter_features === \'all\' || show_parameter_features === \'show_dosage_units\'" :variant="' + param_data + '.show_dosage_units ? \'success\' : \'danger\'" style="cursor: pointer;"> DU </b-badge>';
      html += '<b-badge v-if="show_parameter_features === \'all\' || show_parameter_features === \'not_schema\'" :variant="' + param_data + '.not_schema ? \'success\' : \'danger\'" style="cursor: pointer;"> Ş </b-badge>';
      html += '<b-badge v-if="show_parameter_features === \'all\' || show_parameter_features === \'anonym\'" :variant="' + param_data + '.anonym ? \'success\' : \'danger\'" style="cursor: pointer;"> A </b-badge>';
      html += '<b-badge v-if="show_parameter_features === \'all\' || show_parameter_features === \'naming\'" :variant="' + param_data + '.naming ? \'success\' : \'danger\'" style="cursor: pointer;"> N </b-badge>';
      html += '<b-badge v-if="show_parameter_features === \'all\' || show_parameter_features === \'show_after_recorded\'" :variant="' + param_data + '.page_not_show ? \'danger\' : \'success\'" style="cursor: pointer;"> SAR </b-badge>';
      html += '<b-badge v-if="show_parameter_features === \'all\' || show_parameter_features === \'hims_match_operations\'" :variant="' + param_data + '.hims_match_operations ? \'success\' : \'danger\'" style="cursor: pointer;"> HMF </b-badge>';
      html += '<b-badge v-if="brief_list !== undefined && [\'object_list\'].indexOf(' + param_data + '.type) === -1" class="pull-right" :variant="f_controlBriefList(' + param_index_list + ') ? \'success\' : \'danger\'" style="cursor: pointer;" @click="f_paramBriefList(' + param_index_list + ')"> {{ $t(\'wdm16.13177\') }} </b-badge>';
      html += '</template>';
      html += '</b-col>';
      html += '</b-row>';
      if (this.d_level <= this.d_levelCount) {
        html += this.main_param_func();
      }
      html += '</template>';
      return html;
    },
    f_isThisSelected: function (index_list) {
      /*
          'times': 'multi', // one, multi
          'type': 'this_index', // all, this_index, this_types
          'this_index': [
            ['article_summary', 'article_summary_purpose', 'article_summary_purpose_info'],
            ['article_summary', 'article_summary_methods', 'article_summary_methods_info'],
            ['article_summary', 'article_summary_results', 'article_summary_results_info'],
            ['article_summary', 'article_summary_conclusion', 'article_summary_conclusion_info'],
            ['article_summary', 'article_general_information', 'article_general_information_info'],
            ['article_summary', 'article_methods', 'article_methods_info'],
            ['article_summary', 'article_statistics', 'article_statistics_info'],
            ['article_summary', 'article_results', 'article_results_info'],
            ['article_summary', 'article_conclusion', 'article_conclusion_info']
          ]
      */
      if (this.selection) {
        if (this.selection.times === 'one') {
          if (this.selected_index_data.index_list.join() === index_list.join()) {
            return true;
          } else {
            return false;
          }
        } else if (this.selection.times === 'multi') {
          for (let i in this.selected_index_data.index_list) {
            if (this.selected_index_data.index_list[i].join() === index_list.join()) {
              return true;
            }
          }
          return false;
        }
      } else {
        if (this.selected_index_data.index_list.join() === index_list.join()) {
          return true;
        } else {
          return false;
        }
      }
    },
    f_controlBriefList: function (index_list) {
      for (let i in this.brief_list) {
        if (this.brief_list[i].join() === index_list.join()) {
          return true;
        }
      }
      return false;
    },
    f_paramBriefList: function (index_list) {
      let det = false;
      for (let i in this.brief_list) {
        if (this.brief_list[i].join() === index_list.join()) {
          det = true;
          this.brief_list.splice(i, 1);
          break;
        }
      }
      if (!det) {
        this.brief_list.push(index_list);
      }
    },
    f_paramData: function () {
      let str = 'option_data[data_type][group]';
      for (let i = 1; i <= this.d_level; i++) {
        str += '.parameters[param' + i.toString() + ']';
      }
      return str;
    },
    f_showHimsMatchOperationsModal: function (param_data, hims_data) {
      this.d_hmfParamData = param_data;
      this.d_hmfHimsData = hims_data;
      this.d_showHimsMatchOperationsModal = true;
    },
    upper_param_order: function () {
      let str = 'option_data[data_type][group]';
      if (this.d_level > 1) {
        str = 'option_data[data_type][group]';
        for (let i = 1; i < this.d_level; i++) {
          str += '.parameters[param' + i.toString() + ']';
        }
      }
      return str + '.param_order';
    },
    f_calculateParamStyle: function () {
      let margin_left = 20;
      margin_left *= this.d_level;
      return 'padding-left: 20px; margin-left: ' + margin_left.toString() + 'px; border-left: dotted 1px #20a8d8; border-bottom: dotted 1px #20a8d8;';
    },
    CalculateVariant: function (param_type) {
      if (['select', 'checkbox', 'radiogroup'].indexOf(param_type) !== -1) {
        return 'warning';
      } else if (['textarea', 'text', 'html_editor'].indexOf(param_type) !== -1) {
        return 'secondary';
      } else if (['integer', 'float'].indexOf(param_type) !== -1) {
        return 'primary';
      } else if (['list_parameter'].indexOf(param_type) !== -1) {
        return 'success';
      } else if (['object_list_options', 'object_list'].indexOf(param_type) !== -1) {
        return 'info';
      } else if (['date', 'time', 'datetime'].indexOf(param_type) !== -1) {
        return 'dark';
      }
    },
    list_param_order: function (list, index, direction) {
      let index_data = list[index];
      let count = list.length;
      let friend = '';
      if (direction === 'up') {
        if (index !== 0) {
          friend = list[index - 1];
          list[index - 1] = index_data;
          list[index] = friend;
        }
      } else if (direction === 'down') {
        if (index !== count - 1) {
          friend = list[index + 1];
          list[index + 1] = index_data;
          list[index] = friend;
        }
      } else if (direction === 'top') {
        list.splice(index, 1);
        list.splice(0, 0, index_data);
      } else if (direction === 'bottom') {
        list.splice(index, 1);
        list.push(index_data);
      }
      this.$forceUpdate();
    },
    f_paramIndexList: function (use_brackets = true) {
      let str = '';
      if (use_brackets) {
        str = '[group,';
      }
      for (let i = 1; i <= this.d_level; i++) {
        str += 'param' + i.toString();
        if (i !== this.d_level) {
          str += ',';
        }
      }
      if (use_brackets) {
        str += ']';
      }
      return str;
    },
    f_paramorderString: function () {
      if (this.d_level === 1) {
        return 'option_data[data_type][group].param_order';
      } else if (this.d_level > 1) {
        let str = 'option_data[data_type][group]';
        for (let i = 1; i < this.d_level; i++) {
          str += '.parameters[param' + i.toString() + ']';
        }
        return str + '.param_order';
      }
    },
    f_selectIndex: function (index_list) {
      /*
          'times': 'multi', // one, multi
          'type': 'this_index', // all, this_index, this_types
          'this_index': [
            ['article_summary', 'article_summary_purpose', 'article_summary_purpose_info'],
            ['article_summary', 'article_summary_methods', 'article_summary_methods_info'],
            ['article_summary', 'article_summary_results', 'article_summary_results_info'],
            ['article_summary', 'article_summary_conclusion', 'article_summary_conclusion_info'],
            ['article_summary', 'article_general_information', 'article_general_information_info'],
            ['article_summary', 'article_methods', 'article_methods_info'],
            ['article_summary', 'article_statistics', 'article_statistics_info'],
            ['article_summary', 'article_results', 'article_results_info'],
            ['article_summary', 'article_conclusion', 'article_conclusion_info']
          ]
      */
      // console.log(index_list);
      if (this.selection) {
        if (this.selection.times === 'one') {
          if (this.selection.type === 'all') {
            this.selected_index_data.index_list.splice(0, this.selected_index_data.index_list.length);
            this.selected_index_data.index_list = index_list;
          } else if (this.selection.type === 'this_index') {
            let det = false;
            for (let i in this.selection.this_index) {
              if (this.selection.this_index[i].join() === index_list.join()) {
                det = true;
                break;
              }
            }
            if (det) {
              this.selected_index_data.index_list.splice(0, this.selected_index_data.index_list.length);
              this.selected_index_data.index_list = index_list;
            }
          }
        } else if (this.selection.times === 'multi') {
          if (this.selection.type === 'all') {
            let ind = '';
            for (let i in this.selected_index_data.index_list) {
              if (this.selected_index_data.index_list[i].join() === index_list.join()) {
                ind = i;
                break;
              }
            }
            if (ind !== '') {
              this.selected_index_data.index_list.splice(ind, 1);
            } else {
              this.selected_index_data.index_list.push(index_list);
            }
          } else if (this.selection.type === 'this_index') {
            let det = false;
            for (let i in this.selection.this_index) {
              if (this.selection.this_index[i].join() === index_list.join()) {
                det = true;
                break;
              }
            }
            if (det) {
              let ind = '';
              for (let i in this.selected_index_data.index_list) {
                if (this.selected_index_data.index_list[i].join() === index_list.join()) {
                  ind = i;
                  break;
                }
              }
              if (ind !== '') {
                this.selected_index_data.index_list.splice(ind, 1);
              } else {
                this.selected_index_data.index_list.push(index_list);
              }
            }
          }
        }
      } else {
        this.selected_index_data.index_list = index_list;
      }
      this.$forceUpdate();
    },
    param_name_func: function (param_data) {
      param_data.naming ? this.$delete(param_data, 'naming') : param_data.naming = '1';
      this.$forceUpdate();
    },
    param_require_func: function (param_data) {
      if (param_data.required && param_data.required === 'yes') {
        param_data.required = 'no';
      } else {
        param_data.required = 'yes';
      }
      this.$forceUpdate();
    },
    param_fav_show_func: function (param_data) {
      if (param_data.fav_show && param_data.fav_show === 'yes') {
        param_data.fav_show = 'no';
      } else {
        param_data.fav_show = 'yes';
      }
      this.$forceUpdate();
    },
    param_show_func: function (param_data) {
      if (param_data.show && param_data.show === 'yes') {
        param_data.show = 'no';
      } else {
        param_data.show = 'yes';
      }
      this.$forceUpdate();
    },
    param_show_size_units_func: function (param_data) {
      param_data.show_size_units ? this.$delete(param_data, 'show_size_units') : param_data.show_size_units = '1';
      this.$forceUpdate();
    },
    param_show_weight_units_func: function (param_data) {
      param_data.show_weight_units ? this.$delete(param_data, 'show_weight_units') : param_data.show_weight_units = '1';
      this.$forceUpdate();
    },
    param_show_dosage_units_func: function (param_data) {
      param_data.show_dosage_units ? this.$delete(param_data, 'show_dosage_units') : param_data.show_dosage_units = '1';
      this.$forceUpdate();
    },
    param_not_schema_func: function (param_data) {
      param_data.not_schema ? this.$delete(param_data, 'not_schema') : param_data.not_schema = '1';
      this.$forceUpdate();
    },
    param_anonym_func: function (param_data) {
      param_data.anonym ? this.$delete(param_data, 'anonym') : param_data.anonym = '1';
      this.$forceUpdate();
    },
    param_option_search_func: function (param_data) {
      param_data.option_search ? this.$delete(param_data, 'option_search') : param_data.option_search = '';
      this.$forceUpdate();
    },
    param_page_not_show_func: function (param_data) {
      param_data.page_not_show ? this.$delete(param_data, 'page_not_show') : param_data.page_not_show = '0';
      this.$forceUpdate();
    },
    param_hims_match_operations: function (param_data) {
      param_data.hims_match_operations ? this.$delete(param_data, 'hims_match_operations') : param_data.hims_match_operations = '0';
      this.$forceUpdate();
    }
  },
  watch: {
    'param_change.update': function () {
      this.$forceUpdate();
    },
    'param_change.new_parameter_added': function () {
      this.render_template();
    }
  }
};

</script>

<style>

.param-group-not-selected {
  color: blue;
  font-weight: bold;
  cursor: pointer;
}

.param-group-selected {
  background-color: #e3e6e6;
  font-weight: bold;
  cursor: pointer;
}

.param-not-selected-1 {
  /*border-bottom: solid 1px #ccffcc;*/
  cursor: pointer;
}

.param-selected-1 {
  background-color: #e3e6e6;
  /*border-bottom: solid 1px #ccffcc;*/
  cursor: pointer;
}

.param-group-not-selected:hover,
.param-not-selected-1:hover {
  background-color: yellow;
  padding-top: 3px;
  padding-bottom: 3px;
}

.group-level {
  padding-left: 20px;
  border-left: dotted 1px #20a8d8;
  border-bottom: dotted 1px #20a8d8;
}


</style>

