<template>
  <div>
    <template>
      <parameter-design></parameter-design>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import {default as router} from '@/router';
/*import {
  default as Modal
} from '@/components/widgets/Modal';*/
// import OptionService from '@/services/option';
import {
  default as ParameterDesign
} from '@/components/widgets/ParameterDesign';

export default {
  name: 'DataForm',
  computed: {
    ...mapGetters({
      lang: 'lang'
    })
  },
  created: function () {},
  mounted: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  data () {
    return {};
  },
  methods: {},
  components: {
    ParameterDesign
  },
  watch: {}
};

</script>

